<template>
  <div :style="menuOpenStyle">
    <b-card no-body class="border-0">
      <b-tabs
        v-model="currentTab"
        nav-class="settings-tabs"
        pills
        card
        vertical
        nav-wrapper-class="nav-wrapper-class"
        content-class="content-class"
        class="tabs-class"
      >
        <template #tabs-start>
          <p class="tw-pt-[23px]">
            {{ $t("edit_campaign.campaign_setting_taglist_title", "Categories:") }}
          </p>
        </template>
        <b-tab :key="tag" :title="tag" v-for="tag in uniqueTagNames">
          <div class="tw-flex tw-flex-wrap tw-gap-4 tw-justify-between tw-items-start">
            <p>
              {{ $t("edit_campaign.campaign_setting_campaign_setting_taglist_title", "Settings:") }}
            </p>
            <input
              type="text"
              v-model="filterQuery"
              :placeholder="
                $t(
                  'edit_campaign.campaign_setting_campaign_setting_taglist_search_placeholder',
                  'Quick search by keywords'
                )
              "
              class="tw-min-w-[260px] tw-mb-4 tw-border-b tw-border-bordercolor tw-outline-none"
            />
          </div>
          <div
            :key="s.name"
            :title="s.title"
            v-for="s in filteredSettings(tag)"
            v-show="isSettingVisible(s)"
            class="tw-mb-5"
            :data-setting-name="s.name"
          >
            <component v-if="s.custom" :is="s.custom" :baseSettingsItem="s" />
            <setting
              v-else
              :name="s.name"
              :scheme="s.scheme"
              :remap="s.remap"
              :baseSettingsItem="s"
              modal
            >
              <template v-if="s.name === 'add_team_from_campaign'" #above-form>
                <copy-field-row
                  :url="addTeamFromCampaignFromLink"
                  :label="$t('edit_campaign.add_team_from_campaign_form_link', 'Form link')"
                />
              </template>
              <template
                v-if="s.name === 'donation_success_page_config'"
                #extra-buttons="{ isActive }"
              >
                <b-btn
                  v-if="isActive"
                  class="bttn bttn--sm bttn--orange mx-2"
                  v-b-modal.preview-modal
                >
                  {{ $t("edit_campaign.setting_preview", "Preview") }}
                </b-btn>
              </template>
            </setting>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>

    <b-modal id="preview-modal" centered size="xl">
      <iframe :src="donateThankYouPreview" frameborder="0" class="w-100" height="600"></iframe>

      <template #modal-footer="{ hide }">
        <b-btn class="bttn bttn--sm bttn--orange mx-2" @click="hide"> Ok </b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AddTeamFromCampaignShema from "./settings-schemas/add_team_from_campaign.schema.js";
import AllowTeamUpdateByPublicTokenShema from "./settings-schemas/allow_team_update_by_public_token.schema.js";
import HideClock from "./settings-schemas/hide_clock.schema.js";
import SeferTorahLomdimBlock from "./settings-schemas/sefer_torah_lomdim_block.schema.js";
import TitleSliderVue from "./settings-schemas/title_slider_vue.schema.js";
import Data2Columns from "./settings-schemas/data_2_columns.schema.js";
import DonorsWidgetVue from "./settings-schemas/donors_widget_vue.schema.js";
import ProjectorMode from "./settings-schemas/projector_mode.schema.js";
import AllowPreDonation from "./settings-schemas/allow_pre_donation.schema.js";
import HideDonorList from "./settings-schemas/hide_donor_list.schema.js";
import ShowDonationLevelsAboveAmount from "./settings-schemas/show_donation_levels_above_amount.schema.js";
import DonationFormVue from "./settings-schemas/donation_form_vue.schema.js";
import NotificationDonationVue from "./settings-schemas/notification_donation_vue.schema.js";
import BrandSlider from "./settings-schemas/brand_slider.schema.vue";
import DonationSuccessPageConfig from "./settings-schemas/donation_success_page_config.schema.js";
import UnidyMediaStripe from "./settings-schemas/unidy_media_stripe.schema";
import NotifyOrgAboutDonationsAbove from "./settings-schemas/notify_org_about_donations_above.schema";
import DonorRescueNotification from "./settings-schemas/donor_rescue_notification.schema";
import PreDonationPdfForm from "./settings-schemas/pre_donation_pdf_form.schema";
import WhatsappSupportBtn from "./settings-schemas/whatsapp_support_btn.schema";
import DonorsGoal from "./settings-schemas/donors_goal.schema";
import DonationUpsell from "./settings-schemas/donation_upsell.schema";
import RecurringPayments from "./settings-schemas/recurring_payments.schema";
import CoverCCFee from "./settings-schemas/cover_cc_fee.schema.vue";
import TeamPageHideDonorListSchema from "./settings-schemas/team_page_hide_donor_list.schema";
import MinDonationAmount from "./settings-schemas/min_donation_amount.schema";
import DonationFormCustomFields from "./settings-schemas/donation_form_custom_fields.schema";
import PhoneRequired from "./settings-schemas/phone_required.schema";
import ShowBillingAddress from "./settings-schemas/show_billing_address.schema";
import NotificationsFromEmail from "./settings-schemas/notifications_from_email.schema";
import ActivateConfettiNow from "./settings-schemas/activate_confetti_now.schema";
import CopyFieldRow from "@/common-components/copy-field-row.vue";
import Setting from "./setting.vue";
import SettingModal from "./settingModal.vue";
import { donateHost } from "@/constants/urls";
import CustomTabsUnidyClassic from "./settings-schemas/custom_tabs_unidy_classic.schema";
import MaximumDonationAmount from "./settings-schemas/maximum_donation_amount.schema";
import PastAchievements from "./settings-schemas/past_achievements.schema.vue";
import CampaignPromoCode from "./settings-schemas/campaign_promo_code.schema.vue";
import FeaturedTeams from "./settings-schemas/featured_teams.schema.js";
import ReceiptEmailToggle from "./settings-schemas/receipt_email_toggle.schema.vue";
import PlaceNewDonationsOnHold from "./settings-schemas/place_new_donations_on_hold.schema";
import UnidyClassicTabsVue from "./settings-schemas/unidy_classic_tabs_vue.schema";
import { defineComponent } from "vue";
import { useCampaignSettings } from "@/composables/campaign-settings.js";

export default defineComponent({
  setup() {
    const { getSetting } = useCampaignSettings();

    return {
      getSetting,
    };
  },
  components: {
    Setting,
    CopyFieldRow,
    CoverCCFee,
    BrandSlider,
    PastAchievements,
    CampaignPromoCode,
    SettingModal,
    ReceiptEmailToggle,
  },

  data() {
    return {
      filterQuery: "",
      menuOpenState: true,
      activeSettingName: null,
      currentTab: 0,
      settingsExtraData: {
        unidyTabsOrderTabsData: [],
      },
    };
  },

  computed: {
    ...mapState({
      oID: () => localStorage.getItem("orgId"),
      cID: (state) => state.campaign.campaign.data.id,
    }),

    menuOpenStyle() {
      if (this.menuOpenState) {
        return { "--_menu-open": "block" };
      }

      return { "--_menu-open": "none" };
    },

    addTeamFromCampaignFromLink() {
      let env = "";

      if (process.env.NODE_ENV === "development") {
        env = "develop.";
      } else if (location.hostname.includes("develop.")) {
        env = "develop.";
      }

      const url = new URL(`https://view.${env}charidy.com/new_team_page/`);

      url.searchParams.append("campaign_id", this.cID);

      return url.toString();
    },

    donateThankYouPreview() {
      return `${donateHost}/${this.cID}?status=preview`;
    },

    uniqueTagNames() {
      const tagNames = this.settings.map((setting) => setting.tagName);
      return [...new Set(tagNames)];
    },

    settings() {
      return [
        {
          name: "brand_slider",
          title: this.$t("edit_campaign.brand_slider_setting_name", "Brand Slider"),
          description: this.$t("edit_campaign.brand_slider_setting_description", "description"),
          preview: this.$t("edit_campaign.brand_slider_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          custom: "BrandSlider",
        },
        {
          name: "include_fee_to_my_donaton",
          title: this.$t("edit_campaign.cover_cc_fee_setting_name", "Cover CC Fee"),
          description: this.$t("edit_campaign.cover_cc_fee_setting_description", "description"),
          preview: this.$t("edit_campaign.cover_cc_fee_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_donation_form", "Donation form"),
          custom: "CoverCCFee",
        },
        {
          name: "past_achievements",
          title: this.$t("edit_campaign.past_achievements_setting_name", "Past Achievements"),
          description: this.$t(
            "edit_campaign.past_achievements_setting_description",
            "description"
          ),
          preview: this.$t("edit_campaign.past_achievements_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          custom: "PastAchievements",
        },
        {
          name: "campaign_promo_code",
          title: this.$t("edit_campaign.campaign_promo_code_setting_name", "Promo Codes"),
          description: this.$t(
            "edit_campaign.campaign_promo_code_setting_description",
            "description"
          ),
          preview: this.$t("edit_campaign.campaign_promo_code_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_team_page", "Team page"),
          custom: "CampaignPromoCode",
        },
        {
          name: "place_new_donations_on_hold",
          title: this.$t(
            "edit_campaign.place_new_donations_on_hold_name",
            "Place new donations on hold"
          ),
          description: this.$t(
            "edit_campaign.place_new_donations_on_hold_description",
            "description"
          ),
          preview: this.$t("edit_campaign.place_new_donations_on_hold_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_donation_form", "Donation form"),
          scheme: PlaceNewDonationsOnHold((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "donors_widget_vue",
          title: this.$t("edit_campaign.donors_widget_vue_setting_name", "Donors widget vue"),
          description: this.$t(
            "edit_campaign.donors_widget_vue_setting_description",
            "description"
          ),
          preview: this.$t("edit_campaign.donors_widget_vue_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: DonorsWidgetVue((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "projector_mode",
          title: this.$t("edit_campaign.projector_mode_name", "Projector mode"),
          description: this.$t("edit_campaign.projector_mode_description", "description"),
          preview: this.$t("edit_campaign.projector_mode_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: ProjectorMode((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "title_slider_vue",
          title: this.$t("edit_campaign.title_slider_vue_setting_name", "Title slider Vue"),
          description: this.$t("edit_campaign.title_slider_vue_setting_description", "description"),
          preview: this.$t("edit_campaign.title_slider_vue_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: TitleSliderVue((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "data_2_columns",
          title: this.$t("edit_campaign.data_2_columns_setting_name", "Data 2 columns"),
          description: this.$t("edit_campaign.data_2_columns_setting_description", "description"),
          preview: this.$t("edit_campaign.data_2_columns_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_other", "Other"),
          scheme: Data2Columns((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "sefer_torah_lomdim_block",
          title: this.$t(
            "edit_campaign.sefer_torah_lomdim_block_setting_name",
            "Sefer Torah lomdim block"
          ),
          description: this.$t(
            "edit_campaign.sefer_torah_lomdim_block_setting_description",
            "description"
          ),
          preview: this.$t("edit_campaign.sefer_torah_lomdim_block_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: SeferTorahLomdimBlock((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "hide_donor_list",
          title: this.$t("edit_campaign.hide_donor_list_setting_name", "Hide donor list"),
          description: this.$t("edit_campaign.hide_donor_list_setting_description", "description"),
          preview: this.$t("edit_campaign.hide_donor_list_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: HideDonorList((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "whatsapp_support_btn",
          title: this.$t("edit_campaign.whatsapp_support_btn_setting_name", "Whatsapp support btn"),
          description: this.$t(
            "edit_campaign.whatsapp_support_btn_setting_description",
            "description"
          ),
          preview: this.$t("edit_campaign.whatsapp_support_btn_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: WhatsappSupportBtn((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "pre_donation_pdf_form",
          title: this.$t(
            "edit_campaign.pre_donation_pdf_form_setting_name",
            "Pre donation pdf form"
          ),
          description: this.$t(
            "edit_campaign.pre_donation_pdf_form_setting_description",
            "description"
          ),
          preview: this.$t("edit_campaign.pre_donation_pdf_form_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_donation_form", "Donation form"),
          scheme: PreDonationPdfForm((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "donor_rescue_notification",
          title: this.$t(
            "edit_campaign.donor_rescue_notification_setting_name",
            "Donor rescue notification"
          ),
          description: this.$t(
            "edit_campaign.donor_rescue_notification_setting_description",
            "description"
          ),
          preview: this.$t("edit_campaign.donor_rescue_notification_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_donation_form", "Donation form"),
          scheme: DonorRescueNotification((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "add_team_from_campaign",
          title: this.$t(
            "edit_campaign.add_team_from_campaign_setting_name",
            `"New team page" settings`
          ),
          description: this.$t(
            "edit_campaign.add_team_from_campaign_setting_description",
            "description"
          ),
          preview: this.$t("edit_campaign.add_team_from_campaign_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_team_page", "Team page"),
          scheme: AddTeamFromCampaignShema((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "allow_team_update_by_public_token",
          title: this.$t(
            "edit_campaign.allow_team_update_by_public_token_setting_name",
            "Allow team update by public token"
          ),
          description: this.$t(
            "edit_campaign.allow_team_update_by_public_token_setting_description",
            "description"
          ),
          preview: this.$t(
            "edit_campaign.allow_team_update_by_public_token_setting_name_preview_image"
          ),
          tagName: this.$t("edit_campaign.tag_title_team_page", "Team page"),
          scheme: AllowTeamUpdateByPublicTokenShema((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "hideclock",
          title: this.$t("edit_campaign.campaign_hideclock_setting_name", "Hide clock"),
          description: this.$t(
            "edit_campaign.campaign_hideclock_setting_description",
            "description"
          ),
          preview: this.$t("edit_campaign.campaign_hideclock_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: HideClock((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "notification_donation_vue",
          title: this.$t(
            "edit_campaign.notification_donation_vue_setting_name",
            "Notification donation vue"
          ),
          description: this.$t(
            "edit_campaign.notification_donation_vue_setting_description",
            "description"
          ),
          preview: this.$t("edit_campaign.notification_donation_vue_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: NotificationDonationVue((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "allow_pre_donation",
          title: this.$t("edit_campaign.allow_pre_donation_setting_name", "Allow pre donation"),
          description: this.$t(
            "edit_campaign.allow_pre_donation_setting_description",
            "description"
          ),
          preview: this.$t("edit_campaign.allow_pre_donation_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_donation_form", "Donation form"),
          scheme: AllowPreDonation((key, fallback) => this.$t(key, fallback)),
          disable: true,
        },
        {
          name: "show_donation_levels_above_amount",
          title: this.$t(
            "edit_campaign.show_donation_levels_above_amount_setting_name",
            "Show donation levels above amount"
          ),
          description: this.$t(
            "edit_campaign.show_donation_levels_above_amount_setting_description",
            "description"
          ),
          preview: this.$t(
            "edit_campaign.show_donation_levels_above_amount_setting_name_preview_image"
          ),
          tagName: this.$t("edit_campaign.tag_title_donation_form", "Donation form"),
          scheme: ShowDonationLevelsAboveAmount((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "donation_form_vue",
          title: this.$t("edit_campaign.donation_form_vue_setting_name", "Donation form vue"),
          description: this.$t(
            "edit_campaign.donation_form_vue_setting_description",
            "description"
          ),
          preview: this.$t("edit_campaign.donation_form_vue_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: DonationFormVue((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "donation_success_page_config",
          title: this.$t(
            "edit_campaign.donation_success_page_config_setting_name",
            "Donation Success Page Config"
          ),
          description: this.$t(
            "edit_campaign.donation_success_page_config_setting_description",
            "description"
          ),
          preview: this.$t("edit_campaign.donation_success_page_config_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_donation_form", "Donation form"),
          scheme: DonationSuccessPageConfig((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "unidy_media_stripe",
          title: this.$t("edit_campaign.unidy_media_stripe_settings_name", "Unidy media stripe"),
          description: this.$t(
            "edit_campaign.unidy_media_stripe_settings_description",
            "description"
          ),
          preview: this.$t("edit_campaign.unidy_media_stripe_settings_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: UnidyMediaStripe((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "notify_org_about_donations_above",
          title: this.$t(
            "edit_campaign.notify_org_about_donations_above_settings_name",
            "Notify about donations above"
          ),
          description: this.$t(
            "edit_campaign.notify_org_about_donations_above_settings_description",
            "description"
          ),
          preview: this.$t(
            "edit_campaign.notify_org_about_donations_above_settings_name_preview_image"
          ),
          tagName: this.$t("edit_campaign.tag_title_donation_form", "Donation form"),
          scheme: NotifyOrgAboutDonationsAbove((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "donors_goal",
          title: this.$t("edit_campaign.donors_goal_settings_name", "Donors goal"),
          description: this.$t("edit_campaign.donors_goal_settings_description", "description"),
          preview: this.$t("edit_campaign.donors_goal_settings_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: DonorsGoal((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "donation_upsell",
          title: this.$t("edit_campaign.donation_upsell_settings_name", "Donation Upsell"),
          description: this.$t("edit_campaign.donation_upsell_settings_description", "description"),
          preview: this.$t("edit_campaign.donation_upsell_settings_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_donation_form", "Donation form"),
          scheme: DonationUpsell((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "recurring_payments",
          title: this.$t("edit_campaign.recurring_payments_settings_name", "Recurring Payments"),
          description: this.$t(
            "edit_campaign.recurring_payments_settings_description",
            "description"
          ),
          preview: this.$t("edit_campaign.recurring_payments_settings_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_donation_form", "Donation form"),
          scheme: RecurringPayments((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "team_page_hide_donor_list",
          title: this.$t(
            "edit_campaign.team_page_hide_donor_list_settings_name",
            "Team Page Hide Donor List"
          ),
          description: this.$t(
            "edit_campaign.team_page_hide_donor_list_settings_description",
            "description"
          ),
          preview: this.$t("edit_campaign.team_page_hide_donor_list_settings_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_team_page", "Team page"),
          scheme: TeamPageHideDonorListSchema((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "notifications_from_email",
          title: this.$t(
            "edit_campaign.notifications_from_email_setting_name",
            "Notifications From Email"
          ),
          description: this.$t(
            "edit_campaign.notifications_from_email_setting_description",
            "description"
          ),
          preview: this.$t("edit_campaign.notifications_from_email_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: NotificationsFromEmail((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "activate_confetti_now",
          title: this.$t("edit_campaign.activate_confetti_now_setting_name", "Confetti ON/OFF"),
          description: this.$t(
            "edit_campaign.activate_confetti_now_setting_description",
            "description"
          ),
          preview: this.$t("edit_campaign.activate_confetti_now_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: ActivateConfettiNow((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "min_donation_amount",
          title: this.$t("edit_campaign.meta_min_donation_amount", "Min Donation Amount"),
          description: this.$t("edit_campaign.meta_min_donation_amount_description", "description"),
          preview: this.$t("edit_campaign.meta_min_donation_amount_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_donation_form", "Donation form"),
          scheme: MinDonationAmount((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "maximum_donation_amount",
          title: this.$t("edit_campaign.meta_maximum_donation_amount", "Maximum Donation Amount"),
          description: this.$t(
            "edit_campaign.meta_maximum_donation_amount_description",
            "description"
          ),
          preview: this.$t("edit_campaign.meta_maximum_donation_amount_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_donation_form", "Donation form"),
          scheme: MaximumDonationAmount((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "donation_form_custom_fields",
          title: this.$t(
            "edit_campaign.meta_donation_form_custom_fields",
            "Donation form custom fields"
          ),
          description: this.$t(
            "edit_campaign.meta_donation_form_custom_fields_description",
            "description"
          ),
          preview: this.$t("edit_campaign.meta_donation_form_custom_fields_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_donation_form", "Donation form"),
          scheme: DonationFormCustomFields((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "custom_tabs_unidy_classic",
          title: this.$t(
            "edit_campaign.meta_custom_tabs_unidy_classic",
            "Custom tabs (unidy classic)"
          ),
          description: this.$t(
            "edit_campaign.meta_custom_tabs_unidy_classic_description",
            "description"
          ),
          preview: this.$t("edit_campaign.meta_custom_tabs_unidy_classic_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: CustomTabsUnidyClassic((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "phone_required",
          title: this.$t("edit_campaign.meta_phone_required", "Donation form - Phone required"),
          description: this.$t("edit_campaign.meta_phone_required_description", "description"),
          preview: this.$t("edit_campaign.meta_phone_required_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_donation_form", "Donation form"),
          scheme: PhoneRequired((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "show_billing_address",
          title: this.$t(
            "edit_campaign.meta_show_billing_address",
            "Donation form - Show billing address"
          ),
          description: this.$t(
            "edit_campaign.meta_show_billing_address_description",
            "description"
          ),
          preview: this.$t("edit_campaign.meta_show_billing_address_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_donation_form", "Donation form"),
          scheme: ShowBillingAddress((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "featured_teams",
          title: this.$t("edit_campaign.featured_teams_setting_name", "Featured Teams"),
          description: this.$t(
            "edit_campaign.featured_teams_setting_description",
            "Configure featured teams display settings"
          ),
          preview: this.$t("edit_campaign.featured_teams_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: FeaturedTeams((key, fallback) => this.$t(key, fallback)),
        },
        {
          name: "receipt_email_toggle",
          title: this.$t("edit_campaign.receipt_email_toggle_setting_name", "Receipt Email Toggle"),
          description: this.$t(
            "edit_campaign.receipt_email_toggle_setting_description",
            "Configure receipt email settings"
          ),
          preview: this.$t("edit_campaign.receipt_email_toggle_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_other", "Other"),
          custom: "ReceiptEmailToggle",
        },
        {
          name: "unidy_classic_tabs_vue",
          title: this.$t(
            "edit_campaign.unidy_classic_tabs_vue_setting_name",
            "Tabs (Unidy Template)"
          ),
          description: this.$t(
            "edit_campaign.unidy_classic_tabs_vue_setting_description",
            "Configure how tabs are displayed on the campaign public page (Unidy Template)"
          ),
          preview: this.$t("edit_campaign.unidy_classic_tabs_vue_setting_name_preview_image"),
          tagName: this.$t("edit_campaign.tag_title_campaign_page", "Campaign page"),
          scheme: UnidyClassicTabsVue(
            (key, fallback) => this.$t(key, fallback),
            this.settingsExtraData
          ),
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      fetchTicketActivated: "ticket/fetchTicketActivated",
    }),
    filteredSettings(tag) {
      return this.settings.filter((setting) => setting.tagName === tag && setting.disable !== true);
    },
    isSettingVisible(setting) {
      if (this.filterQuery) {
        const words = this.filterQuery
          .toLowerCase()
          .split(/\s+/)
          .filter((word) => word);
        return words.some(
          (word) =>
            setting.title.toLowerCase().includes(word) ||
            setting.description.toLowerCase().includes(word)
        );
      }
      return true;
    },
    async openSetting(settingName) {
      if (!settingName) return;

      const setting = this.settings.find((s) => s.name === settingName);
      if (!setting) return;

      const tabIndex = this.uniqueTagNames.findIndex((tag) => tag === setting.tagName);
      if (tabIndex >= 0) {
        this.currentTab = tabIndex;
      }

      // Wait for DOM updates
      await this.$nextTick();

      // Try multiple times with increasing delays
      const tryOpenModal = async (attempts = 0) => {
        if (attempts >= 5) return false; // Give up after 5 attempts

        const MODAL_ID = `campaign-setting-modal-${setting.name}`;
        try {
          await this.$bvModal.show(MODAL_ID);
          return true;
        } catch (e) {
          console.log(`Attempt ${attempts + 1} failed, retrying...`);
          await new Promise((resolve) => setTimeout(resolve, 100 * Math.pow(2, attempts)));
          return tryOpenModal(attempts + 1);
        }
      };

      return tryOpenModal();
    },
    async unidyTabsOrderTabsData() {
      const defaultTabs = [
        "donors",
        "donors_matched",
        "matchers",
        "about",
        "teams",
        "teams_nominated",
        "teams_group",
        "team_grand_parent",
        "raffle",
        "fb_widget",
      ];

      try {
        const setting = await this.getSetting(
          {
            cid: this.cID,
            oid: this.oID,
          },
          "unidy_tabs_order"
        );

        if (
          setting?.attributes?.meta_data?.value &&
          Array.isArray(setting?.attributes?.meta_data?.tabs) &&
          setting?.attributes?.meta_data?.tabs.length > 0
        ) {
          return setting?.attributes?.meta_data?.tabs;
        }

        return defaultTabs;
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        return defaultTabs;
      }
    },
  },

  mounted() {
    this.unidyTabsOrderTabsData().then((data) => {
      this.settingsExtraData.unidyTabsOrderTabsData = data;
    });

    this.fetchTicketActivated(this.cID);

    // Handle hash params
    const tryOpenFromHash = async () => {
      const hashQuery = window.location.hash.split("?")[1];
      if (!hashQuery) return;

      const hashQueryParams = new URLSearchParams(`?${hashQuery}`);
      const setting = hashQueryParams.get("setting");
      if (setting) {
        await this.openSetting(setting);
      }
    };

    // Try opening after a short delay to ensure everything is mounted
    setTimeout(() => tryOpenFromHash(), 100);
  },
});
</script>

<style lang="scss" scoped>
::v-deep .nav-wrapper-class {
  display: var(--_menu-open, block);
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
}
::v-deep .settings-tabs {
  @apply tw-border-r tw-border-bordercolor;
  background-color: #fff !important;
  @media only screen and (max-width: 991px) {
    border-right: none;
    width: 100%;
  }
}
::v-deep .tabs-class {
  flex-wrap: nowrap;
  @media only screen and (max-width: 991px) {
    flex-wrap: wrap;
  }
}
</style>
