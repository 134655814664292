export default ($t, extraData) => ({
  title: "UnidyClassicTabsVue",
  type: "object",
  properties: {
    value: {
      title: "Active",
      expanded: true,
      type: "boolean",
      default: false,
    },
    default_tab: {
      title: $t("edit_campaign.schema_unidy_classic_tabs_vue_default_tab", "Default Tab"),
      type: "enum",
      default: "auto",
      expanded: true,
      description: $t(
        "edit_campaign.schema_unidy_classic_tabs_vue_default_tab_description",
        "Default tab to display when the campaign is opened"
      ),
      items: {
        enum: extraData.unidyTabsOrderTabsData.map((tab) => ({
          text: $t(
            `edit_campaign.schema_unidy_classic_tabs_vue_default_tab_items_enum_${tab}`,
            tab
          ),
          value: tab,
        })),
      },
    },
    default_tab_on_team_page: {
      title: $t(
        "edit_campaign.schema_unidy_classic_tabs_vue_default_tab_on_team_page",
        "Default Tab on Team Page"
      ),
      type: "enum",
      default: "auto",
      expanded: true,
      description: $t(
        "edit_campaign.schema_unidy_classic_tabs_vue_default_tab_on_team_page_description",
        "Default tab to display when the campaign is opened on the team page"
      ),
      items: {
        enum: extraData.unidyTabsOrderTabsData.map((tab) => ({
          text: $t(
            `edit_campaign.schema_unidy_classic_tabs_vue_default_tab_on_team_page_items_enum_${tab}`,
            tab
          ),
          value: tab,
        })),
      },
    },
    open_default_tab_on_team_page: {
      title: $t(
        "edit_campaign.schema_unidy_classic_tabs_vue_open_default_tab_on_team_page",
        "Open Default Tab on Team Page"
      ),
      expanded: true,
      type: "boolean",
      default: false,
      description: $t(
        "edit_campaign.schema_unidy_classic_tabs_vue_open_default_tab_on_team_page_description",
        'When this setting is true, this means we reuse "Default Tab" from the campaign page'
      ),
    },
  },
});
