<template>
  <b-form-checkbox
    class="event-checkbox"
    :class="{ 'event-checkbox__empty': isSlotEmpty, 'event-checkbox__altcolor': altcolor }"
    switch
    :unchecked-value="uncheckedValue"
    :value="checkedValue"
    v-model="modelValue"
    :disabled="disabled"
  >
    <slot v-if="!isSlotEmpty" />
  </b-form-checkbox>
</template>
<script>
export default {
  props: {
    value: [Boolean, String],
    checkedValue: {
      type: [Boolean, String],
      default: true,
    },
    uncheckedValue: {
      type: [Boolean, String],
      default: false,
    },
    altcolor: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    isSlotEmpty() {
      return !this.$slots.default || this.$slots.default.length === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.event-checkbox {
  width: 100%;
  padding-right: 52px;
  padding-left: var(--padding-left);
  @media (max-width: 768px) {
    padding-left: 0;
  }

  &__altcolor {
    --event-primary: var(--event-success);
  }

  ::v-deep .custom-control-label {
    width: 100%;
    cursor: pointer;
  }

  ::v-deep .custom-control-label::before {
    top: 0px;
    right: -52px;
    left: unset;
    width: 52px;
    height: 30px;
    border: var(--event-border) solid 1px;
    border-radius: 9999px;
    background-color: var(--event-border) !important;
    box-shadow: none !important;
  }

  ::v-deep .custom-control-label::after {
    top: 4px;
    right: -26px;
    left: unset;
    width: 22px;
    height: 22px;
    border-radius: 9999px;
    background-color: var(--event-white);
  }

  ::v-deep .custom-control-input:checked ~ .custom-control-label::after {
    background-color: var(--event-white);
    transform: translateX(22px);
  }

  ::v-deep .custom-control-input:checked ~ .custom-control-label::before {
    color: var(--event-white);
    border-color: var(--event-primary);
    background-color: var(--event-primary) !important;
  }

  &__empty {
    width: auto;
    padding-left: 0;
  }
}
</style>
