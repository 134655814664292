export default ($t) => ({
  title: "CustomTabsUnidyClassic",
  type: "object",
  expanded: true,
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    content: {
      title: $t("edit_campaign.schema.custom_tabs_unidy_classic.content", "Content"),
      type: "list",
      default: [],
      expanded: true,
      items: {
        type: "object",
        properties: {
          key: {
            title: $t("edit_campaign.schema.custom_tabs_unidy_classic.content.items.key", "Key"),
            type: "string",
            default: "",
            placeholder: "custom_component_2",
            description: $t(
              "edit_campaign.schema.custom_tabs_unidy_classic.content.items.key_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          title: {
            title: $t(
              "edit_campaign.schema.custom_tabs_unidy_classic.content.items.title",
              "Title"
            ),
            type: "string",
            default: "",
            placeholder: "Pdf viewer",
            description: $t(
              "edit_campaign.schema.custom_tabs_unidy_classic.content.items.title_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          content: {
            title: $t(
              "edit_campaign.schema.custom_tabs_unidy_classic.content.items.content",
              "Content"
            ),
            type: "string",
            default: "",
            placeholder:
              "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf",
            description: $t(
              "edit_campaign.schema.custom_tabs_unidy_classic.content.items.content_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          component: {
            title: $t(
              "edit_campaign.schema.custom_tabs_unidy_classic.content.items.component",
              "Type"
            ),
            type: "enum",
            default: "pdf",
            description: $t(
              "edit_campaign.schema.custom_tabs_unidy_classic.content.items.component_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
            items: {
              enum: [
                {
                  text: $t(
                    "edit_campaign.schema.custom_tabs_unidy_classic.content.items.component.items.enum.pdf_viewer",
                    "PDF Viewer"
                  ),
                  value: "pdf_viewer",
                },
                {
                  text: $t(
                    "edit_campaign.schema.custom_tabs_unidy_classic.content.items.component.items.enum.team_list",
                    "Team List"
                  ),
                  value: "team_list",
                },
                {
                  text: $t(
                    "edit_campaign.schema.custom_tabs_unidy_classic.content.items.component.items.enum.taggbox",
                    "Taggbox"
                  ),
                  value: "taggbox",
                },
                {
                  text: $t(
                    "edit_campaign.schema.custom_tabs_unidy_classic.content.items.component.items.enum.donors_custom_fields",
                    "Donors custom fields"
                  ),
                  value: "donors_custom_fields",
                },
                {
                  text: $t(
                    "edit_campaign.schema.custom_tabs_unidy_classic.content.items.component.items.enum.remote_api",
                    "Remote API"
                  ),
                  value: "remote_api",
                },
              ],
            },
          },
          settings: {
            title: $t(
              "edit_campaign.schema.custom_tabs_unidy_classic.content.items.settings",
              "Settings"
            ),
            type: "object",
            properties: {
              filter_by_team: {
                title: $t(
                  "edit_campaign.schema.custom_tabs_unidy_classic.content.items.settings.filter_by_team",
                  "Filter by team"
                ),
                type: "boolean",
                default: true,
                description: $t(
                  "edit_campaign.schema.custom_tabs_unidy_classic.content.items.settings.filter_by_team_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              parent_teams_only: {
                title: $t(
                  "edit_campaign.schema.custom_tabs_unidy_classic.content.items.settings.parent_teams_only",
                  "Parent teams only"
                ),
                type: "boolean",
                default: true,
                description: $t(
                  "edit_campaign.schema.custom_tabs_unidy_classic.content.items.settings.parent_teams_only_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              granparent_teams_only: {
                title: $t(
                  "edit_campaign.schema.custom_tabs_unidy_classic.content.items.settings.granparent_teams_only",
                  "Grandparent teams only"
                ),
                type: "boolean",
                default: false,
                description: $t(
                  "edit_campaign.schema.custom_tabs_unidy_classic.content.items.settings.granparent_teams_only_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              remote_api_url: {
                title: $t(
                  "edit_campaign.schema.custom_tabs_unidy_classic.content.items.settings.remote_api_url",
                  "Remote API url"
                ),
                type: "string",
                default:
                  "https://tool.microserviceland.com/service/:service_id/tools/spreadsheetreader/:file_id?max_age=10",
                description: $t(
                  "edit_campaign.schema.custom_tabs_unidy_classic.content.items.settings.remote_api_url_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              remote_api_service_id: {
                title: $t(
                  "edit_campaign.schema.custom_tabs_unidy_classic.content.items.settings.remote_api_service_id",
                  "Remote API service id"
                ),
                type: "string",
                default: "23233505-d614-2a49-1556-e42b7612e154",
                description: $t(
                  "edit_campaign.schema.custom_tabs_unidy_classic.content.items.settings.remote_api_service_id_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              remote_api_file_id: {
                title: $t(
                  "edit_campaign.schema.custom_tabs_unidy_classic.content.items.settings.remote_api_file_id",
                  "Remote API file id"
                ),
                type: "string",
                default: "1Vd74H6Azs4se0nH5UZs4RfeGB1VnkV73ity6exoUJBI",
                description: $t(
                  "edit_campaign.schema.custom_tabs_unidy_classic.content.items.settings.remote_api_file_id_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              colors: {
                title: $t(
                  "edit_campaign.schema.custom_tabs_unidy_classic.content.items.settings.colors",
                  "Cyclic colors: #000000..."
                ),
                type: "array",
                expanded: true,
                description: $t(
                  "edit_campaign.schema.custom_tabs_unidy_classic.content.items.settings.colors_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
            },
          },
          wall_id: {
            title: $t(
              "edit_campaign.schema.custom_tabs_unidy_classic.content.items.wall_id",
              "Wall ID"
            ),
            type: "string",
            default: "",
            placeholder: "57870",
            description: $t(
              "edit_campaign.schema.custom_tabs_unidy_classic.content.items.wall_id_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          lang: {
            title: $t(
              "edit_campaign.schema.custom_tabs_unidy_classic.content.items.lang",
              "Language"
            ),
            type: "object",
            component: "LanguageSelect",
          },
        },
      },
    },
  },
});
