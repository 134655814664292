<template>
  <div v-if="campaignId">
    <event-header-hero class="event-controls-hero" title="Screen Controls" />
    <div class="tw-pb-[82px]">
      <input-text-number
        class="event-search"
        type="text"
        placeholder="Search setting"
        v-model="search"
      >
        <template #icon>
          <icon-search />
        </template>
      </input-text-number>

      <!-- Main view -->
      <div class="form-wrapper" v-show="!isShowSettings">
        <div
          class="form-section"
          v-for="([key, section], index) in Object.entries(schema).filter(([k]) => k !== propName)"
          :key="index"
        >
          <div class="form-section__header">{{ schema[key][propName].title }}</div>
          <div class="form-section__body">
            <event-page-settings-list-item
              v-for="([setting_key, setting], ind) in Object.entries(section).filter(
                ([k]) => k !== propName
              )"
              :key="ind"
              :data="setting[propName]"
              @toggle="showSetting(setting_key)"
            />
          </div>
        </div>
      </div>

      <!-- Open view -->
      <div class="form-wrapper" v-show="isShowSettings || isSearched">
        <!-- language -->
        <event-page-open-settings-wrapper
          @toggle="showSetting"
          :data="schema.general.language[propName]"
          v-show="showSettingsKey === 'language' || isSearched"
        >
          <event-page-open-settings-item
            v-model="search"
            :text="schema.general.language[propName].title"
          >
            <div class="event-select">
              <div class="event-select__label">
                {{ schema.general.language[propName].title }}
              </div>
              <app-select
                class="event-select__select"
                v-model="form.general.language"
                :searchable="false"
                :show-labels="false"
                track-by="value"
                label="text"
                :allow-empty="false"
                :options="schema.general.language.options"
                singleModel
              >
                <template #caret>
                  <span class="multiselect__select">
                    <icon-arrow-right class="multiselect__select__caret" />
                  </span>
                </template>
              </app-select>
            </div>
          </event-page-open-settings-item>
        </event-page-open-settings-wrapper>
        <!-- confetti -->
        <event-page-open-settings-wrapper
          @toggle="showSetting"
          :data="schema.display.confetti[propName]"
          v-show="showSettingsKey === 'confetti' || isSearched"
        >
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.confetti.show_confetti_on_donation_above.title"
          >
            <input-text-number
              :label="schema.display.confetti.show_confetti_on_donation_above.title"
              type="number"
              size="200"
              prefix="$"
              v-model="form.display.confetti.show_confetti_on_donation_above"
            />
          </event-page-open-settings-item>
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.confetti.show_confetti_on_ambassador_goal_above.title"
          >
            <input-text-number
              :label="schema.display.confetti.show_confetti_on_ambassador_goal_above.title"
              type="number"
              size="200"
              prefix="$"
              v-model="form.display.confetti.show_confetti_on_ambassador_goal_above"
            />
          </event-page-open-settings-item>
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.confetti.show_confetti_on_total_raised_above_percent.title"
          >
            <input-text-number
              :label="schema.display.confetti.show_confetti_on_total_raised_above_percent.title"
              type="number"
              size="134"
              sufix="%"
              v-model="form.display.confetti.show_confetti_on_total_raised_above_percent"
            />
          </event-page-open-settings-item>
        </event-page-open-settings-wrapper>
        <!-- pin -->
        <event-page-open-settings-wrapper
          @toggle="showSetting"
          :data="schema.display.pin[propName]"
          v-show="showSettingsKey === 'pin' || isSearched"
        >
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.pin.donation_total_or_ambassador_goal_above.title"
          >
            <input-text-number
              :label="schema.display.pin.donation_total_or_ambassador_goal_above.title"
              type="number"
              size="200"
              prefix="$"
              v-model="form.display.pin.donation_total_or_ambassador_goal_above"
            />
          </event-page-open-settings-item>
        </event-page-open-settings-wrapper>
        <event-page-open-settings-wrapper
          @toggle="showSetting"
          :data="schema.display.pin[propName].secondsection"
          v-show="showSettingsKey === 'pin' || isSearched"
        >
          <event-page-open-settings-item
            v-if="filteredDonations.length"
            v-model="search"
            :text="schema.display.pin.donation_total_or_ambassador_goal_above.title"
          >
            <div class="event-checkbox-helper">
              <checkbox-switch
                class="event-checkbox-helper__item"
                v-for="(item, ind) in filteredDonations"
                :key="ind"
                v-model="pinnedItems[`${item.team_id}-${item.donation_id}`]"
              >
                <span class="event-checkbox-helper__label">
                  <span class="event-checkbox-helper__icon">
                    <icon-pin-card />
                  </span>
                  <span class="event-checkbox-helper__text">
                    {{ item.donor_name || item.team_name }}
                  </span>
                  <span class="event-checkbox-helper__value"> ${{ item.donation_amount }} </span>
                </span>
              </checkbox-switch>
            </div>
          </event-page-open-settings-item>
        </event-page-open-settings-wrapper>
        <!-- commitment_cards -->
        <event-page-open-settings-wrapper
          @toggle="showSetting"
          :data="schema.display.commitment_cards[propName]"
          v-show="showSettingsKey === 'commitment_cards' || isSearched"
        >
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.commitment_cards.show_donors.title"
          >
            <checkbox-switch v-model="form.display.commitment_cards.show_donors">
              {{ schema.display.commitment_cards.show_donors.title }}
            </checkbox-switch>
          </event-page-open-settings-item>
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.commitment_cards.show_ambassadors.title"
          >
            <checkbox-switch v-model="form.display.commitment_cards.show_ambassadors">
              {{ schema.display.commitment_cards.show_ambassadors.title }}
            </checkbox-switch>
          </event-page-open-settings-item>
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.commitment_cards.show_joined_donors_and_ambassadors.title"
          >
            <checkbox-switch
              v-model="form.display.commitment_cards.show_joined_donors_and_ambassadors"
            >
              {{ schema.display.commitment_cards.show_joined_donors_and_ambassadors.title }}
            </checkbox-switch>
          </event-page-open-settings-item>
        </event-page-open-settings-wrapper>
        <event-page-open-settings-wrapper
          @toggle="showSetting"
          :data="schema.display.commitment_cards[propName].secondsection"
          v-show="showSettingsKey === 'commitment_cards' || isSearched"
        >
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.commitment_cards.mode.title"
          >
            <div class="event-radio-group-wrapper">
              {{ schema.display.commitment_cards.mode.title }}
              <b-form-group class="event-radio-group">
                <b-form-radio-group
                  v-model="form.display.commitment_cards.mode"
                  :options="schema.display.commitment_cards.mode.options"
                  name="commitment_cards-mode"
                  button-variant="primary"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </event-page-open-settings-item>
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.commitment_cards.new_don_cards_should_apear.title"
          >
            <checkbox-switch v-model="new_don_cards_should_apear_value">
              {{ schema.display.commitment_cards.new_don_cards_should_apear.title }}
            </checkbox-switch>
          </event-page-open-settings-item>
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.commitment_cards.new_team_cards_should_apear.title"
          >
            <checkbox-switch v-model="new_team_cards_should_apear_value">
              {{ schema.display.commitment_cards.new_team_cards_should_apear.title }}
            </checkbox-switch>
          </event-page-open-settings-item>
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.commitment_cards.layout_cards_number.title"
          >
            <div class="event-radio-group-wrapper">
              {{ schema.display.commitment_cards.layout_cards_number.title }}
              <b-form-group class="event-radio-group">
                <b-form-radio-group
                  v-model="form.display.commitment_cards.layout_cards_number"
                  :options="schema.display.commitment_cards.layout_cards_number.options"
                  name="commitment_cards-layout_cards_number"
                  button-variant="primary"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </event-page-open-settings-item>
        </event-page-open-settings-wrapper>
        <!-- scroll_lines -->
        <event-page-open-settings-wrapper
          @toggle="showSetting"
          :data="schema.display.scroll_lines[propName]"
          v-show="showSettingsKey === 'scroll_lines' || isSearched"
        >
          <event-page-open-settings-item
            v-model="search"
            :text="
              schema.display.scroll_lines[propName].title +
              ' ' +
              schema.display.scroll_lines.row1.active.title +
              ' ' +
              schema.display.scroll_lines.row1.title.title +
              ' ' +
              schema.display.scroll_lines.row1.show_amount_range_from.title +
              ' ' +
              schema.display.scroll_lines.row1.show_amount_range_to.title
            "
          >
            <div class="event-scrolling-settings-wrapper">
              <checkbox-switch v-model="form.display.scroll_lines.row1.active">
                <span class="tw-font-bold">
                  {{ schema.display.scroll_lines.row1.active.title }}
                </span>
              </checkbox-switch>
              <div class="event-select">
                <div class="event-select__label">
                  {{ schema.display.scroll_lines.row1.scroll.title }}
                </div>
                <app-select
                  class="event-select__select"
                  v-model="form.display.scroll_lines.row1.scroll"
                  :searchable="false"
                  :show-labels="false"
                  track-by="value"
                  label="text"
                  :allow-empty="false"
                  :options="schema.display.scroll_lines.row1.scroll.options"
                  singleModel
                >
                  <template #caret>
                    <span class="multiselect__select">
                      <icon-arrow-right class="multiselect__select__caret" />
                    </span>
                  </template>
                </app-select>
              </div>

              <input-text-number
                class="event-scrolling-settings-wrapper__input"
                :label="schema.display.scroll_lines.row1.title.title"
                type="text"
                size="310"
                v-model="form.display.scroll_lines.row1.title"
              />
              <div class="event-two-inputs-wrapper">
                <span class="event-two-inputs-wrapper__title">
                  {{ schema.display.scroll_lines.row1.show_amount_range_from.title }}
                </span>
                <input-text-number
                  class="event-two-inputs-wrapper__input"
                  type="number"
                  size="200"
                  prefix="$"
                  v-model="form.display.scroll_lines.row1.show_amount_range_from"
                />
                <span>
                  {{ schema.display.scroll_lines.row1.show_amount_range_to.title }}
                </span>
                <input-text-number
                  class="event-two-inputs-wrapper__input"
                  type="number"
                  size="200"
                  prefix="$"
                  v-model="form.display.scroll_lines.row1.show_amount_range_to"
                />
              </div>
            </div>
          </event-page-open-settings-item>
          <event-page-open-settings-item
            v-model="search"
            :text="
              schema.display.scroll_lines[propName].title +
              ' ' +
              schema.display.scroll_lines.row2.active.title +
              ' ' +
              schema.display.scroll_lines.row2.title.title +
              ' ' +
              schema.display.scroll_lines.row2.show_amount_range_from.title +
              ' ' +
              schema.display.scroll_lines.row2.show_amount_range_to.title
            "
          >
            <div class="event-scrolling-settings-wrapper">
              <checkbox-switch v-model="form.display.scroll_lines.row2.active">
                <span class="tw-font-bold">
                  {{ schema.display.scroll_lines.row2.active.title }}
                </span>
              </checkbox-switch>
              <div class="event-select">
                <div class="event-select__label">
                  {{ schema.display.scroll_lines.row2.scroll.title }}
                </div>
                <app-select
                  class="event-select__select"
                  v-model="form.display.scroll_lines.row2.scroll"
                  :searchable="false"
                  :show-labels="false"
                  track-by="value"
                  label="text"
                  :allow-empty="false"
                  :options="schema.display.scroll_lines.row2.scroll.options"
                  singleModel
                >
                  <template #caret>
                    <span class="multiselect__select">
                      <icon-arrow-right class="multiselect__select__caret" />
                    </span>
                  </template>
                </app-select>
              </div>

              <input-text-number
                class="event-scrolling-settings-wrapper__input"
                :label="schema.display.scroll_lines.row2.title.title"
                type="text"
                size="310"
                v-model="form.display.scroll_lines.row2.title"
              />
              <div class="event-two-inputs-wrapper">
                <span class="event-two-inputs-wrapper__title">
                  {{ schema.display.scroll_lines.row2.show_amount_range_from.title }}
                </span>
                <input-text-number
                  class="event-two-inputs-wrapper__input"
                  type="number"
                  size="200"
                  prefix="$"
                  v-model="form.display.scroll_lines.row2.show_amount_range_from"
                />
                <span>
                  {{ schema.display.scroll_lines.row2.show_amount_range_to.title }}
                </span>
                <input-text-number
                  class="event-two-inputs-wrapper__input"
                  type="number"
                  size="200"
                  prefix="$"
                  v-model="form.display.scroll_lines.row2.show_amount_range_to"
                />
              </div>
            </div>
          </event-page-open-settings-item>
        </event-page-open-settings-wrapper>
        <!-- section_stats -->
        <event-page-open-settings-wrapper
          @toggle="showSetting"
          :data="schema.display.section_stats[propName]"
          v-show="showSettingsKey === 'section_stats' || isSearched"
        >
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.section_stats.show_total_raised.title"
          >
            <checkbox-switch v-model="form.display.section_stats.show_total_raised">
              {{ schema.display.section_stats.show_total_raised.title }}
            </checkbox-switch>
          </event-page-open-settings-item>
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.section_stats.show_total_goal.title"
          >
            <checkbox-switch v-model="form.display.section_stats.show_total_goal">
              {{ schema.display.section_stats.show_total_goal.title }}
            </checkbox-switch>
          </event-page-open-settings-item>
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.section_stats.show_number_donors.title"
          >
            <checkbox-switch v-model="form.display.section_stats.show_number_donors">
              {{ schema.display.section_stats.show_number_donors.title }}
            </checkbox-switch>
          </event-page-open-settings-item>
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.section_stats.show_number_ambassadors.title"
          >
            <checkbox-switch v-model="form.display.section_stats.show_number_ambassadors">
              {{ schema.display.section_stats.show_number_ambassadors.title }}
            </checkbox-switch>
          </event-page-open-settings-item>
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.section_stats.show_total_ambassadors_goal.title"
          >
            <checkbox-switch v-model="form.display.section_stats.show_total_ambassadors_goal">
              {{ schema.display.section_stats.show_total_ambassadors_goal.title }}
            </checkbox-switch>
          </event-page-open-settings-item>
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.section_stats.show_total_donation_pledges.title"
          >
            <checkbox-switch v-model="form.display.section_stats.show_total_donation_pledges">
              {{ schema.display.section_stats.show_total_donation_pledges.title }}
            </checkbox-switch>
          </event-page-open-settings-item>
          <event-page-open-settings-item
            v-model="search"
            :text="schema.display.section_stats.show_total_raised_in_percentage.title"
          >
            <checkbox-switch v-model="form.display.section_stats.show_total_raised_in_percentage">
              {{ schema.display.section_stats.show_total_raised_in_percentage.title }}
            </checkbox-switch>
          </event-page-open-settings-item>
        </event-page-open-settings-wrapper>
        <!-- color -->
        <event-page-open-settings-wrapper
          @toggle="showSetting"
          :data="schema.general.color[propName]"
          v-show="showSettingsKey === 'color' || isSearched"
        >
          <event-page-open-settings-item
            v-model="search"
            :text="
              schema.general.color.theme.classic.title +
              ' ' +
              schema.general.color.theme.elegant.title
            "
          >
            <div class="event-color-theme-settings-wrapper">
              <div class="event-color-theme-settings-wrapper__item">
                {{ schema.general.color.theme.classic.title }}
                <img
                  src="@/assets/img/event-page/classic.png"
                  :class="[
                    form.general.theme === schema.general.color.theme.classic.value ? 'active' : '',
                  ]"
                  alt="classic-theme"
                />
                <checkbox-switch
                  v-model="form.general.theme"
                  :unchecked-value="schema.general.color.theme.elegant.value"
                  :checked-value="schema.general.color.theme.classic.value"
                />
              </div>

              <div class="event-color-theme-settings-wrapper__item">
                {{ schema.general.color.theme.elegant.title }}
                <img
                  src="@/assets/img/event-page/elegant.png"
                  :class="[
                    form.general.theme === schema.general.color.theme.elegant.value ? 'active' : '',
                  ]"
                  alt="elegant-theme"
                />
                <checkbox-switch
                  v-model="form.general.theme"
                  :unchecked-value="schema.general.color.theme.classic.value"
                  :checked-value="schema.general.color.theme.elegant.value"
                />
              </div>
            </div>
          </event-page-open-settings-item>
        </event-page-open-settings-wrapper>
        <event-page-open-settings-wrapper
          @toggle="showSetting"
          :data="schema.general.color[propName].secondsection"
          v-show="showSettingsKey === 'color' || isSearched"
        >
          <event-page-open-settings-item
            v-model="search"
            :text="
              schema.general.color.color.title +
              ' ' +
              schema.general.color.imgs
                .map((img) => `${img.title} ${img.description} ${img.btnText}`)
                .join(' ')
            "
          >
            <div class="event-color-settings-wrapper">
              <div
                v-for="img in schema.general.color.imgs"
                :key="img.id"
                class="event-color-settings-wrapper-files"
              >
                <img v-if="form.general[img.id]" :src="form.general[img.id]" alt="" />
                <div class="event-color-settings-wrapper-files-text">
                  <p>{{ img.title }}</p>
                  <span>{{ img.description }}</span>
                </div>
                <input-file
                  :file="img"
                  :loading="loadingImageId === img.id"
                  @change="addImg($event, img.id)"
                />
              </div>

              <p class="event-color-settings-wrapper__title">
                {{ schema.general.color.color.title }}
              </p>
              <div class="event-color-settings-wrapper-controls">
                <div
                  class="event-color-settings-wrapper-controls__item"
                  v-for="item in 3"
                  :key="item"
                >
                  <p>Color #{{ item }}</p>
                  <div>
                    <div class="event-input-color-wrapper">
                      <div class="event-input-color-wrapper-inner">
                        <input
                          type="color"
                          class="event-input-color"
                          v-model="form.general[`color${item}`]"
                        />
                      </div>
                    </div>
                    <input
                      type="text"
                      class="event-input-color-text"
                      v-model="form.general[`color${item}`]"
                    />
                  </div>
                </div>
              </div>
              <div class="event-color-settings-wrapper-result">
                <sample-donor-card
                  :color1="form.general.color1"
                  :color2="form.general.color2"
                  :color3="form.general.color3"
                />
              </div>
            </div>
          </event-page-open-settings-item>
        </event-page-open-settings-wrapper>
        <btn type="primary" :loading="loading" @click="save">
          {{ this.$t("eventpage.settings.saveBtnText", "Save Changes") }}
        </btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EventPageSettingsListItem from "./event-page-settings-list-item.vue";
import EventPageOpenSettingsWrapper from "./event-page-open-settings-wrapper.vue";
import EventPageOpenSettingsItem from "./event-page-open-settings-item.vue";
import InputTextNumber from "@/views/event-page/ui/input.vue";
import iconPinCard from "@/views/event-page/icons/pin-card.vue";
import iconSearch from "@/views/event-page/icons/search.vue";
import appSelect from "@/common-components/ui-elements/app-select.vue";
import iconArrowRight from "@/views/event-page/icons/arrow-right.vue";
import SampleDonorCard from "./event-page-sample-donor-card.vue";
import axios from "axios";
import urls from "@/constants/urls";
import InputFile from "@/views/event-page/ui/input-file.vue";
import Btn from "@/views/event-page/ui/btn.vue";
import EventHeaderHero from "@/views/event-page/header/header-hero.vue";
import CheckboxSwitch from "@/views/event-page/ui/checkbox-switch.vue";
import { deepCopy } from "@/helpers/calendarModule";
import AddTeamFromCampaignShema from "@/views/campaigns/campaign-settings/settings-schemas/add_team_from_campaign.schema.js";
import PlaceNewDonationsOnHold from "@/views/campaigns/campaign-settings/settings-schemas/place_new_donations_on_hold.schema.js";

export default {
  name: "event-page-settings",
  data() {
    return {
      pinnedItems: {},
      new_don_cards_should_apear_value: false,
      new_team_cards_should_apear_value: false,
      commonMeta: {
        new_don_cards_should_apear: {
          data: null,
          metaName: "place_new_donations_on_hold",
          scheme: PlaceNewDonationsOnHold((key, fallback) => this.$t(key, fallback)),
          key: "value",
        },
        new_team_cards_should_apear: {
          data: null,
          metaName: "add_team_from_campaign",
          scheme: AddTeamFromCampaignShema((key, fallback) => this.$t(key, fallback)),
          key: "hidden",
        },
      },
      loading: false,
      form: {},
      showSettingsKey: "",
      propName: "properties",
      search: "",
      loadingImageId: null,
    };
  },

  components: {
    appSelect,
    EventPageOpenSettingsItem,
    EventPageSettingsListItem,
    EventPageOpenSettingsWrapper,
    InputTextNumber,
    iconPinCard,
    iconSearch,
    iconArrowRight,
    SampleDonorCard,
    InputFile,
    Btn,
    EventHeaderHero,
    CheckboxSwitch,
  },

  computed: {
    ...mapState({
      oID: () => localStorage.getItem("orgId"),
      eventDonationsList: (state) => {
        return state.eventPlatform.eventDonations;
      },
      eventSettingsList: (state) => {
        return state.eventPlatform.eventSettingsSchema;
      },
    }),

    filteredDonations() {
      if (!this.form?.display?.pin?.manual_list || !this.eventDonationsList) {
        return [];
      }

      return this.eventDonationsList.filter((donation) =>
        this.form.display.pin.manual_list.some(
          (pinned) =>
            pinned.team_id === donation.team_id && pinned.donation_id === donation.donation_id
        )
      );
    },

    campaignId() {
      return this.$store.state.dashboard.selectedCampaign;
    },

    isShowSettings() {
      if (this.isSearched) {
        return true;
      } else {
        return this.showSettingsKey;
      }
    },
    isSearched() {
      return this.search.length > 0;
    },
    schema() {
      return {
        general: {
          properties: {
            title: this.$t("eventpage.settings.section.general.title", "General"),
          },
          language: {
            properties: {
              title: this.$t("eventpage.settings.general.language.title", "Display Language"),
              description: this.$t(
                "eventpage.settings.general.language.description",
                "Select preferred language"
              ),
              icon: "earth",
            },
            options: [
              {
                text: this.$t("eventpage.settings_general_language_hebrew_text", "Hebrew"),
                value: "he",
              },
              {
                text: this.$t("eventpage.settings_general_language_english_text", "English"),
                value: "en",
              },
            ],
          },
          color: {
            properties: {
              title: this.$t("eventpage.settings.general.color.title", "Theme"),
              description: this.$t(
                "eventpage.settings.general.color.description",
                "Classic or Gala"
              ),
              icon: "laptop",
              secondsection: {
                title: this.$t("eventpage.settings.general.color.secondsection.title", "Colors"),
                icon: "laptop",
              },
            },
            theme: {
              classic: {
                title: this.$t("eventpage.settings.general.color.theme.classic.title", "Classic"),
                value: "classic",
              },
              elegant: {
                title: this.$t("eventpage.settings.general.color.theme.elegant.title", "Elegant"),
                value: "elegant",
              },
            },
            imgs: [
              {
                id: "background_image",
                title: this.$t(
                  "eventpage.settings.general.color.background_image.title",
                  "Upload Background Image for Screen"
                ),
                description: this.$t(
                  "eventpage.settings.general.color.background_image.description",
                  "For best quality, recommended size 1920x1080, max 2MB"
                ),
                btnText: this.$t(
                  "eventpage.settings.general.color.background_image.btnText",
                  "Upload Image"
                ),
              },
              {
                id: "organization_logo",
                title: this.$t(
                  "eventpage.settings.general.color.organization_logo.title",
                  "Upload Organization Logo"
                ),
                description: this.$t(
                  "eventpage.settings.general.color.organization_logo.description",
                  "This will be displayed on the screen. If you are using a dark background, make sure the logo is a light color."
                ),
                btnText: this.$t(
                  "eventpage.settings.general.color.organization_logo.btnText",
                  "Upload Image"
                ),
              },
            ],
            color: {
              title: this.$t(
                "eventpage.settings.general.color.color.title",
                "Choose Colors for your campaign"
              ),
            },
          },
        },
        display: {
          properties: {
            title: this.$t("eventpage.settings.section.display.title", "Display"),
          },
          commitment_cards: {
            properties: {
              title: this.$t(
                "eventpage.settings.display.commitment_cards.title",
                "Commitment Cards"
              ),
              description: this.$t(
                "eventpage.settings.display.commitment_cards.description",
                "Choose which commitments to display on screen"
              ),
              icon: "list",
              secondsection: {
                title: this.$t(
                  "eventpage.settings.display.commitment_cards.secondsection.title",
                  "Layout"
                ),
                icon: "list",
              },
            },
            show_donors: {
              title: this.$t(
                "eventpage.settings.display.commitment_cards.show_donors.title",
                "Donor Commitments"
              ),
            },
            show_ambassadors: {
              title: this.$t(
                "eventpage.settings.display.commitment_cards.show_ambassadors.title",
                "Ambassador Commitment"
              ),
            },
            show_joined_donors_and_ambassadors: {
              title: this.$t(
                "eventpage.settings.display.commitment_cards.show_joined_donors_and_ambassadors.title",
                "Donor+Ambassador Commitments"
              ),
            },
            mode: {
              title: this.$t(
                "eventpage.settings.display.commitment_cards.mode.title",
                "New cards should appear on the screen"
              ),
              options: [
                {
                  text: this.$t(
                    "eventpage.settings.display.commitment_cards.mode.manual",
                    "Manually"
                  ),
                  value: "manual",
                },
                {
                  text: this.$t(
                    "eventpage.settings.display.commitment_cards.mode.automatic",
                    "Automatically"
                  ),
                  value: "automatic",
                },
              ],
            },
            new_don_cards_should_apear: {
              title: this.$t(
                "eventpage.settings.display.commitment_cards.new_don_cards_should_apear.title",
                "New donation cards should appear on the screen"
              ),
            },
            new_team_cards_should_apear: {
              title: this.$t(
                "eventpage.settings.display.commitment_cards.new_team_cards_should_apear.title",
                "New team cards should appear on the screen"
              ),
            },
            layout_cards_number: {
              title: this.$t(
                "eventpage.settings.display.commitment_cards.layout_cards_number.title",
                "Choose maximum donor cards to displayon screen"
              ),
              options: [
                { text: "10", value: 10 },
                { text: "18", value: 18 },
                { text: "26", value: 26 },
              ],
            },
          },
          scroll_lines: {
            properties: {
              title: this.$t("eventpage.settings.display.scroll_lines.title", "Scrolling"),
              description: this.$t(
                "eventpage.settings.display.scroll_lines.description",
                "Option to scroll donations and ambassadors"
              ),
              icon: "move",
            },
            row1: {
              active: {
                title: this.$t(
                  "eventpage.settings.display.scroll_lines.row1.active.title",
                  "1st Row"
                ),
              },
              title: {
                title: this.$t(
                  "eventpage.settings.display.scroll_lines.row1.title.title",
                  "Choose title to be displayed"
                ),
              },
              scroll: {
                title: this.$t(
                  "eventpage.settings.display.scroll_lines.row1.scroll.title",
                  "Scroll"
                ),
                options: [
                  { text: "Donors", value: "donors" },
                  { text: "Ambassadors", value: "ambassadors" },
                ],
              },
              show_amount_range_from: {
                title: this.$t(
                  "eventpage.settings.display.scroll_lines.row1.show_amount_range_from.title",
                  "Show amounts from"
                ),
              },
              show_amount_range_to: {
                title: this.$t(
                  "eventpage.settings.display.scroll_lines.row1.show_amount_range_to.title",
                  "to"
                ),
              },
            },
            row2: {
              active: {
                title: this.$t(
                  "eventpage.settings.display.scroll_lines.row2.active.title",
                  "2nd Row"
                ),
              },
              title: {
                title: this.$t(
                  "eventpage.settings.display.scroll_lines.row2.title.title",
                  "Choose title to be displayed"
                ),
              },
              scroll: {
                title: this.$t(
                  "eventpage.settings.display.scroll_lines.row2.scroll.title",
                  "Scroll"
                ),
                options: [
                  { text: "Donors", value: "donors" },
                  { text: "Ambassadors", value: "ambassadors" },
                ],
              },
              show_amount_range_from: {
                title: this.$t(
                  "eventpage.settings.display.scroll_lines.row2.show_amount_range_from.title",
                  "Show amounts from"
                ),
              },
              show_amount_range_to: {
                title: this.$t(
                  "eventpage.settings.display.scroll_lines.row2.show_amount_range_to.title",
                  "to"
                ),
              },
            },
          },
          pin: {
            properties: {
              title: this.$t("eventpage.settings.display.pin.title", "Pin"),
              description: this.$t(
                "eventpage.settings.display.pin.description",
                "Option to pin donations or pin rows"
              ),
              icon: "lock",
              secondsection: {
                title: this.$t(
                  "eventpage.settings.display.pin.secondsection.title",
                  "Currently Pinned Donor Cards"
                ),
                icon: "lock",
              },
            },
            donation_total_or_ambassador_goal_above: {
              title: this.$t(
                "eventpage.settings.display.pin.donation_total_or_ambassador_goal_above.title",
                "Pin all published donations or ambassador goals that are above:"
              ),
            },
          },
          confetti: {
            properties: {
              title: this.$t("eventpage.settings.display.confetti.title", "Confetti"),
              description: this.$t(
                "eventpage.settings.display.confetti.description",
                "Control when confetti is automatically displayed"
              ),
              icon: "confetti",
            },
            show_confetti_on_donation_above: {
              title: this.$t(
                "eventpage.settings.display.confetti.show_confetti_on_donation_above.title",
                "Automatically display confetti when a donation higher than this amount is published:"
              ),
            },
            show_confetti_on_ambassador_goal_above: {
              title: this.$t(
                "eventpage.settings.display.confetti.show_confetti_on_ambassador_goal_above.title",
                "Automatically display confetti when an ambassador goal higher than this amount is published:"
              ),
            },
            show_confetti_on_total_raised_above_percent: {
              title: this.$t(
                "eventpage.settings.display.confetti.show_confetti_on_total_raised_above_percent.title",
                "Automatically display confetti when a this percentage of total goal is reached"
              ),
            },
          },
          section_stats: {
            properties: {
              title: this.$t("eventpage.settings.display.section_stats.title", "Goals"),
              description: this.$t(
                "eventpage.settings.display.section_stats.description",
                "Choose which goals you want displayed"
              ),
              icon: "bd",
            },
            show_total_raised: {
              title: this.$t(
                "eventpage.settings.display.section_stats.show_total_raised.title",
                "Total Raised"
              ),
            },
            show_total_goal: {
              title: this.$t(
                "eventpage.settings.display.section_stats.show_total_goal.title",
                "Total Goal"
              ),
            },
            show_number_donors: {
              title: this.$t(
                "eventpage.settings.display.section_stats.show_number_donors.title",
                "Total Amount of Donors "
              ),
            },
            show_number_ambassadors: {
              title: this.$t(
                "eventpage.settings.display.section_stats.show_number_ambassadors.title",
                "Total Amount of Ambassadors"
              ),
            },
            show_total_ambassadors_goal: {
              title: this.$t(
                "eventpage.settings.display.section_stats.show_total_ambassadors_goal.title",
                "Total Ambassador Goal"
              ),
            },
            show_total_donation_pledges: {
              title: this.$t(
                "eventpage.settings.display.section_stats.show_total_donation_pledges.title",
                "Pledge Goal"
              ),
            },
            show_total_raised_in_percentage: {
              title: this.$t(
                "eventpage.settings.display.section_stats.show_total_raised_in_percentage.title",
                "Goal in Percentage"
              ),
            },
          },
        },
      };
    },
  },

  watch: {
    campaignId: function () {
      this.showSettingsKey = "";
      this.search = "";
      this.initForm();
      this.getSetting();
    },

    filteredDonations: {
      immediate: true,
      handler(newList) {
        this.pinnedItems = newList.reduce((acc, item) => {
          const key = `${item.team_id}-${item.donation_id}`;
          acc[key] = true;
          return acc;
        }, {});
      },
    },

    eventSettingsList: {
      handler(newList) {
        this.form.display.pin.manual_list = newList?.display?.pin?.manual_list;
      },
    },
  },

  methods: {
    async addImg(event, id) {
      const file = event.target.files[0];
      if (!file) return;

      this.loadingImageId = id;

      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(urls.loadImageQuill, formData);
        this.form.general[id] = response.data.src;
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        this.loadingImageId = null;
      }
    },
    showSetting(key) {
      this.search = "";
      this.showSettingsKey = key || "";
    },
    async getSetting() {
      await this.$store.dispatch("getEventSettings").then((data) => {
        if (data) {
          this.form = deepCopy(data.attributes.meta_data);
        }
      });

      const promises = Object.keys(this.commonMeta).map((key) => {
        this.commonMeta[key].error = false;
        return this.$store
          .dispatch("getMetaSettings", this.commonMeta[key].metaName)
          .then((data) => {
            if (data) {
              this[`${key}_value`] = !!data?.setting?.[this.commonMeta[key].key];
              this.commonMeta[key].data = data;
            }
          })
          .catch((e) => {
            this.$_notificationsMixin_handleCatch(e);
            this.commonMeta[key].error = true;
            throw e;
          });
      });

      await Promise.all(promises);
    },

    async save() {
      this.form.display.pin.manual_list = this.form.display.pin.manual_list.filter(
        (pinned) => this.pinnedItems[`${pinned.team_id}-${pinned.donation_id}`] !== false
      );

      if (!this.form.general.language || typeof this.form.general.language !== "string") {
        this.form.general.language = "en";
      }

      await this.$store
        .dispatch("saveEventSettings", { form: this.form })
        .then((data) => {
          this.form = deepCopy(data.attributes.meta_data);
          this.$_notificationsMixin_makeToast(
            "Success",
            this.$t("edit_campaign.setting_saved", "Saved successfully"),
            "success"
          );
        })
        .catch((e) => {
          this.$_notificationsMixin_handleCatch(e);
          throw e;
        });

      const promises = Object.keys(this.commonMeta).map((key) => {
        const valueKey = `${key}_value`;
        const value = this[valueKey];

        if (this.commonMeta[key].error) {
          return;
        }

        return this.$store
          .dispatch("saveMetaSettings", {
            ...this.commonMeta[key],
            value,
            key: this.commonMeta[key].key,
          })
          .then(() => {
            this.$_notificationsMixin_makeToast(
              "Success",
              this.$t(
                "edit_event_page.setting_saved",
                `${this.commonMeta[key].metaName} saved successfully`
              ),
              "success"
            );
          })
          .catch((e) => {
            this.$_notificationsMixin_handleCatch(e);
            throw e;
          });
      });

      await Promise.all(promises);
    },

    initForm() {
      const data = {
        general: {
          language: "en",
          theme: "classic",
          background_image: "",
          organization_logo: "",
          color1: "#120A25",
          color2: "#FFD8A6",
          color3: "#B1719E",
        },
        display: {
          section_stats: {
            show_total_raised: true,
            show_total_goal: true,
            show_number_donors: true,
            show_number_ambassadors: true,
            show_total_ambassadors_goal: true,
            show_total_donation_pledges: true,
            show_total_raised_in_percentage: true,
          },
          confetti: {
            show_confetti_on_donation_above: 5000,
            show_confetti_on_ambassador_goal_above: 5000,
            show_confetti_on_total_raised_above_percent: 50,
          },
          pin: {
            donation_total_or_ambassador_goal_above: 5000,
            manual_list: [],
          },
          commitment_cards: {
            show_donors: true,
            show_ambassadors: true,
            show_joined_donors_and_ambassadors: true,
            mode: "automatic",
            layout_cards_number: 10,
          },
          scroll_lines: {
            row1: {
              active: true,
              scroll: "donors",
              title: "Silver donors",
              show_amount_range_from: 1000,
              show_amount_range_to: 5000,
            },
            row2: {
              active: true,
              scroll: "ambassadors",
              title: "Silver donors",
              show_amount_range_from: 1000,
              show_amount_range_to: 5000,
            },
          },
        },
      };
      this.form = data;

      this.$store.dispatch("setInitialEventSettings", data);
    },
  },

  created() {
    this.initForm();
  },

  mounted() {
    this.getSetting();
  },
};
</script>

<style lang="scss" scoped>
.event-controls-hero {
  padding-block: 100px;
  @media (max-width: 768px) {
    padding-block: 60px;
  }
}

.event-search {
  padding-block-start: 82px;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 46px;
  color: var(--event-black);
  font-size: 20px;

  .form-section {
    &__header {
      padding: 17px 86px;
      border: 1px solid var(--event-dark-blue);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      font-weight: 700;
      background-color: var(--event-dark-blue);
      color: var(--event-white);
    }
  }
}

.event-checkbox-helper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding-block: 36px;

  &__label {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin-inline-end: 20px;
    border-radius: 9999px;
    background-color: var(--event-secondary);
  }

  &__text {
    width: 166px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__item {
    padding-left: 0 !important;
  }
}

.event-radio-group-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  padding-left: var(--padding-left);
  padding-block: 36px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding-left: 0;
  }
}

::v-deep .event-radio-group {
  flex-shrink: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    flex-shrink: 1;
  }

  .btn-group {
    gap: 8px;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  .btn-primary {
    height: auto;
    padding: 14px;
    border-radius: 15px !important;
    border-color: var(--event-not-active);
    background-color: var(--event-not-active);
    font-family: inherit !important;
    font-weight: 400 !important;
    font-size: 19px;
    line-height: 1;
    @media (max-width: 768px) {
      flex-grow: 0;
    }
  }

  .btn-primary:not(:disabled):not(.disabled).active {
    border-color: var(--event-primary);
    background-color: var(--event-primary);
    color: var(--event-white);
  }

  .btn-primary:not(:disabled):not(.disabled):active {
    border-color: var(--event-primary);
    background-color: var(--event-primary);
  }
}

.event-scrolling-settings-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding-block: 36px;

  &__input {
    padding-block: 0;
  }
}

.event-two-inputs-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding-left: var(--padding-left);
  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding-left: 0;
  }

  &__title {
    margin-right: auto;
    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  &__input {
    width: auto;
    padding-block: 0;
    padding-left: 0;
  }
}

::v-deep .event-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  padding-left: var(--padding-left);
  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding-left: 0;
  }

  &__select {
    width: 294px;

    .multiselect {
      color: var(--event-soft-dark);
      font-size: 23px;
      cursor: pointer;
    }

    .multiselect__select {
      position: absolute;
      top: 0px;
      right: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 80px;
      transition: transform 0.2s ease;

      &::before {
        content: none;
      }

      svg {
        transform: rotate(90deg);
      }
    }

    .multiselect__single {
      padding-left: 12px;
      font-size: 23px;
      background-color: var(--event-ui-bg);
    }

    .multiselect__tags {
      min-height: 62px;
      padding-top: 16px;
      padding-right: 80px;
      border-color: transparent;
      border-radius: 9999px;
      background-color: var(--event-ui-bg);
    }

    .multiselect__content-wrapper {
      border-color: transparent;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .multiselect--active {
      .multiselect__select {
        transform: rotate(180deg);
      }
      .multiselect__content-wrapper {
        box-shadow: 0px 2.26878px 22.6878px rgba(0, 0, 0, 0.05);
      }
      .multiselect__tags {
        border-radius: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    .multiselect__option {
      padding: 20px 30px;
      line-height: 23px;
    }

    .multiselect__option--highlight {
      background-color: transparent;
      color: var(--event-primary);
    }

    .multiselect__option--selected {
      background-color: transparent;
      color: var(--event-primary);
      font-weight: 400;
    }
  }
}

.event-color-theme-settings-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 27px 22px 37px 31px;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding-left: 24px;

    &:first-child {
      border-right: 1px solid var(--event-border);
      padding-right: 24px;
      padding-left: 0;
    }

    img {
      margin-top: 30px;
      margin-bottom: 42px;
      border: 2px solid transparent;
      border-radius: 10px;
      &.active {
        border: 2px solid var(--event-primary);
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
    &__item {
      border: 0 !important;
      padding: 0 !important;
      img {
        margin-top: 20px;
        margin-bottom: 26px;
      }
    }
  }
}

.event-color-settings-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  padding-block: 36px;
  padding-left: var(--padding-left);
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding-left: 0;
  }

  &__title {
    margin-bottom: 10px;
    grid-column: 1 / -1;
  }

  &-controls {
    display: flex;
    flex-direction: column;
    gap: 36px;

    &__item {
      display: flex;
      flex-direction: column;
      & > p {
        margin-bottom: 16px;
      }

      & > div {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
  }

  &-result {
    padding-top: 24px;
    margin: 0 auto;
  }

  &-files {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    justify-content: space-between;
    grid-column: 1 / -1;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    img {
      grid-column: 1 / -1;
    }

    &-text {
      max-width: 360px;
      @media (max-width: 768px) {
        max-width: 100%;
      }

      p {
        margin-top: 20px;
        margin-bottom: 0;
      }
      span {
        font-size: 17px;
        font-style: italic;
      }
    }
  }
}

.event-input-color-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 100%;
  aspect-ratio: 1 / 1;
  padding: 10px;
  border: 1px solid var(--event-border);
  border-radius: 9999px;

  &-inner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 9999px;
  }

  .event-input-color {
    position: absolute;
    top: -50%;
    left: -50%;
    width: calc(100% * 2);
    height: calc(100% * 2);
    padding: 0;
    border: 0;
    background-color: transparent;
    line-height: 1;
    outline: none;
  }
}

.event-input-color-text {
  width: 100%;
  padding: 20px 36px;
  border: 1px solid var(--event-border);
  border-radius: 9999px;
  background-color: transparent;
  line-height: 1;
  outline: none;
}
</style>
