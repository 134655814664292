<template>
  <b-container class="m-0 p-0 mb-2" fluid>
    <b-row class="align-items-center mb-3">
      <b-col cols="8">
        {{ $t(`teams.extra_${type}_label`, `Additional ${type}s`) }}
      </b-col>
      <b-col class="d-flex justify-content-end" cols="4">
        <button type="button" class="bttn bttn--sm bttn--orange" @click="add" :disabled="disabled">
          {{ $t("teams.add_extra_data", "Add") }}
        </button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="inputs">
        <b-form-group v-for="(v, i) in value" :key="i">
          <b-input-group>
            <VueTelInput
              v-if="type === 'phone'"
              :name="`team-extra-data-phone-${i}`"
              :value="v"
              @input="raw($event, i)"
              @validate="validate($event, i)"
              class="tw-relative tw-flex-auto tw-w-[1%] tw-min-w-0 tw-h-[50px] tw-m-0 tw-flex tw-text-[#414359] tw-font-['VitoExtended-Light',sans-serif] tw-text-[15px] tw-shadow-none !tw-rounded-l !tw-rounded-r-none tw-transition-all tw-duration-200 tw-border !tw-border-[#ced4da] focus-within:tw-border-2 focus-within:!tw-border-[#0084af] focus-within:!tw-shadow-none hover:!tw-border-[#0084af]"
              v-validate="{
                twilio_lookup: twilioLookup(i),
                phone_number: phoneObject(i),
              }"
              :inputOptions="{
                styleClasses: `tw-m-0 tw-px-[20px] tw-h-full tw-w-full tw-text-[#414359] tw-font-['VitoExtended-Light',sans-serif] tw-text-[15px]`,
              }"
              data-vv-validate-on="blur"
              valid-characters-only
              aria-describedby="phone-help"
              v-bind="phoneInputAttrs(i)"
            />
            <b-form-input
              v-else
              :value="v"
              @input="raw($event, i)"
              :type="type"
              form="team-add"
              :disabled="disabled"
            />
            <template #append>
              <b-button @click="remove(i)" variant="outlined-secondary" class="bttn--inverted-blue">
                <b-icon icon="trash-fill" />
              </b-button>
            </template>
          </b-input-group>
          <template #description>
            <small
              v-if="type === 'phone' && twilioLookupErrorMsg(i)"
              class="tw-mt-1 tw-text-red-600 tw-font-[700] tw-text-[80%]"
            >
              <b-icon-exclamation-circle-fill />
              {{ twilioLookupErrorMsg(i) }}
            </small>
          </template>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import { cloneDeep } from "@/helpers/cloneDeep";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  components: {
    VueTelInput,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
    disabled: Boolean,
  },
  data() {
    return {
      validationValue: [],
    };
  },
  computed: {
    ...mapState({
      defaultCountry: (s) => s.account.userCountry,
    }),

    autoDefaultCountry() {
      return this.defaultCountry === "N/A" || this.defaultCountry.length === 0;
    },
  },
  methods: {
    raw(e, i) {
      const po = this.phoneObject(i);
      this.$set(this.value, i, po?.valid ? po?.number : e);
      this.$emit("input", this.value);
    },
    add() {
      const copy = cloneDeep(this.value || []);
      copy.push("");
      this.$emit("input", copy);

      if (this.type === "phone") {
        this.validationValue.push(null);
      }
    },
    remove(i) {
      const copy = cloneDeep(this.value);
      copy.splice(i, 1);
      this.$emit("input", copy);

      if (this.type === "phone") {
        this.validationValue.splice(i, 1);
      }
    },

    validate(phoneObject, i) {
      this.$set(this.validationValue, i, phoneObject);
    },
    vErrorsHas(field, rule) {
      return this.vErrors.items.find((el) => el.field === field && el.rule === rule);
    },
    twilioLookupErrorMsg(i) {
      if (this.vErrorsHas(`team-extra-data-phone-${i}`, "twilio_lookup")) {
        return this.$t("template.field_error_twilio_lookup", "Invalid or incorrect phone number");
      }

      if (this.vErrorsHas(`team-extra-data-phone-${i}`, "phone_number")) {
        return this.$t("template.field_error_phone_number", "Invalid or incorrect phone number");
      }

      return "";
    },
    phoneInputAttrs(i) {
      if (!this.edit) {
        return {
          defaultCountry: this.defaultCountry,
          autoDefaultCountry: this.autoDefaultCountry,
          mode: "international",
        };
      } else {
        return {
          defaultCountry: "",
          autoDefaultCountry: false,
          mode: "international",
          ...(!this.phoneObject(i)?.valid
            ? {
                dropdownOptions: {
                  showFlags: false,
                },
              }
            : {}),
        };
      }
    },
    phoneObject(i) {
      const phoneObject = this.validationValue?.[i];
      return phoneObject;
    },
    twilioLookup(i) {
      return {
        valid: this.phoneObject(i)?.valid || false,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-flow: column;
  gap: 10px;
}
</style>
