export default ($t) => ({
  title: "ProjectorMode",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    show_level_instead_amount: {
      title: $t(
        "edit_campaign.schema.projector_mode.show_level_instead_amount",
        "Show level instead amount"
      ),
      type: "boolean",
      default: false,
      description: $t("edit_campaign.schema.projector_mode.show_level_instead_amount_description", {
        returnEmptyOnMissing: true,
      }),
    },
  },
});
