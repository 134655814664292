import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";

import Home from "./views/Home.vue";
import DashboardProtected from "@/views/dashboard/dashboard-protected.vue";
import Event from "@/views/event-page/index.vue";
import Campaigns from "@/views/campaigns/campaigns-page.vue";
import CampaignsPage from "@/views/campaigns/campaigns-page-wrapper.vue";
import CampaignEditWrapper from "@/views/campaigns/campaign-edit-wrapper.vue";
import SignIn from "@/views/SignIn.vue";
import UnifiedLogin from "@/views/UnifiedLogin.vue";
import resetPassword from "@/views/resetPassword.vue";
import SignUp from "@/views/SignUp.vue";
import Reminder from "@/views/reminder/reminder.vue";
import Donations from "@/views/donation/donation.vue";
import Teams from "@/views/teams/teams.vue";
import Tranings from "@/views/trainings/tranings-wrapper.vue";
import VideoList from "@/views/trainings/video.vue";
import ArticleList from "@/views/trainings/article.vue";
import Gateways from "@/views/gateways/gatewaysList.vue";
import Account from "@/views/account/account.vue";
import User from "@/views/account/user.vue";
import EmailConfirm from "./views/EmailConfirm.vue";
import Users from "@/views/users/users.vue";
import DonorDashboard from "@/views/donor-dashboard/donor-dashboard.vue";
import DonorProfile from "@/views/donor-dashboard/donor-profile.vue";
import DonorDonations from "@/views/donor-dashboard/donor-donations.vue";
import DonorPayment from "@/views/donor-dashboard/donor-payment.vue";
import DonorHome from "@/views/donor-dashboard/sharedComponents/donor-home.vue";
import DonorSignUp from "@/views/donor-dashboard/donor-sign-up.vue";
import DonorLogIn from "@/views/donor-dashboard/donor-log-in.vue";
import DonorTemporary from "@/views/donor-dashboard/donor-temporary.vue";
import DonorOrganization from "@/views/donor-dashboard/donor-organization.vue";
import DonorChooseAccount from "@/views/donor-dashboard/choose-account.vue";
import DonorMyTeams from "@/views/donor-dashboard/donor-my-teams.vue";
import DonorTeamInfoPage from "@/views/donor-dashboard/donor-team-info-page.vue";
import DonorSettings from "@/views/donor-dashboard/donor-settings.vue";
import DonorCancelRecurringPlan from "@/views/donor-dashboard/donor-cancel-recurring-plan.vue";
import DonorTeamsPublicReply from "@/views/donor-dashboard/donor-teams-public-reply.vue";
import DonorAuctionCampaigns from "@/views/donor-dashboard/donor-auction-campaigns.vue";
import Emails from "@/views/emails/emails.vue";
import DesignTemplates from "@/views/design-templates/design-templates.vue";
import Payouts from "@/views/payouts/payoutsPage.vue";
import Billing from "@/views/billing/billingPage.vue";
import BillingWrapper from "@/views/billing/billingPageWrapper.vue";
import VendorLayout from "@/views/vendor-dashboard/vendor-layout";
import VendorDashboard from "@/views/vendor-dashboard/dashboard/vendor-dashboard";
import VendorGateways from "@/views/vendor-dashboard/gateways/vendor-gateways";
import Packages from "@/views/vendor-dashboard/vendor-packages/packages-page";
import PackagesPage from "@/views/vendor-dashboard/vendor-packages/packages-page-wrapper.vue";
import PackagesEdit from "@/views/vendor-dashboard/vendor-packages/campaign-edit-vue/packagesEdit.vue";
import VendorUsers from "@/views/vendor-dashboard/users/users.vue";
import profileTabs from "@/views/vendor-dashboard/vendor-profiles/profileTabs.vue";
import ReportsPage from "@/views/reports/ReportsPage.vue";
import ToolsPage from "@/views/tools/ToolsPage.vue";
import MediaStoragePage from "@/views/media-storage/MediaStoragePage.vue";
import VideoTutorialsPage from "@/views/video-tutorials/page.vue";
import VideoFr from "@/views/video-tutorials/lang-content.vue";
import RDSPage from "@/views/recurring-donations-summary/rds-page.vue";
import OrgTicketsPage from "@/views/org-tickets/OrgTicketsPage.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active-link",
  routes: [
    {
      path: "/",
      component: UnifiedLogin,
      name: "UnifiedLogin",
      meta: { unifiedlogin: true },
    },
    {
      path: "/choose-account",
      name: "DonorChooseAccount",
      component: DonorChooseAccount,
    },
    {
      path: "/emailverify/:tokenVerify",
      component: EmailConfirm,
    },
    {
      path: "/login",
      component: SignIn,
    },
    {
      path: "/register",
      component: SignUp,
      name: "dashboard-login",
    },
    {
      path: "/login/:token",
      component: SignIn,
    },
    {
      path: "/resetpassword",
      component: resetPassword,
    },
    {
      path: "",
      component: Home,
      meta: { requiresLogin: true },
      children: [
        {
          path: "/dashboard",
          component: DashboardProtected,
          meta: { requiresLogin: true },
        },
        {
          path: "campaign",
          component: CampaignsPage,
          children: [
            {
              path: "",
              component: Campaigns,
              meta: { requiresLogin: true },
            },
            {
              path: ":id",
              component: CampaignEditWrapper,
              meta: { requiresLogin: true },
              beforeEnter(to, from, next) {
                store.commit("setSelectedCampaign", to.params.id);
                next();
              },
            },
          ],
          meta: { requiresLogin: true },
        },
        {
          path: "/event-donations",
          component: Event,
          meta: { requiresLogin: true, eventDonations: true },
        },
        {
          path: "/event-controls",
          component: Event,
          meta: { requiresLogin: true },
        },
        {
          path: "/leads/",
          component: Reminder,
          meta: { requiresLogin: true },
        },
        {
          path: "/emails/",
          component: Emails,
          meta: { requiresLogin: true },
        },
        {
          path: "/donations/",
          component: Donations,
          meta: { requiresLogin: true },
        },
        {
          path: "/teams/",
          component: Teams,
          meta: { requiresLogin: true },
        },
        {
          path: "/training",
          component: Tranings,
          meta: { requiresLogin: true },
          children: [
            {
              path: "video",
              name: "video",
              component: VideoList,
            },
            {
              path: "video-fr",
              name: "video-fr",
              component: VideoFr,
            },
            {
              path: "article",
              name: "article",
              component: ArticleList,
            },
          ],
        },
        {
          path: "/gateways",
          component: Gateways,
          meta: { requiresLogin: true },
        },
        {
          path: "/billing",
          component: BillingWrapper,
          meta: { requiresLogin: true },
          children: [
            {
              path: "",
              component: Billing,
              meta: { requiresLogin: true },
            },
            {
              path: ":id",
              component: Payouts,
              meta: { requiresLogin: true },
            },
          ],
        },
        {
          path: "/account",
          component: Account,
          meta: { requiresLogin: true },
        },
        {
          path: "/user",
          component: User,
          meta: { requiresLogin: true },
        },
        {
          path: "/reports/recurring-donations-summary",
          component: RDSPage,
          meta: { requiresLogin: true },
          beforeEnter: (to, from, next) => {
            store.dispatch("updateOrgSettingsAccess").then(() => {
              if (store.state.account.canSeeReports) {
                next();
              } else {
                next({ path: "/dashboard" });
              }
            });
          },
        },
        {
          path: "/reports/:name",
          component: ReportsPage,
          meta: { requiresLogin: true },
          beforeEnter: (to, from, next) => {
            store.dispatch("updateOrgSettingsAccess").then(() => {
              if (store.state.account.canSeeReports) {
                next();
              } else {
                next({ path: "/dashboard" });
              }
            });
          },
        },
        {
          path: "/advanced/:page",
          name: "advanced",
          component: ToolsPage,
          meta: { requiresLogin: true },
        },
        {
          path: "/tickets",
          name: "tickets",
          component: OrgTicketsPage,
          meta: { requiresLogin: true },
          beforeEnter: (to, from, next) => {
            store.dispatch("updateOrgSettingsAccess").then(() => {
              if (store.state.account.canSeeTickets) {
                next();
              } else {
                next({ path: "/dashboard" });
              }
            });
          },
        },
        {
          path: "/media-storage",
          name: "media-storage",
          component: MediaStoragePage,
          meta: { requiresLogin: true },
        },
        {
          path: "/design-templates",
          component: DesignTemplates,
          meta: { requiresLogin: true },
          beforeEnter: (to, from, next) => {
            if (
              store.state.account.designTemplatesEnabled &&
              store.state.account.access.CanSeeDesignTemplates
            ) {
              next();
            } else {
              next({
                path: "/dashboard",
              });
            }
          },
        },
        {
          path: "/users",
          component: Users,
          meta: { requiresLogin: true },
        },
        {
          path: "/video-tutorials",
          component: VideoTutorialsPage,
          meta: { requiresLogin: true },
          beforeEnter: (to, from, next) => {
            store.dispatch("updateOrgSettingsAccess").then(() => {
              if (store.state.account.canSeeTrainingVideos) {
                next();
              } else {
                next({ path: "/dashboard" });
              }
            });
          },
        },
      ],
    },
    {
      path: "",
      component: DonorHome,
      children: [
        {
          path: "donor-my-teams",
          component: DonorMyTeams,
          meta: { requiresLogin: true, donorDashboard: true, donorMyTeams: true },
        },
        {
          path: "donor-my-teams/:id",
          component: DonorTeamInfoPage,
          meta: { requiresLogin: true, donorDashboard: true },
        },
        {
          path: "donor-dashboard",
          component: DonorDashboard,
          meta: { requiresLogin: true, donorDashboard: true },
        },
        {
          path: "donor-profile",
          component: DonorProfile,
          meta: { requiresLogin: true, donorDashboard: true },
        },
        {
          path: "donor-donations",
          component: DonorDonations,
          meta: { requiresLogin: true, donorDashboard: true },
        },
        {
          path: "donor-payment",
          component: DonorPayment,
          meta: { requiresLogin: true, donorDashboard: true },
        },
        {
          path: "donor-organization",
          component: DonorOrganization,
          meta: { requiresLogin: true, donorDashboard: true },
        },
        {
          path: "donor-settings",
          component: DonorSettings,
          meta: { requiresLogin: true, donorDashboard: true },
        },
        {
          path: "donor-auction-campaigns",
          component: DonorAuctionCampaigns,
          meta: { requiresLogin: true, donorDashboard: true },
        },
      ],
    },
    {
      path: "/donor-cancel-recurring-plan/:jwtAuthCode",
      component: DonorCancelRecurringPlan,
      meta: { requiresLogin: false, donorDashboard: true },
    },
    {
      path: "/donor-teams-public-reply/:jwtAuthCode",
      component: DonorTeamsPublicReply,
      meta: { requiresLogin: false, donorDashboard: true },
    },
    {
      path: "",
      component: VendorLayout,
      children: [
        {
          path: "vendor-dashboard",
          component: VendorDashboard,
        },
        {
          path: "vendor-users",
          component: VendorUsers,
        },
        {
          path: "profile-tabs",
          component: profileTabs,
        },
        {
          path: "packages-page",
          component: PackagesPage,
          children: [
            {
              path: "",
              component: Packages,
              meta: { requiresLogin: true },
            },
            {
              path: ":id",
              component: PackagesEdit,
              meta: { requiresLogin: true },
            },
          ],
        },
        {
          path: "vendor-gateways",
          component: VendorGateways,
        },
      ],
    },
    {
      path: "/donor-temporary",
      component: DonorTemporary,
    },
    {
      path: "/chooseaccount",
      component: DonorChooseAccount,
    },
    {
      path: "/donor-sign-up",
      component: DonorSignUp,
      name: "donors-sign-up",
      meta: { donorDashboard: true },
    },
    {
      path: "/donor-log-in",
      component: DonorLogIn,
      name: "donors-login",
      meta: { donorDashboard: true },
    },
    {
      path: "/donor-log-in/:token",
      component: DonorLogIn,
      name: "donors-login-token",
      meta: { donorDashboard: true },
    },
    {
      path: "**",
      redirect: "/dashboard",
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.donorMyTeams) &&
    !store.getters.isLoggedIn &&
    !(to.params?.token || to.query?.token)
  ) {
    next("/donor-log-in?redirect=" + to.fullPath);
  } else if (
    to.matched.some((record) => record.meta.requiresLogin) &&
    !store.getters.isLoggedIn &&
    !(to.params?.token || to.query?.token)
  ) {
    if (from.matched.some((record) => record.path.match("/donor-*"))) {
      next("/donor-log-in");
    } else {
      next("/login"); //chooseaccount
    }
  } else if (
    to.matched.some((record) => record.path.match("/donor-log-in")) &&
    store.getters.isLoggedIn &&
    !(to.params?.token || to.query?.token)
  ) {
    next("/donor-dashboard");
  } else {
    next();
  }
});

export default router;
