<template>
  <div class="form-section--settings__body__item" :class="{ 'is-visible': isShow }" v-show="isShow">
    <slot />
  </div>
</template>

<script>
export default {
  name: "event-page-open-settings-item",

  props: {
    value: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    isShow() {
      return this.text.toLowerCase().includes(this.value.toLowerCase());
    },
  },
};
</script>
<style lang="scss" scoped>
.form-section--settings {
  &__body__item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 13px 34px 13px 25px;
    border: 1px solid var(--event-border);
    border-top: none;
    text-align: left;
    --padding-left: 61px;
    @media (max-width: 768px) {
      padding: 13px 34px;
    }
  }

  &__body__item:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
</style>
