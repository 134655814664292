<template>
  <div
    v-if="clientId"
    id="google-signin-button"
    class="tw-w-[250px] tw-mx-auto tw-mb-[50px]"
    ref="googleButton"
  ></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "GoogleLogin",

  computed: {
    ...mapState({
      clientId: (state) => state.account.googleClientId,
    }),
  },

  watch: {
    clientId(newValue) {
      if (newValue) {
        this.initGoogleAuth();
      }
    },
  },

  methods: {
    loginUser(credential) {
      this.$store
        .dispatch("loginUser", [credential, { noRouting: true, googleLogin: true }])
        .then((user) => {
          this.$emit("onLoginSuccess", user);
        });
    },

    initGoogleAuth() {
      const googleScript = document.createElement("script");
      googleScript.src = "https://accounts.google.com/gsi/client";
      googleScript.async = true;
      googleScript.defer = true;
      document.head.appendChild(googleScript);

      googleScript.onload = () => {
        window.google.accounts.id.initialize({
          client_id: this.clientId,
          callback: this.handleCredentialResponse,
          auto_select: false,
          cancel_on_tap_outside: true,
        });

        window.google.accounts.id.renderButton(this.$refs.googleButton, {
          theme: "outline",
          size: "large",
          text: "signin_with",
          shape: "rectangular",
          logo_alignment: "center",
          width: 250,
        });
      };
    },

    handleCredentialResponse(response) {
      if (response.credential) {
        // TODO: remove this after google registration is done
        // const base64Url = response.credential.split(".")[1];
        // const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        // const jsonPayload = decodeURIComponent(
        //   atob(base64)
        //     .split("")
        //     .map(function (c) {
        //       return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        //     })
        //     .join("")
        // );

        // const userData = JSON.parse(jsonPayload);

        // this.user.name = userData.name;
        // this.user.email = userData.email;
        // this.user.picture = userData.picture;
        this.loginUser(response.credential);
      }
    },
  },
  mounted() {
    if (this.clientId) {
      this.initGoogleAuth();
    }
  },
};
</script>
