<template>
  <div class="app-select">
    <Multiselect
      v-model="selected"
      v-bind="$attrs"
      v-on="selectListeners"
      :label="labelValue"
      :track-by="trackByValue"
      :options="options"
    >
      <slot name="singleLabel" slot="singleLabel"></slot>
      <slot name="caret" slot="caret"></slot>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "AppSelect",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    trackBy: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    singleModel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      labelValue: (() => {
        if (this.label) {
          return this.label;
        } else if (
          !this.label &&
          typeof this.$attrs.options[0] !== "string" &&
          typeof this.$attrs.options[0] !== "number"
        ) {
          return "label";
        }

        return "";
      })(),
      trackByValue: (() => {
        if (this.trackBy) {
          return this.trackBy;
        } else if (
          !this.trackBy &&
          typeof this.$attrs.options[0] !== "string" &&
          typeof this.$attrs.options[0] !== "number"
        ) {
          return "label";
        }

        return "";
      })(),
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    selected: {
      get() {
        if (this.singleModel) {
          return this.options.find((option) => option[this.trackBy] === this.value) || null;
        } else {
          return this.value;
        }
      },
      set(newValue) {
        if (this.singleModel) {
          this.$emit("input", newValue[this.trackBy]);
        } else {
          this.$emit("input", newValue);
        }
      },
    },
    selectListeners() {
      // eslint-disable-next-line no-unused-vars
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-select {
  width: 100%;
  display: inline-block;
  position: relative;
}

::v-deep .multiselect {
  min-height: 48px;
  &__select {
    height: 46px;
  }
  &__tags {
    min-height: 48px;
    border-radius: 24px;
    border-color: $bb-50;
    padding-left: 20px;
  }
  &__single {
    height: 30px;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__input {
    min-height: 30px;
    line-height: 30px;
  }
  &__option {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

#app.he {
  .app-select {
    ::v-deep .multiselect {
      text-align: right;
      &__single {
        text-align: right;
      }
    }
  }
}
</style>
