<template>
  <div class="tw-col-span-1">
    <div class="profile-aside panel" :class="{ 'tw-shadow-none': hideShadows }" v-if="!isLoading">
      <div class="main-host">
        <a href="https://charidy.com">
          <cdn-logo-by-lang />
        </a>
      </div>
      <div class="profile-info">
        <div class="avatar-wrap">
          <div class="avatar" :style="avatarStyle">
            <div class="inner">
              <b-button
                v-if="!isAvatar"
                @click="openModal"
                ref="btnShow"
                v-b-modal.editPhoto
                class="add-photo"
              >
                <i></i>
                <span>{{ $t("donor_dashboard.add_photo_action", "Add Photo") }}</span>
              </b-button>
            </div>

            <b-button
              v-if="isAvatar"
              @click="openModal"
              ref="btnShow"
              v-b-modal.editPhoto
              class="update-btn"
              >{{ $t("donor_dashboard.edit_photo_action", "Edit photo") }}</b-button
            >
          </div>
        </div>
        <h3>
          <span>{{ donorAccountData.first_name }}</span>
          <span>{{ donorAccountData.last_name }}</span>
        </h3>
      </div>
      <div class="tw-mb-[20px] tw-mx-[5%]">
        <LanguageSelect size="lg" />
      </div>
      <nav>
        <ul class="profile-nav">
          <router-link to="/donor-dashboard" custom v-slot="{ navigate, isActive, isExactActive }">
            <li
              class="nav-item nav-link"
              :class="{ 'router-link-exact-active': isExactActive, 'active-link': isActive }"
              @click="onNavItemClick($event, navigate)"
            >
              <i>
                <img
                  src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/dashboard-icon.png"
                  alt
                />
              </i>
              <span>{{ $t("donor_dashboard.dashboard_page", "Dashboard") }}</span>
            </li>
          </router-link>
          <router-link to="/donor-profile" custom v-slot="{ navigate, isActive, isExactActive }">
            <li
              class="nav-item nav-link"
              :class="{ 'router-link-exact-active': isExactActive, 'active-link': isActive }"
              @click="onNavItemClick($event, navigate)"
            >
              <i>
                <img
                  src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/profile-icon.png"
                  alt
                />
              </i>
              <span>{{ $t("donor_dashboard.profile_page", "Profile") }}</span>
            </li>
          </router-link>
          <router-link to="/donor-donations" custom v-slot="{ navigate, isActive, isExactActive }">
            <li
              class="nav-item nav-link"
              :class="{ 'router-link-exact-active': isExactActive, 'active-link': isActive }"
              @click="onNavItemClick($event, navigate)"
            >
              <i>
                <img
                  src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/donations-icon.png"
                  alt
                />
              </i>
              <span>{{ $t("donor_dashboard.my_donations_page", "My Donations") }}</span>
            </li>
          </router-link>
          <router-link to="/donor-payment" custom v-slot="{ navigate, isActive, isExactActive }">
            <li
              class="nav-item nav-link"
              :class="{ 'router-link-exact-active': isExactActive, 'active-link': isActive }"
              @click="onNavItemClick($event, navigate)"
            >
              <i>
                <img
                  src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/payment-methods-icon.png"
                  alt
                />
              </i>
              <span>{{ $t("donor_dashboard.payment_methods_page", "Payment Methods") }}</span>
            </li>
          </router-link>
          <router-link
            to="/donor-organization"
            custom
            v-slot="{ navigate, isActive, isExactActive }"
          >
            <li
              class="nav-item nav-link"
              :class="{ 'router-link-exact-active': isExactActive, 'active-link': isActive }"
              @click="onNavItemClick($event, navigate)"
            >
              <i>
                <img
                  src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/noun-organizational-chart%20(1).png"
                  alt
                />
              </i>
              <span>{{ $t("donor_dashboard.organization_role_page", "Organization Role") }}</span>
            </li>
          </router-link>
          <router-link to="/donor-my-teams" custom v-slot="{ navigate, isActive, isExactActive }">
            <li
              class="nav-item nav-link"
              :class="{ 'router-link-exact-active': isExactActive, 'active-link': isActive }"
              @click="onNavItemClick($event, navigate)"
            >
              <i>
                <icon-my-teams />
              </i>
              <span>{{ $t("donor_dashboard.my_teams_page", "My Teams") }}</span>
            </li>
          </router-link>
          <router-link
            to="/donor-auction-campaigns"
            custom
            v-slot="{ navigate, isActive, isExactActive }"
          >
            <li
              class="nav-item nav-link"
              :class="{ 'router-link-exact-active': isExactActive, 'active-link': isActive }"
              @click="onNavItemClick($event, navigate)"
            >
              <i>
                <IconAuctionCampaigns />
              </i>
              <span>{{ $t("donor_dashboard.auction_campaigns_page", "Auction Campaigns") }}</span>
            </li>
          </router-link>
          <li class="dash" @click="$emit('click')">
            <a class="nav-link" role="button" @click.prevent="logout()">
              <i>
                <IconSignOut />
              </i>
              <span>{{ $t("template.signout", "Sign Out") }}</span>
            </a>
          </li>
          <router-link to="/donor-settings" custom v-slot="{ navigate, isActive, isExactActive }">
            <li
              class="nav-item nav-link"
              :class="{ 'router-link-exact-active': isExactActive, 'active-link': isActive }"
              @click="onNavItemClick($event, navigate)"
            >
              <i>
                <b-icon icon="gear-fill" style="color: #79b2c6" />
              </i>
              <span>{{ $t("donor_dashboard.settings_page", "Settings") }}</span>
            </li>
          </router-link>
          <router-link to="/dashboard" custom v-slot="{ navigate, isActive, isExactActive }">
            <li
              class="nav-item nav-link"
              :class="{ 'router-link-exact-active': isExactActive, 'active-link': isActive }"
              @click="onNavItemClick($event, navigate)"
            >
              <i>
                <IconOrgDashboard />
              </i>
              <span>{{
                $t("donor_dashboard.organization_dashboard_page", "Organization dashboard")
              }}</span>
            </li>
          </router-link>
        </ul>
      </nav>
      <edit-photo ref="editPhoto" :donorAccount="donorAccountData">
        <div class="avatar-wrap" slot="avatar">
          <div class="avatar" :style="avatarStyle">
            <div class="inner">
              <div v-if="!isAvatar" class="default-photo"></div>
            </div>
          </div>
        </div>
      </edit-photo>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import iconMyTeams from "./iconMyTeams.vue";
import editPhoto from "../modals/edit-photo-popup.vue";
import CdnLogoByLang from "@/common-components/cdn-v2/cdn-logo-by-lang.vue";
import IconAuctionCampaigns from "./iconAuctionCampaigns.vue";
import LanguageSelect from "../common-components/language-select.vue";
import IconOrgDashboard from "./iconOrgDashboard.vue";
import IconSignOut from "./iconSignOut.vue";

export default {
  props: {
    hideShadows: Boolean,
    loading: Boolean,
  },
  components: {
    "edit-photo": editPhoto,
    iconMyTeams,
    CdnLogoByLang,
    IconAuctionCampaigns,
    LanguageSelect,
    IconOrgDashboard,
    IconSignOut,
  },
  data() {
    return {
      avatarStyle: {},
    };
  },
  methods: {
    openModal: function () {
      this.$refs.editPhoto.openModal();
    },

    logout: function () {
      this.$store.dispatch("unsetUser");
      this.$router.push({ path: "/" });
    },

    onNavItemClick(e, navigate) {
      navigate(e);
      this.$emit("click");
    },
  },
  watch: {
    donorAccountData: {
      handler(val) {
        if (val && !val?.avatar?.includes("data:")) {
          this.avatarStyle = {
            backgroundImage: "url(" + val.avatar + ")",
            backgroundSize: "cover",
          };
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState({
      donorAccountData: (state) => {
        return state.donorAccount.account.attributes;
      },
      isLoading: (state) => {
        return state.donorAccount.loadingAccount;
      },
    }),
    isAvatar() {
      const avatar = this.donorAccountData.avatar;
      return avatar && !avatar?.includes("data:") ? true : false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";

.main-host {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 10px 22px -18px rgba(0, 0, 0, 0.75);
  background-color: #ffffff;
  position: absolute;
  top: 0px;
  left: 0px;
  > a {
    display: inline-flex;
    svg {
      display: block;
      width: auto;
      height: 60px;
    }
  }
}

li {
  height: 60px;
}
.dash {
  &:before {
    position: relative !important;
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #88bacc;
    position: absolute;
    bottom: 0px;
    left: 0;
  }
  a.nav-link {
    cursor: pointer;
  }
}

.profile-aside {
  padding-top: 90px;
  padding-bottom: 30px;
  position: relative;
}
.profile-info {
  padding: 31px 5% 20px;
  text-align: center;
  h3 {
    font: 30px/1.2 "VitoExtended-Bold";
    margin-bottom: 0;
    width: 100%;
    overflow: hidden;
    span {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.profile-nav {
  margin-bottom: 1rem;
  li {
    line-height: 0;
    display: block;
  }
  .nav-link {
    color: $fd;
    font-size: 18px;
    padding: 17px 10% 20px;
    line-height: 1.2;
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    gap: 15px;
    cursor: pointer;
    align-items: center;
    ::v-deep i {
      opacity: 0.6;
      display: inline-block;
      vertical-align: middle;
      width: 27px;
      text-align: center;
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
    &.router-link-exact-active,
    &:hover {
      color: $bb-100;
      font-family: "VitoExtended-Bold";
      background: #f7fbff;
      line-height: 1.15;
      i {
        opacity: 1;
      }
    }
  }
}
.avatar-wrap {
  width: 160px;
  height: 160px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 80px;
}
.avatar {
  width: 160px;
  height: 160px;
  display: table;
  text-align: center;
  margin-bottom: 2px;
  position: relative;
  background: url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/default-user-picture.png")
    center no-repeat;
  background-size: 100%;
  .inner {
    display: table-cell;
    width: 160px;
    height: 160px;
    vertical-align: middle;
    img {
      max-width: 160px;
      max-height: 160px;
    }
  }
  .update-btn {
    color: $bb-100;
    font-size: 16px !important;
    border: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffffffbf !important;
    text-align: center;
    cursor: pointer;
    font: 14px/1.2 "VitoExtended-Bold";
    padding: 12px 5px 17px !important;
    outline: none;
    display: none;
    &:hover,
    &:focus {
      background: #ffffffbf;
      color: $bb-100;
    }
  }
  &:hover {
    .update-btn {
      display: block;
    }
  }
  .add-photo,
  .default-photo {
    background: #f5f9fe;
    border: 1px dashed #c5ccd4;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 35px 20px;
    border-radius: 50%;
    font-family: "VitoExtended-Light";
    box-sizing: border-box;
    i {
      display: inline-block;
      width: 36px;
      height: 36px;
      border: 1px solid #10759833;
      margin-bottom: 23px;
      border-radius: 18px;
      &:before {
        content: "\002B";
        display: block;
        width: 34px;
        height: 34px;
        font-style: normal;
        font-size: 26px;
        color: #107598;
        line-height: 1.3;
      }
    }
    span {
      display: block;
      text-decoration: underline;
      color: #107598;
    }
    &:focus {
      outline: none;
      background: #fbfdff !important;
      border: 1px dashed #dee6ef !important;
    }
  }
}
</style>
