<template>
  <div class="form-section form-section--settings" :class="{ 'is-hidden': !hasVisibleChildren }">
    <button class="form-section__header form-section--settings__header" @click="$emit('toggle')">
      <icon-arrow-right class="form-section--settings__header__btn" />
      <icon :icon-name="data?.icon" class="form-section--settings__header__icon" />
      {{ data?.title }}
    </button>
    <div class="form-section--settings__body">
      <slot />
    </div>
  </div>
</template>

<script>
import iconArrowRight from "@/views/event-page/icons/arrow-right.vue";
import icon from "@/views/event-page/icons";

export default {
  name: "event-page-open-settings-wrapper",

  components: {
    iconArrowRight,
    icon,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      hasVisibleChildren: false,
    };
  },
  mounted() {
    this.checkVisibleChildren();
  },
  updated() {
    this.checkVisibleChildren();
  },
  methods: {
    checkVisibleChildren() {
      this.hasVisibleChildren = this.$el.querySelector(".is-visible") !== null;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-section--settings {
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 14px 30px;
    border: 1px solid var(--event-secondary);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    text-align: left;
    font-weight: 700;
    background-color: var(--event-secondary);
    color: var(--event-soft-dark);

    &__btn {
      flex-shrink: 0;
      transform: rotate(180deg);
    }
    &__icon {
      flex-shrink: 0;
      margin-inline-start: 39px;
      margin-inline-end: 16px;
      color: var(--event-primary);
    }
  }
}

.is-hidden {
  display: none;
}
</style>
