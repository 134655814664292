<template>
  <button class="form-section__item" @click="$emit('toggle')">
    <div class="form-section__item__icon">
      <icon :icon-name="data?.icon" />
    </div>
    <div class="form-section__item__name">{{ data?.title }}</div>
    <div class="form-section__item__descr">
      {{ data?.description || "&nbsp;" }}
      <icon-arrow-right class="form-section__item__btn" />
    </div>
  </button>
</template>

<script>
import iconArrowRight from "@/views/event-page/icons/arrow-right.vue";
import icon from "@/views/event-page/icons";

export default {
  name: "event-page-settings-list-item",

  components: {
    iconArrowRight,
    icon,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-section {
  &__item {
    --p-x: 20px;
    --svg-width: 11px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 13px var(--p-x);
    border: 1px solid var(--event-border);
    border-top: none;
    text-align: left;
    --icon-width: 66px;
    &__icon {
      flex-shrink: 0;
      width: var(--icon-width);
      color: var(--event-primary);
    }
    &__name {
      width: calc(35% - var(--icon-width) / 2);
      padding-right: 24px;
      @media (max-width: 768px) {
        flex-basis: 35%;
        width: auto;
      }
    }
    &__descr {
      width: calc(65% - var(--icon-width) / 2);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-inline-end: calc(var(--svg-width) + var(--p-x));
      gap: 24px;
      color: var(--event-soft-dark);
      @media (max-width: 768px) {
        flex-grow: 1;
        flex-basis: 100%;
        width: auto;
        margin-top: 16px;
        font-size: 18px;
      }
    }
    &__btn {
      position: absolute;
      flex-shrink: 0;
      right: var(--p-x);
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__item:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
</style>
