<template>
  <button
    class="event-btn"
    :class="{ [`${type}-btn`]: true, [`${type}-btn__loading`]: loading }"
    v-on="$listeners"
  >
    <slot /><loader v-if="loading" />
  </button>
</template>

<script>
import Loader from "@/views/event-page/ui/loader.vue";

export default {
  name: "primary-btn",

  components: {
    Loader,
  },
  props: {
    type: {
      type: String,
      default: "primary",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.event-btn {
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  height: 57px;
  padding: 0 30px;
  border: 1px solid transparent;
  border-radius: 9999px;
  font-size: 23px;
}

.primary-btn {
  background-color: var(--event-primary);
  border-color: var(--event-primary);
  color: var(--event-white);

  &__loading {
    color: var(--event-primary);
    pointer-events: none;
  }
}

.secondary-btn {
  background-color: var(--event-white);
  border-color: var(--event-secondary-b);
  color: var(--event-secondary-c);

  &__loading {
    color: var(--event-white);
    pointer-events: none;
  }
}

.danger-btn {
  background-color: var(--event-danger);
  border-color: var(--event-danger);
  color: var(--event-white);

  &__loading {
    color: var(--event-danger);
    pointer-events: none;
  }
}
</style>
