<template>
  <div class="input-file">
    <label :for="file.id" class="input-file__label" :class="{ 'input-file__loading': loading }">
      {{ file.btnText }}
      <loader v-if="loading" />
    </label>
    <input
      class="input-file__input"
      type="file"
      accept="image/*"
      :id="file.id"
      @change="$emit('change', $event)"
    />
  </div>
</template>

<script>
import Loader from "@/views/event-page/ui/loader.vue";

export default {
  name: "input-file",

  components: {
    Loader,
  },

  props: {
    file: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.input-file {
  position: relative;
  display: flex;
  align-self: flex-start;
  justify-self: center;

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    border: 0;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
  }

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding: 12px 32px;
    border-radius: 0;
    background-color: var(--event-primary);
    color: var(--event-white);
    font-size: 23px;
    cursor: pointer;

    &.input-file__loading {
      color: var(--event-primary);
      pointer-events: none;
    }
  }
}
</style>
