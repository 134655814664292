<template>
  <div class="key-value-input">
    <div v-if="value && Object.keys(value).length > 0" class="mb-3">
      <b-table small :items="tableItems" :fields="fields">
        <template #cell(actions)="{ index }">
          <b-icon-trash-fill
            variant="danger"
            role="button"
            font-scale="1.2"
            @click="removeItem(index)"
          />
        </template>
      </b-table>
    </div>

    <div class="d-flex mb-2">
      <b-form-group class="mr-2 flex-grow-1 mb-0">
        <b-form-input
          v-model="currentKey"
          :placeholder="keyPlaceholder || 'Ключ'"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-form-group class="mr-2 flex-grow-1 mb-0">
        <b-form-input
          v-model="currentValue"
          :placeholder="valuePlaceholder || 'Значение'"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-button
        class="custom-outline-button"
        size="md"
        variant="outline-primary"
        @click="addItem"
        :disabled="!canAdd"
      >
        <b-icon-plus-circle></b-icon-plus-circle>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DynamicKeyValueObject",

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    keyPlaceholder: {
      type: String,
      default: "",
    },
    valuePlaceholder: {
      type: String,
      default: "",
    },
    field: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      currentKey: "",
      currentValue: "",
      fields: [
        { key: "key", label: "Key" },
        { key: "value", label: "Value" },
        { key: "actions", label: "Actions" },
      ],
    };
  },

  computed: {
    canAdd() {
      return this.currentKey.trim() !== "";
    },

    tableItems() {
      return Object.entries(this.value || {}).map(([key, value]) => ({
        key,
        value,
      }));
    },
  },

  methods: {
    addItem() {
      if (!this.canAdd) return;

      const newValue = { ...this.value };
      newValue[this.currentKey] = this.currentValue;

      this.$emit("input", newValue);

      this.currentKey = "";
      this.currentValue = "";
    },

    removeItem(index) {
      const key = Object.keys(this.value)[index];
      const newValue = { ...this.value };
      delete newValue[key];

      this.$emit("input", newValue);
    },
  },
};
</script>

<style scoped>
.custom-outline-button {
  height: 50px;
  border-color: #107598 !important;
  color: #107598 !important;
}
.custom-outline-button:hover {
  background-color: #2883a2 !important;
  color: #fff !important;
}
</style>
