<template>
  <div>
    <b-modal id="exportDonation" size="lg" @hidden="clearData">
      <div slot="modal-header">
        <h2>{{ $t("dashboard.export_modal_title") }}</h2>
        <div class="modal-header__hide" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button
          :class="
            !exportLink.file && success ? 'bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--blue'
          "
          @click.prevent="hideModal"
        >
          {{ $t("dashboard.hide_modal") }}
        </button>
        <button
          @click="exportList"
          :class="isLoading ? 'disabled bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--orange'"
          type="button"
          v-if="!success"
          :disabled="isLoading || !isDatesCorrect || !isReportCorrect"
        >
          {{ $t("dashboard.export_export_in_modal") }}
        </button>
        <a
          :href="exportLink.file"
          v-if="exportLink.file && success"
          class="bttn bttn--lg bttn--orange"
        >
          {{ $t("dashboard.export_dowload_export_file") }}
        </a>
      </div>

      <div class="filter-block-wrapper" v-if="showFilters">
        <h4 class="modal-subtitle mb-3">
          {{ $t("dashboard.export_modal_filter_label") }}
        </h4>
        <div class="filter-block dashboard-donation__export">
          <div class="filter-block-item">
            <h6 class="block-item-label">
              {{ $t("dashboard.export_modal_filter_donation_type_label") }}
            </h6>
            <div class="select">
              <div class="multiselect__select"></div>
              <select class="select__select" v-model="filters.recurringFilter">
                <option value="-1">
                  {{ $t("dashboard.recurring_filter_recurring_and_onetime") }}
                </option>
                <option value="1">{{ $t("dashboard.recurring_filter_recurring_only") }}</option>
                <option value="0">{{ $t("dashboard.recurring_filter_onetime_only") }}</option>
              </select>
            </div>
          </div>

          <div class="filter-block-item">
            <h6 class="block-item-label">
              {{ $t("dashboard.export_modal_filter_gateway_label") }}
            </h6>
            <div class="select">
              <div class="multiselect__select"></div>
              <select class="select__select" v-model="filters.gatewayFilter">
                <option value>{{ $t("dashboard.select_filter_please_select_gateway") }}</option>
                <option
                  v-for="gateway in gateways"
                  :key="gateway.id"
                  :value="gateway.attributes.value"
                >
                  {{ $t("dashboard.select_filter_" + gateway.attributes.text) }}
                </option>
              </select>
            </div>
          </div>
          <div class="filter-block-item filter-block-item--100">
            <h6 class="block-item-label">
              {{ $t("dashboard.export_modal_filter_team_label") }}
            </h6>
            <div class="search">
              <multiselect
                v-model="selectedTeam"
                :options="teamsList"
                :custom-label="customLabel"
                track-by="text"
                :placeholder="$t('dashboard.select_teams')"
                :multiple="false"
                :show-labels="true"
                :select-label="$t('dashboard.export_team_select_label', 'Press enter to select')"
                :deselect-label="
                  $t('dashboard.export_team_deselect_label', 'Press enter to remove')
                "
                :selected-label="$t('dashboard.export_team_selected_label', 'Selected')"
              ></multiselect>
            </div>
          </div>
          <div v-if="category === 'givingday'" class="filter-block-item">
            <div class="form-checkbox form-checkbox--orange">
              <input
                id="all-gd-subcamp"
                v-model="allGdSubCampaigns"
                type="checkbox"
                class="form-checkbox__input"
              />
              <label for="all-gd-subcamp" class="form-checkbox__label">
                {{ $t("dashboard.export_all_givingday_subcampaigns") }}
              </label>
            </div>
          </div>
          <div v-if="isHasLinkedCampaigns" class="filter-block-item">
            <div class="form-checkbox form-checkbox--orange">
              <input
                id="all-linked-campaigns"
                v-model="allLinkedCampaigns"
                type="checkbox"
                class="form-checkbox__input"
              />
              <label for="all-linked-campaigns" class="form-checkbox__label">
                {{ $t("dashboard.export_all_linked_campaigns") }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-filters" v-if="showFilters">
        <div class="bottom-filters__actions">
          <div class="bottom-filters__actions-title">
            <h3 class="section-title">
              {{ $t("dashboard.export_modal_date_range_label") }}
            </h3>
            <p class="section-subtitle">
              {{ $t("dashboard.export_modal_date_range_sub_label") }}
              <span class="accent">{{ accTimezone }}</span>
            </p>
          </div>
          <b-col cols="12">
            <!-- time intervals select -->
            <div class="action-row">
              <div class="form-radio">
                <input
                  v-model="selectedDate"
                  id="today"
                  type="radio"
                  class="form-radio__input"
                  value="today"
                />
                <label for="today" class="form-radio__label">
                  {{ $t("dashboard.export_modal_date_range_today_label") }}
                </label>
              </div>
              <div class="legend">
                {{ showDate("today") }}
              </div>
            </div>
            <div class="action-row">
              <div class="form-radio">
                <input
                  v-model="selectedDate"
                  id="c-month"
                  type="radio"
                  class="form-radio__input"
                  value="month"
                />
                <label for="c-month" class="form-radio__label">
                  {{ $t("dashboard.export_modal_date_range_current_month_label") }}
                </label>
              </div>
              <div class="legend">
                {{ showDate("month") }}
              </div>
            </div>

            <div class="action-row">
              <div class="form-radio">
                <input
                  v-model="selectedDate"
                  id="-week"
                  type="radio"
                  class="form-radio__input"
                  value="-week"
                />
                <label for="-week" class="form-radio__label">
                  {{ $t("dashboard.export_modal_date_range_prev_7_days_label") }}
                </label>
              </div>
              <div class="legend">
                {{ showDate("-week") }}
              </div>
            </div>

            <div class="action-row">
              <div class="form-radio">
                <input
                  v-model="selectedDate"
                  id="-month"
                  type="radio"
                  class="form-radio__input"
                  value="-month"
                />
                <label for="-month" class="form-radio__label">
                  {{ $t("dashboard.export_modal_date_range_prev_month_label") }}
                </label>
              </div>
              <div class="legend">
                {{ showDate("-month") }}
              </div>
            </div>

            <div class="action-row">
              <div class="form-radio">
                <input
                  v-model="selectedDate"
                  id="all"
                  type="radio"
                  class="form-radio__input"
                  value="all"
                />
                <label for="all" class="form-radio__label">
                  {{ $t("dashboard.export_modal_date_range_all_label") }}
                </label>
              </div>
            </div>

            <div class="action-row-custom">
              <div class="form-radio">
                <input
                  v-model="selectedDate"
                  id="custom"
                  type="radio"
                  class="form-radio__input"
                  value="custom"
                />
                <label for="custom" class="form-radio__label">
                  {{ $t("dashboard.export_modal_date_range_custom_label") }}
                </label>
              </div>
              <div class="from">
                <datetime
                  input-id="start_date"
                  type="date"
                  input-class="mb-2 mb-sm-0 form-control"
                  v-model="custom.from"
                  :max-datetime="new Date().toISOString()"
                  :disabled="selectedDate !== 'custom'"
                  auto
                />
              </div>
              <div class="to" :class="{ danger: !isDatesCorrect }">
                <datetime
                  input-id="end_date"
                  type="date"
                  input-class="mb-2 mb-sm-0 form-control"
                  v-model="custom.to"
                  :max-datetime="new Date().toISOString()"
                  :disabled="selectedDate !== 'custom'"
                  auto
                />
              </div>
            </div>
            <div v-if="!isDatesCorrect" class="error-message">
              {{ $t("dashboard.export_dates_have_error") }}
            </div>
          </b-col>
          <!--/ end of intervals -->
          <b-col>
            <div class="report-type">
              <div class="report-type__legend">
                {{ $t("dashboard.export_modal_select_report_type_label") }}
              </div>
              <b-form-select
                v-model="reportTypeSelected"
                :options="reportTypeOptions"
                value-field="value"
                text-field="text"
              ></b-form-select>
            </div>
          </b-col>
        </div>
        <b-row v-if="reportTypeSelected === 'basic'">
          <b-col cols="12" class="d-flex mb-3">
            <h3 class="section-title">
              {{ $t("dashboard.export_modal_basic_report_options_label") }}<br />
              <span>{{ $t("dashboard.export_modal_basic_report_options_sub_label") }}</span>
            </h3>
            <button class="select-deselect" @click="selectDeselect(basicReportRaw, basicReport)">
              {{
                basicReportRaw.checked
                  ? $t("dashboard.export_modal_check_all_btn")
                  : $t("dashboard.export_modal_clear_all_btn")
              }}
            </button>
          </b-col>
          <b-col cols="12" md="4" v-for="(item, index) in basicReportRaw.data" :key="index">
            <b-form-checkbox
              :id="`br-${index}`"
              v-model="basicReport[item[0]]"
              :disabled="item[0] === 'donation_id'"
            >
              {{ item[2] }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12">
            <div v-if="!isReportCorrect" class="error-message">
              {{ $t("dashboard.export_basic_report_have_error") }}
            </div>
          </b-col>
        </b-row>
        <b-row v-if="reportTypeSelected === 'advanced'">
          <b-col cols="12" class="d-flex mb-3">
            <h3 class="section-title">
              {{ $t("dashboard.export_modal_advanced_report_options_label") }}
            </h3>
            <button
              class="select-deselect"
              @click="selectDeselectReversed(advancedReportRaw, advancedReport)"
            >
              {{
                advancedReportRaw.checked
                  ? $t("dashboard.export_modal_check_all_btn")
                  : $t("dashboard.export_modal_clear_all_btn")
              }}
            </button>
          </b-col>
          <b-col cols="12" md="4" v-for="(item, index) in advancedReportRaw.data" :key="index">
            <b-form-checkbox
              :id="`ar-${index}`"
              v-model="advancedReport[item[0]]"
              :disabled="item[0] === 'donation_id'"
            >
              {{ item[2] }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row v-if="reportTypeSelected === 'mediaPerformance'">
          <b-col cols="12" class="d-flex mb-3">
            <h3 class="section-title">
              {{ $t("dashboard.export_modal_mediaPerformance_report_options_label") }}
            </h3>
            <button
              class="select-deselect"
              @click="selectDeselect(mediaPerformanceReportRaw, mediaPerformanceReport)"
            >
              {{
                mediaPerformanceReportRaw.checked
                  ? $t("dashboard.export_modal_check_all_btn")
                  : $t("dashboard.export_modal_clear_all_btn")
              }}
            </button>
          </b-col>
          <b-col
            cols="12"
            md="4"
            v-for="(item, index) in mediaPerformanceReportRaw.data"
            :key="index"
          >
            <b-form-checkbox
              :id="`mpr-${index}`"
              v-model="mediaPerformanceReport[item[0]]"
              :disabled="item[0] === 'donation_id'"
            >
              {{ item[2] }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row v-if="reportTypeSelected === 'clearance'">
          <b-col cols="12" class="d-flex mb-3">
            <h3 class="section-title">
              {{ $t("dashboard.export_modal_clearance_report_options_label") }}
            </h3>
            <button
              class="select-deselect"
              @click="selectDeselect(clearanceReportRaw, clearanceReport)"
            >
              {{
                clearanceReportRaw.checked
                  ? $t("dashboard.export_modal_check_all_btn")
                  : $t("dashboard.export_modal_clear_all_btn")
              }}
            </button>
          </b-col>
          <b-col cols="12" md="4" v-for="(item, index) in clearanceReportRaw.data" :key="index">
            <b-form-checkbox
              :id="`cr-${index}`"
              v-model="clearanceReport[item[0]]"
              :disabled="item[0] === 'donation_id'"
            >
              {{ item[2] }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row v-if="reportTypeSelected === 'crmExport'">
          <b-col cols="12" class="d-flex mb-3">
            <h3 class="section-title">
              {{ $t("dashboard.export_modal_crmExport_report_options_label") }}
            </h3>
            <button
              class="select-deselect"
              @click="selectDeselect(crmExportReportRaw, crmExportReport)"
            >
              {{
                crmExportReportRaw.checked
                  ? $t("dashboard.export_modal_check_all_btn")
                  : $t("dashboard.export_modal_clear_all_btn")
              }}
            </button>
          </b-col>
          <b-col cols="12" md="4" v-for="(item, index) in crmExportReportRaw.data" :key="index">
            <b-form-checkbox
              :id="`cerr-${index}`"
              v-model="crmExportReport[item[0]]"
              :disabled="item[0] === 'donation_id'"
            >
              {{ item[2] }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row v-if="reportTypeSelected === 'igulLetova'">
          <b-col cols="12" class="d-flex mb-3">
            <h3 class="section-title">
              {{ $t("dashboard.export_modal_igulLetova_report_options_label") }}
            </h3>
            <button
              class="select-deselect"
              @click="selectDeselect(igulLetovaReportRaw, igulLetovaReport)"
            >
              {{
                igulLetovaReportRaw.checked
                  ? $t("dashboard.export_modal_check_all_btn")
                  : $t("dashboard.export_modal_clear_all_btn")
              }}
            </button>
          </b-col>
          <b-col cols="12" md="4" v-for="(item, index) in igulLetovaReportRaw.data" :key="index">
            <b-form-checkbox
              :id="`ilr-${index}`"
              v-model="igulLetovaReport[item[0]]"
              :disabled="item[0] === 'donation_id'"
            >
              {{ item[2] }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row v-if="reportTypeSelected === 'admireExport'">
          <b-col cols="12" class="d-flex mb-3">
            <h3 class="section-title">
              {{ $t("dashboard.export_modal_admireExport_report_options_label", "Admire Export") }}
            </h3>
            <button
              class="select-deselect"
              @click="selectDeselect(admireExportReportRaw, admireExportReport)"
            >
              {{
                admireExportReportRaw.checked
                  ? $t("dashboard.export_modal_check_all_btn")
                  : $t("dashboard.export_modal_clear_all_btn")
              }}
            </button>
          </b-col>
          <b-col cols="12" md="4" v-for="(item, index) in admireExportReportRaw.data" :key="index">
            <b-form-checkbox
              :id="`admire-report-select-${index}`"
              v-model="admireExportReport[item[0]]"
              :disabled="item[0] === 'Admire_WebId' || item[0] === 'Admire_AlreadyBilled'"
            >
              {{ item[2] }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row v-if="reportTypeSelected === 'tessituraExport'">
          <b-col cols="12" class="d-flex mb-3">
            <h3 class="section-title">
              {{
                $t("dashboard.export_modal_admireExport_report_options_label", "Tessitura Export")
              }}
            </h3>
            <!--button
              class="select-deselect"
              @click="selectDeselect(tessituraExportReportRaw, tessituraExportReport)"
            >
              {{
                admireExportReportRaw.checked
                  ? $t("dashboard.export_modal_check_all_btn")
                  : $t("dashboard.export_modal_clear_all_btn")
              }}
            </button -->
          </b-col>
          <!-- b-col cols="12" md="4" v-for="(item, index) in tessituraExportReportRaw.data" :key="index">
            <b-form-checkbox
              :id="`tessitura-report-select-${index}`"
              v-model="tessituraExportReport[item[0]]"
            >
              {{ item[2] }}
            </b-form-checkbox>
          </b-col -->
        </b-row>
      </div>

      <b-row v-if="showFilters">
        <b-col class="rtl-justify-start">
          <div
            class="form-checkbox form-checkbox--orange m-0"
            :class="{
              'form-checkbox--disabled':
                reportTypeSelected === 'admireExport' || reportTypeSelected === 'tessituraExport',
            }"
          >
            <input
              id="format-csv"
              class="form-checkbox__input"
              type="checkbox"
              v-model="exportAsCSV"
              :disabled="
                reportTypeSelected === 'admireExport' || reportTypeSelected === 'tessituraExport'
              "
            />
            <label class="form-checkbox__label" for="format-csv">
              {{ $t("dashboard.export_modal_csv_format") }}
            </label>
          </div>
          <span class="text-break">
            {{ $t("dashboard.export_modal_csv_format_recomended_msg") }}
          </span>
        </b-col>
        <b-col>
          <b-form-group class="w-100">
            <b-form-select
              v-model="tab"
              :options="tabOptions"
              :disabled="
                !exportAsCSV ||
                reportTypeSelected === 'admireExport' ||
                reportTypeSelected === 'tessituraExport'
              "
              value-field="value"
              text-field="text"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <form id="export-donation" class="entities-data">
        <div class="info-content success-desc" v-if="exportLink.file && success">
          <p>{{ $t("dashboard.export_success_have_button") }}</p>
        </div>

        <div class="error info-content" v-if="!exportLink.file && success">
          <p>{{ $t("dashboard.export_check_email") }}</p>
        </div>

        <div class="error info-content" v-if="exportTaskError">
          <p>{{ exportTaskError }}</p>
        </div>

        <div class="loading-link-wrap" v-if="!success">
          <div v-if="!isLoading">
            <p>{{ $t("dashboard.export_export_description") }}</p>

            <error-message :response.sync="errorResponse"></error-message>

            <div class="form-group form-unit">
              <label>{{ $t("dashboard.export_email") }}</label>
              <input
                class="form-control"
                name="email"
                v-model="email"
                @keydown.enter.prevent="exportList"
                v-validate="'required|email'"
                mode="passive"
              />
              <span v-show="vErrors.has('email')" class="v-help is-danger">{{
                $t("template.email_is_wrong")
              }}</span>
            </div>

            <div v-if="!isReportCorrect" class="error-message">
              {{ $t("dashboard.export_basic_report_have_error") }}
            </div>

            <div v-if="!isDatesCorrect" class="error-message">
              {{ $t("dashboard.export_dates_have_error") }}
            </div>
          </div>

          <div class="info-content loading-link" v-if="isLoading">
            <p>{{ $t("dashboard.export_loading_link") }}...{{ progress }}%</p>
          </div>
          <error-message ref="error" :response.sync="errorResponse"></error-message>
        </div>
      </form>

      <div class="info-content" v-if="exportFormat === 'csv'">
        <p>{{ $t("dashboard.export_csv_message") }}</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import errorList from "@/components/error-message.vue";
import Multiselect from "vue-multiselect";

export default {
  props: [
    "campaignid",
    "category",
    "filters",
    "teamList",
    "multiselectTeam",
    "gateways",
    "customLabel",
    "canSeeInputSearch",
    "isHasLinkedCampaigns",
  ],

  data() {
    return {
      allGdSubCampaigns: false,
      allLinkedCampaigns: false,
      email: "",
      exportLink: "",
      exportFormat: "",
      exportAsCSV: false,
      tab: "processed",
      exportTaskError: "",
      isLoading: false,
      needEmail: false,
      success: false,
      errorResponse: null,
      procLoading: false, // the process of file uploading
      uploadResult: null, // json with result report
      successUpload: false, // if the import is comlpeted
      progress: 0,
      procID: 0,
      sqsMessageID: "",
      interval: null,
      showFilters: true,
      selectedTeam: null,
      checks: 0,
      maxChecks: 45,
      selectedDate: "all",
      custom: {
        from: new Date().toISOString(),
        to: new Date().toISOString(),
      },
      reportTypeSelected: "basic",
      basicReportRaw: {
        checked: false,
        data: [
          ["donation_id", true, this.$t("dashboard.export_field_donation_id", "Donation ID")],
          [
            "donation_date_and_time",
            true,
            this.$t("dashboard.export_field_donation_date_and_time", "Donation Date and Time"),
          ],
          [
            "billing_first_name",
            true,
            this.$t("dashboard.export_field_billing_first_name", "Billing First Name"),
          ],
          [
            "billing_last_name",
            true,
            this.$t("dashboard.export_field_billing_last_name", "Billing Last Name"),
          ],
          ["email", true, this.$t("dashboard.export_field_email", "Email")],
          ["phone", true, this.$t("dashboard.export_field_phone", "Phone")],
          ["phone_number", true, this.$t("dashboard.export_field_phone_number", "Phone Number")],
          [
            "area_phone_prefix",
            true,
            this.$t("dashboard.export_field_area_phone_prefix", "Area phone prefix"),
          ],
          [
            "country_phone_prefix",
            true,
            this.$t("dashboard.export_field_country_phone_prefix", "Country phone prefix"),
          ],
          ["charge_amount", true, this.$t("dashboard.export_field_charge_amount", "Charge Amount")],
          [
            "matched_total_amount",
            true,
            this.$t("dashboard.export_field_matched_total_amount", "Matched/Total Amount"),
          ],
          [
            "charge_amount_total",
            true,
            this.$t("dashboard.export_field_charge_amount_total", "Charge Amount Total"),
          ],
          ["currency", true, this.$t("dashboard.export_field_currency", "Currency")],
          ["bank_name", true, this.$t("dashboard.export_field_bank_name", "Bank Name")],
          ["invoice_no", true, this.$t("dashboard.export_field_invoice_no", "Invoice No")],
          ["status", true, this.$t("dashboard.export_field_status", "Status")],
          ["dedication", true, this.$t("dashboard.export_field_dedication", "Dedication")],
          [
            "billing_address_line_1",
            true,
            this.$t("dashboard.export_field_billing_address_line_1", "Billing Address Line 1"),
          ],
          [
            "billing_address_line_2",
            true,
            this.$t("dashboard.export_field_billing_address_line_2", "Billing Address Line 2"),
          ],
          [
            "billing_address_city",
            true,
            this.$t("dashboard.export_field_billing_address_city", "Billing Address City"),
          ],
          [
            "billing_address_zip_postal_code",
            true,
            this.$t(
              "dashboard.export_field_billing_address_zip_postal_code",
              "Billing Address Zip / Postal Code"
            ),
          ],
          [
            "billing_address_state_area",
            true,
            this.$t(
              "dashboard.export_field_billing_address_state_area",
              "Billing Address State / Area"
            ),
          ],
          [
            "billing_address_country",
            true,
            this.$t("dashboard.export_field_billing_address_country", "Billing Address Country"),
          ],
          [
            "last_four",
            false,
            this.$t("dashboard.export_field_last_four_digits", "Card last 4 digits"),
          ],
          [
            "transactional_id",
            true,
            this.$t("dashboard.export_field_transactional_id", "Transactional ID"),
          ],
        ],
      },
      mediaPerformanceReportRaw: {
        checked: false,
        data: [
          ["donation_id", true, this.$t("dashboard.export_field_donation_id", "Donation ID")],
          [
            "donation_date_and_time",
            true,
            this.$t("dashboard.export_field_donation_date_and_time", "Donation Date and Time"),
          ],
          ["bank_name", true, this.$t("dashboard.export_field_bank_name", "Bank Name")],
          [
            "original_charge_amount_total",
            true,
            this.$t(
              "dashboard.export_field_original_charge_amount_total",
              "Original Charge Amount Total"
            ),
          ],
          [
            "original_charge_currency",
            true,
            this.$t("dashboard.export_field_original_charge_currency", "Original Charge Currency"),
          ],
          ["recurring", true, this.$t("dashboard.export_field_recurring", "Is Recurring")],
          [
            "recurring_period",
            true,
            this.$t("dashboard.export_field_recurring_period", "Recurring number"),
          ],
          [
            "referred_source_id",
            true,
            this.$t("dashboard.export_field_referred_source_id", "Referrer / Source ID"),
          ],
          ["utm_shortlink", true, this.$t("dashboard.export_field_utm_shortlink", "UTM shortlink")],
          ["utm_source", true, this.$t("dashboard.export_field_utm_source", "UTM source")],
          ["utm_medium", true, this.$t("dashboard.export_field_utm_medium", "UTM medium")],
          ["team_id", true, this.$t("dashboard.export_field_team_id", "Team ID")],
          ["team_name", true, this.$t("dashboard.export_field_team_name", "Team Name")],
          [
            "team_leader_name",
            true,
            this.$t("dashboard.export_field_team_leader_name", "Team Leader Name"),
          ],
          [
            "parent_team_id",
            true,
            this.$t("dashboard.export_field_parent_team_id", "Parent Team ID"),
          ],
          [
            "parent_team_name",
            true,
            this.$t("dashboard.export_field_parent_team_name", "Parent Team Name"),
          ],
          [
            "grandparent_team_id",
            true,
            this.$t("dashboard.export_field_grandparent_team_id", "Grandparent Team ID"),
          ],
          [
            "grandparent_team_name",
            true,
            this.$t("dashboard.export_field_grandparent_team_name", "Grandparent Team Name"),
          ],
          ["installments", true, this.$t("dashboard.export_field_installments", "Installments")],
        ],
      },
      clearanceReportRaw: {
        checked: false,
        data: [
          ["donation_id", true, this.$t("dashboard.export_field_donation_id", "Donation ID")],
          [
            "donation_date_and_time",
            true,
            this.$t("dashboard.export_field_donation_date_and_time", "Donation Date and Time"),
          ],
          ["bank_name", true, this.$t("dashboard.export_field_bank_name", "Bank Name")],
          [
            "original_charge_amount",
            true,
            this.$t("dashboard.export_field_original_charge_amount", "Original Charge Amount"),
          ],
          [
            "original_charge_amount_total",
            true,
            this.$t(
              "dashboard.export_field_original_charge_amount_total",
              "Original Charge Amount Total"
            ),
          ],
          [
            "original_charge_currency",
            true,
            this.$t("dashboard.export_field_original_charge_currency", "Original Charge Currency"),
          ],
          [
            "offline_donation_source",
            true,
            this.$t("dashboard.export_field_offline_donation_source", "Offline Donation Source"),
          ],
          ["charge_amount", true, this.$t("dashboard.export_field_charge_amount", "Charge Amount")],
          [
            "matched_total_amount",
            true,
            this.$t("dashboard.export_field_matched_total_amount", "Matched/Total Amount"),
          ],
          [
            "transactional_id",
            true,
            this.$t("dashboard.export_field_transactional_id", "Transaction ID"),
          ],
          ["last_four", true, this.$t("dashboard.export_field_last_four", "Last Four")],
          ["recurring_id", true, this.$t("dashboard.export_field_recurring_id", "Recurring ID")],
          [
            "recurring_period",
            true,
            this.$t("dashboard.export_field_recurring_period", "Recurring period"),
          ],
          ["installments", true, this.$t("dashboard.export_field_installments", "Installments")],
          ["team_id", true, this.$t("dashboard.export_field_team_id", "Team ID")],
          ["team_name", true, this.$t("dashboard.export_field_team_name", "Team Name")],
          [
            "team_leader_name",
            true,
            this.$t("dashboard.export_field_team_leader_name", "Team Leader Name"),
          ],
          [
            "parent_team_id",
            true,
            this.$t("dashboard.export_field_parent_team_id", "Parent Team ID"),
          ],
          [
            "parent_team_name",
            true,
            this.$t("dashboard.export_field_parent_team_name", "Parent Team Name"),
          ],
          [
            "grandparent_team_id",
            true,
            this.$t("dashboard.export_field_grandparent_team_id", "Grandparent Team ID"),
          ],
          [
            "grandparent_team_name",
            true,
            this.$t("dashboard.export_field_grandparent_team_name", "Grandparent Team Name"),
          ],
        ],
      },
      crmExportReportRaw: {
        checked: false,
        data: [
          ["donation_id", true, this.$t("dashboard.export_field_donation_id", "Donation ID")],
          [
            "donation_date_and_time",
            true,
            this.$t("dashboard.export_field_donation_date_and_time", "Donation Date and Time"),
          ],
          [
            "billing_first_name",
            true,
            this.$t("dashboard.export_field_billing_first_name", "Billing First Name"),
          ],
          [
            "billing_last_name",
            true,
            this.$t("dashboard.export_field_billing_last_name", "Billing Last Name"),
          ],
          ["email", true, this.$t("dashboard.export_field_email", "Email")],
          ["phone", true, this.$t("dashboard.export_field_phone", "Phone")],
          ["phone_number", true, this.$t("dashboard.export_field_phone_number", "Phone Number")],
          [
            "area_phone_prefix",
            true,
            this.$t("dashboard.export_field_area_phone_prefix", "Area phone prefix"),
          ],
          [
            "country_phone_prefix",
            true,
            this.$t("dashboard.export_field_country_phone_prefix", "Country phone prefix"),
          ],
          [
            "billing_address_line_1",
            true,
            this.$t("dashboard.export_field_billing_address_line_1", "Billing Address Line 1"),
          ],
          [
            "billing_address_line_2",
            true,
            this.$t("dashboard.export_field_billing_address_line_2", "Billing Address Line 2"),
          ],
          [
            "billing_address_city",
            true,
            this.$t("dashboard.export_field_billing_address_city", "Billing Address City"),
          ],
          [
            "billing_address_zip_postal_code",
            true,
            this.$t(
              "dashboard.export_field_billing_address_zip_postal_code",
              "Billing Address Zip / Postal Code"
            ),
          ],
          [
            "billing_address_state_area",
            true,
            this.$t(
              "dashboard.export_field_billing_address_state_area",
              "Billing Address State / Area"
            ),
          ],
          [
            "billing_address_country",
            true,
            this.$t("dashboard.export_field_billing_address_country", "Billing Address Country"),
          ],
          ["bank_name", true, this.$t("dashboard.export_field_bank_name", "Bank Name")],
          ["campaign", true, this.$t("dashboard.export_field_campaign", "Campaign Name")],
          ["charge_amount", true, this.$t("dashboard.export_field_charge_amount", "Charge Amount")],
          ["installments", true, this.$t("dashboard.export_field_installments", "Installments")],
          ["recurring", true, this.$t("dashboard.export_field_recurring", "Is Recurring")],
          [
            "recurring_period",
            true,
            this.$t("dashboard.export_field_recurring_period", "Recurring period"),
          ],
          [
            "charge_amount_total",
            true,
            this.$t("dashboard.export_field_charge_amount_total", "Charge Amount Total"),
          ],
          ["currency", true, this.$t("dashboard.export_field_currency", "Currency")],
          [
            "original_charge_amount",
            true,
            this.$t("dashboard.export_field_original_charge_amount", "Original Charge Amount"),
          ],
          [
            "original_charge_currency",
            true,
            this.$t("dashboard.export_field_original_charge_currency", "Original Charge Currency"),
          ],
          [
            "original_charge_amount_total",
            true,
            this.$t(
              "dashboard.export_field_original_charge_amount_total",
              "Original Charge Amount Total"
            ),
          ],
          ["last_four", true, this.$t("dashboard.export_field_last_four", "Last Four")],
          ["invoice_no", true, this.$t("dashboard.export_field_invoice_no", "Invoice No")],
          [
            "transactional_id",
            true,
            this.$t("dashboard.export_field_transactional_id", "Transaction ID"),
          ],
          ["dedication", true, this.$t("dashboard.export_field_dedication", "Dedication")],
          ["status", true, this.$t("dashboard.export_field_status", "Status")],
          ["team_name", true, this.$t("dashboard.export_field_team_name", "Team Name")],
          [
            "team_leader_name",
            true,
            this.$t("dashboard.export_field_team_leader_name", "Team Leader Name"),
          ],
          ["team_link", true, this.$t("dashboard.export_field_team_link", "Team Link")],
          [
            "parent_team_name",
            true,
            this.$t("dashboard.export_field_parent_team_name", "Parent Team Name"),
          ],
          [
            "offline_donation_source",
            true,
            this.$t("dashboard.export_field_offline_donation_source", "Offline Donation Source"),
          ],
          [
            "offline_donation_note",
            true,
            this.$t("dashboard.export_field_offline_donation_note", "Offline Donation Note"),
          ],
          [
            "charged_tip_amount",
            true,
            this.$t("dashboard.export_field_charged_tip_amount", "Charged Tip Amount"),
          ],
          ["recurring_id", true, this.$t("dashboard.export_field_recurring_id", "Recurring ID")],
        ],
      },
      igulLetovaReportRaw: {
        checked: false,
        data: [
          ["donation_id", true, this.$t("dashboard.export_field_donation_id", "Donation ID")],
          [
            "donation_date_and_time",
            true,
            this.$t("dashboard.export_field_donation_date_and_time", "Donation Date and Time"),
          ],
          [
            "billing_first_name",
            true,
            this.$t("dashboard.export_field_billing_first_name", "Billing First Name"),
          ],
          [
            "billing_last_name",
            true,
            this.$t("dashboard.export_field_billing_last_name", "Billing Last Name"),
          ],
          ["email", true, this.$t("dashboard.export_field_email", "Email")],
          ["phone", true, this.$t("dashboard.export_field_phone", "Phone")],
          ["phone_number", true, this.$t("dashboard.export_field_phone_number", "Phone Number")],
          [
            "area_phone_prefix",
            true,
            this.$t("dashboard.export_field_area_phone_prefix", "Area phone prefix"),
          ],
          [
            "country_phone_prefix",
            true,
            this.$t("dashboard.export_field_country_phone_prefix", "Country phone prefix"),
          ],
          [
            "billing_address_line_1",
            true,
            this.$t("dashboard.export_field_billing_address_line_1", "Billing Address Line 1"),
          ],
          [
            "billing_address_line_2",
            true,
            this.$t("dashboard.export_field_billing_address_line_2", "Billing Address Line 2"),
          ],
          [
            "billing_address_city",
            true,
            this.$t("dashboard.export_field_billing_address_city", "Billing Address City"),
          ],
          [
            "billing_address_zip_postal_code",
            true,
            this.$t(
              "dashboard.export_field_billing_address_zip_postal_code",
              "Billing Address Zip / Postal Code"
            ),
          ],
          [
            "billing_address_state_area",
            true,
            this.$t(
              "dashboard.export_field_billing_address_state_area",
              "Billing Address State / Area"
            ),
          ],
          [
            "billing_address_country",
            true,
            this.$t("dashboard.export_field_billing_address_country", "Billing Address Country"),
          ],
          ["bank_name", true, this.$t("dashboard.export_field_bank_name", "Bank Name")],
          ["charge_amount", true, this.$t("dashboard.export_field_charge_amount", "Charge Amount")],
          [
            "matched_total_amount",
            true,
            this.$t("dashboard.export_field_matched_total_amount", "Matched/Total Amount"),
          ],
          [
            "transactional_id",
            true,
            this.$t("dashboard.export_field_transactional_id", "Transaction ID"),
          ],
          ["last_four", true, this.$t("dashboard.export_field_last_four", "Last Four")],
          ["invoice_no", true, this.$t("dashboard.export_field_invoice_no", "Invoice No")],
          ["status", true, this.$t("dashboard.export_field_status", "Status")],
          ["team_name", true, this.$t("dashboard.export_field_team_name", "Team Name")],
          [
            "team_leader_name",
            true,
            this.$t("dashboard.export_field_team_leader_name", "Team Leader Name"),
          ],
          ["team_link", true, this.$t("dashboard.export_field_team_link", "Team Link")],
          [
            "parent_team_name",
            true,
            this.$t("dashboard.export_field_parent_team_name", "Parent Team Name"),
          ],
          [
            "offline_donation_source",
            true,
            this.$t("dashboard.export_field_offline_donation_source", "Offline Donation Source"),
          ],
          [
            "offline_donation_note",
            true,
            this.$t("dashboard.export_field_offline_donation_note", "Offline Donation Note"),
          ],
          [
            "charged_tip_amount",
            true,
            this.$t("dashboard.export_field_charged_tip_amount", "Charged Tip Amount"),
          ],
          ["recurring_id", true, this.$t("dashboard.export_field_recurring_id", "Recurring ID")],
          [
            "approved_igul_letova",
            true,
            this.$t("dashboard.export_field_approved_igul_letova", "Approved Igul Letova"),
          ],
          [
            "igm_approved_igul_letova",
            true,
            this.$t(
              "dashboard.export_field_approved_igul_letova",
              "Approved Igul Letova (for IGM Module campaign)"
            ),
          ],
          [
            "igm_igul_letova_zehut",
            true,
            this.$t("dashboard.export_field_igul_letova_zehut", "Igul Letova Zehut"),
          ],
          [
            "igm_igul_letova_card_last4",
            true,
            this.$t("dashboard.export_field_igul_letova_card_last4", "Igul Letova Card Last4"),
          ],
          [
            "igm_igul_letova_card_exp_month",
            true,
            this.$t(
              "dashboard.export_field_igul_letova_card_exp_month",
              "Igul Letova Card Exp Month"
            ),
          ],
          [
            "igm_igul_letova_card_exp_year",
            true,
            this.$t(
              "dashboard.export_field_igul_letova_card_exp_year",
              "Igul Letova Card Exp Year"
            ),
          ],
          [
            "igm_igul_letova_card_issuer",
            true,
            this.$t("dashboard.export_field_igul_letova_card_issuer", "Igul Letova Card Issuer"),
          ],
        ],
      },
      admireExportReportRaw: {
        checked: false,
        data: [
          ["Admire_WebId", true, this.$t("dashboard.export_field_Admire_WebId", "WebId")],
          ["Admire_Date", true, this.$t("dashboard.export_field_Admire_Date", "Date")],
          [
            "Admire_FirstName",
            true,
            this.$t("dashboard.export_field_Admire_FirstName", "FirstName"),
          ],
          ["Admire_LastName", true, this.$t("dashboard.export_field_Admire_LastName", "LastName")],
          ["Admire_Email", true, this.$t("dashboard.export_field_Admire_Email", "Email")],
          ["Admire_Phone", true, this.$t("dashboard.export_field_Admire_Phone", "Phone")],
          [
            "Admire_InsAmount",
            true,
            this.$t("dashboard.export_field_Admire_InsAmount", "InsAmount"),
          ],
          ["Admire_Amount", true, this.$t("dashboard.export_field_Admire_Amount", "Amount")],
          ["Admire_Currency", true, this.$t("dashboard.export_field_Admire_Currency", "Currency")],
          [
            "Admire_PmtMethod",
            true,
            this.$t("dashboard.export_field_Admire_PmtMethod", "PmtMethod"),
          ],
          ["Admire_Address", true, this.$t("dashboard.export_field_Admire_Address", "Address")],
          ["Admire_Apt", true, this.$t("dashboard.export_field_Admire_Apt", "Apt")],
          ["Admire_City", true, this.$t("dashboard.export_field_Admire_City", "City")],
          ["Admire_Zip", true, this.$t("dashboard.export_field_Admire_Zip", "Zip")],
          ["Admire_State", true, this.$t("dashboard.export_field_Admire_State", "State")],
          ["Admire_Country", true, this.$t("dashboard.export_field_Admire_Country", "Country")],
          ["Admire_ZipFour", true, this.$t("dashboard.export_field_Admire_ZipFour", "ZipFour")],
          ["Admire_Occasion", true, this.$t("dashboard.export_field_Admire_Occasion", "Occasion")],
          [
            "Admire_ReceiptNumber",
            true,
            this.$t("dashboard.export_field_Admire_ReceiptNumber", "ReceiptNumber"),
          ],
          [
            "Admire_Association",
            true,
            this.$t("dashboard.export_field_Admire_Association", "Association"),
          ],
          [
            "Admire_Association2",
            true,
            this.$t("dashboard.export_field_Admire_Association2", "Association2"),
          ],
          [
            "Admire_PledgeNotes",
            true,
            this.$t("dashboard.export_field_Admire_PledgeNotes", "PledgeNotes"),
          ],
          ["Admire_WebPlgId", true, this.$t("dashboard.export_field_Admire_WebPlgId", "WebPlgId")],
          ["Admire_InsQty", true, this.$t("dashboard.export_field_Admire_InsQty", "InsQty")],
          [
            "Admire_IntervalType",
            true,
            this.$t("dashboard.export_field_Admire_IntervalType", "IntervalType"),
          ],
          ["Admire_Token", true, this.$t("dashboard.export_field_Admire_Token", "Token")],
          [
            "Admire_CompanyName",
            true,
            this.$t("dashboard.export_field_Admire_CompanyName", "CompanyName"),
          ],
          [
            "Admire_CreatePlg",
            true,
            this.$t("dashboard.export_field_Admire_CreatePlg", "CreatePlg"),
          ],
          [
            "Admire_AlreadyBilled",
            true,
            this.$t("dashboard.export_field_Admire_AlreadyBilled", "AlreadyBilled"),
          ],
        ],
      },
      basicReport: {},
      advancedReport: {},
      mediaPerformanceReport: {},
      clearanceReport: {},
      crmExportReport: {},
      igulLetovaReport: {},
      admireExportReport: {},
    };
  },

  components: {
    errorMessage: errorList,
    Multiselect,
  },
  created() {
    this.basicReport = Object.fromEntries(this.basicReportRaw.data);
    this.advancedReport = Object.fromEntries(this.advancedReportRaw.data);
    this.mediaPerformanceReport = Object.fromEntries(this.mediaPerformanceReportRaw.data);
    this.clearanceReport = Object.fromEntries(this.clearanceReportRaw.data);
    this.crmExportReport = Object.fromEntries(this.crmExportReportRaw.data);
    this.igulLetovaReport = Object.fromEntries(this.igulLetovaReportRaw.data);
    this.admireExportReport = Object.fromEntries(this.admireExportReportRaw.data);
  },
  computed: {
    ...mapState({
      orgId: function () {
        return localStorage.getItem("orgId");
      },

      hasAccess: (state) => {
        return state.account.access.CanExportCampaignDonationList;
      },
      accTimezone: (state) => state.account.account.data.attributes.timezone,
      lang: (state) => state.i18n.locale,
    }),
    tabOptions() {
      if (this.reportTypeSelected === "admireExport") {
        return [
          {
            value: "processed",
            text: this.$t("dashboard.export_modal_admire_tab", "Campaign Donations"),
          },
        ];
      }

      if (this.reportTypeSelected === "tessituraExport") {
        return [
          {
            value: "processed",
            text: this.$t("dashboard.export_modal_admire_tab", "Campaign Donations"),
          },
        ];
      }

      return [
        {
          value: "processed",
          text: this.$t("dashboard.export_modal_processed_tab"),
        },
        {
          value: "pendingandcanceled",
          text: this.$t("dashboard.export_modal_pendingandcanceled_tab"),
        },
        {
          value: "recurring",
          text: this.$t("dashboard.export_modal_recurring_tab"),
        },
        {
          value: "failed",
          text: this.$t("dashboard.export_modal_failed_tab"),
        },
        {
          value: "onhold",
          text: this.$t("dashboard.export_modal_onhold_tab"),
        },
      ];
    },
    reportTypeOptions() {
      return [
        {
          value: "basic",
          text: this.$t("dashboard.export_modal_basic_report"),
        },
        {
          value: "mediaPerformance",
          text: this.$t("dashboard.export_modal_media_performance_report"),
        },
        {
          value: "clearance",
          text: this.$t("dashboard.export_modal_clearance_report"),
        },
        {
          value: "advanced",
          text: this.$t("dashboard.export_modal_advanced_report"),
        },
        {
          value: "crmExport",
          text: this.$t("dashboard.export_modal_crmExport_report", "CRM export"),
        },
        {
          value: "crmExportV2",
          text: this.$t("dashboard.export_modal_crmExportV2_report", "CRM export v2"),
        },
        {
          value: "igulLetova",
          text: this.$t("dashboard.export_modal_igulLetova_report", "Igul Letova"),
        },
        {
          value: "admireExport",
          text: this.$t("dashboard.export_modal_admireExport_report", "Admire Export"),
        },
        {
          value: "tessituraExport",
          text: this.$t("dashboard.export_modal_tessituraExport_report", "Tessitura Export"),
        },
      ];
    },
    teamsList() {
      return this.teamList;
    },
    isEmailSet() {
      return this.email.length !== 0;
    },
    isDatesCorrect() {
      return this.selectedDate === "custom" ? this.custom.from <= this.custom.to : true;
    },
    isReportCorrect() {
      let res = [];

      switch (this.reportTypeSelected) {
        case "basic":
          res = Object.values(this.basicReport);
          break;
        case "mediaPerformance":
          res = Object.values(this.mediaPerformanceReport);
          break;
        case "clearance":
          res = Object.values(this.clearanceReport);
          break;
        case "advanced":
          res = Object.values(this.advancedReport);
          break;
        case "crmExport":
          res = Object.values(this.crmExportReport);
          break;
        case "igulLetova":
          res = Object.values(this.igulLetovaReport);
          break;
        case "crmExportV2":
          res = [true];
          break;
        case "admireExport":
          res = Object.values(this.admireExportReport);
          break;
        case "tessituraExport":
          res = [true];
          break;
      }

      return res.includes(true);
    },
    advancedReportRaw() {
      return {
        checked: true,
        data: [
          ...this.basicReportRaw.data,
          ["campaign", false, this.$t("dashboard.export_field_campaign", "Campaign Name")],
          ["display_name", false, this.$t("dashboard.export_field_display_name", "Display Name")],
          ["receipt_name", false, this.$t("dashboard.export_field_receipt_name", "Receipt Name")],
          [
            "receipt_number",
            false,
            this.$t("dashboard.export_field_receipt_number", "Receipt Number"),
          ],
          [
            "original_charge_amount",
            false,
            this.$t("dashboard.export_field_original_charge_amount", "Original Charge Amount"),
          ],
          [
            "original_charge_currency",
            false,
            this.$t("dashboard.export_field_original_charge_currency", "Original Charge Currency"),
          ],
          [
            "gateway_confirmation_code",
            false,
            this.$t(
              "dashboard.export_field_gateway_confirmation_code",
              "Gateway Confirmation Code"
            ),
          ],
          [
            "account_number",
            false,
            this.$t("dashboard.export_field_account_number", "Account Number"),
          ],
          [
            "referred_source_id",
            false,
            this.$t("dashboard.export_field_referred_source_id", "Referrer / Source ID"),
          ],
          ["utm_shortlink", true, this.$t("dashboard.export_field_utm_shortlink", "UTM shortlink")],
          ["utm_source", true, this.$t("dashboard.export_field_utm_source", "UTM source")],
          ["utm_medium", true, this.$t("dashboard.export_field_utm_medium", "UTM medium")],
          [
            "donation_earmark_group",
            false,
            this.$t("dashboard.export_field_donation_earmark_group", "Donation Earmark / Group"),
          ],
          ["team_id", false, this.$t("dashboard.export_field_team_id", "Team ID")],
          [
            "team_id_team_name",
            false,
            this.$t("dashboard.export_field_team_id_team_name", "Team ID Team Name"),
          ],
          ["team_name", false, this.$t("dashboard.export_field_team_name", "Team Name")],
          [
            "team_leader_name",
            false,
            this.$t("dashboard.export_field_team_leader_name", "Team Leader Name"),
          ],
          //--
          ["team_link", false, this.$t("dashboard.export_field_team_link", "Team Link")],
          ["team_goal", false, this.$t("dashboard.export_field_team_goal", "Team Goal")],
          [
            "parent_team_id",
            false,
            this.$t("dashboard.export_field_parent_team_id", "Parent Team ID"),
          ],
          [
            "parent_team_name",
            false,
            this.$t("dashboard.export_field_parent_team_name", "Parent Team Name"),
          ],
          [
            "parent_team_link",
            false,
            this.$t("dashboard.export_field_parent_team_link", "Parent Team Link"),
          ],
          [
            "grandparent_team_id",
            false,
            this.$t("dashboard.export_field_grandparent_team_id", "Grandparent Team ID"),
          ],
          [
            "grandparent_team_name",
            false,
            this.$t("dashboard.export_field_grandparent_team_name", "Grandparent Team Name"),
          ],
          [
            "grandparent_team_link",
            false,
            this.$t("dashboard.export_field_grandparent_team_link", "Grandparent Team Link"),
          ],
          //--
          [
            "donation_stream",
            false,
            this.$t("dashboard.export_field_donation_stream", "Donation Stream"),
          ],
          [
            "donation_preset_level",
            false,
            this.$t("dashboard.export_field_donation_preset_level", "Donation Preset Level"),
          ],
          ["gift_aid", false, this.$t("dashboard.export_field_gift_aid", "GiftAid")],
          [
            "offline_donation_source",
            false,
            this.$t("dashboard.export_field_offline_donation_source", "Offline Donation Source"),
          ],
          [
            "offline_donation_note",
            false,
            this.$t("dashboard.export_field_offline_donation_note", "Offline Donation Note"),
          ],
          [
            "offline_donation_added_by",
            false,
            this.$t(
              "dashboard.export_field_offline_donation_added_by",
              "Offline Donation Added By (account email)"
            ),
          ],
          ["note", false, this.$t("dashboard.export_field_note", "Note")],
          [
            "custom_fields",
            false,
            this.$t("dashboard.export_field_custom_fields", "Custom Fields"),
          ],
          //---
          ["recurring", false, this.$t("dashboard.export_field_recurring", "Is Recurring")],
          ["recurring_id", false, this.$t("dashboard.export_field_recurring_id", "Recurring ID")],
          [
            "recurring_period",
            false,
            this.$t("dashboard.export_field_recurring_period", "Recurring period"),
          ],
          ["installments", false, this.$t("dashboard.export_field_installments", "Installments")],
          [
            "original_charge_amount_total",
            false,
            this.$t(
              "dashboard.export_field_original_charge_amount_total",
              "Original Charge Amount Total"
            ),
          ],
          ["campaign_id", false, this.$t("dashboard.export_field_campaign_id", "Campaign ID")],
          ["bank", false, this.$t("dashboard.export_field_bank", "Bank")],
          ["branch", false, this.$t("dashboard.export_field_branch", "Branch")],
          ["account", false, this.$t("dashboard.export_field_account", "Account")],
          [
            "successful_donation_id",
            false,
            this.$t(
              "dashboard.export_field_successful_donation_id",
              "Successful Donation ID (for failed donations)"
            ),
          ],
          ["check_n", false, this.$t("dashboard.export_field_check_n", "Check N")],
          ["ga", false, this.$t("dashboard.export_field_ga", "Is tracked by Google Analytics")],
          [
            "charge_curr_gateway_fee_cover_amt_min_curr_denom",
            false,
            this.$t(
              "dashboard.export_field_charge_curr_gateway_fee_cover_amt_min_curr_denom",
              "Charge curr gateway fee cover amt min curr denom"
            ),
          ],
          [
            "card_holder_id",
            false,
            this.$t("dashboard.export_field_card_holder_id", "Card Holder ID"),
          ],
          [
            "credit_card_exp_date",
            false,
            this.$t("dashboard.export_field_credit_card_exp_date", "Credit Card Exp Date"),
          ],
          ["card_token", false, this.$t("dashboard.export_field_card_token", "Credit Card Token")],
          ["card_issuer", false, this.$t("dashboard.export_field_card_issuer", "Card Issuer")],
          [
            "approved_igul_letova",
            false,
            this.$t("dashboard.export_field_approved_igul_letova", "Approved Igul Letova"),
          ],
          [
            "cdr_approved_igul_letova",
            false,
            this.$t(
              "dashboard.export_field_approved_igul_letova",
              "Approved Igul Letova (for IGM Module campaign)"
            ),
          ],
          [
            "igm_igul_letova_zehut",
            false,
            this.$t("dashboard.export_field_igul_letova_zehut", "Igul Letova Zehut"),
          ],
          [
            "igm_igul_letova_card_last4",
            false,
            this.$t("dashboard.export_field_igul_letova_card_last4", "Igul Letova Card Last4"),
          ],
          [
            "igm_igul_letova_card_exp_month",
            false,
            this.$t(
              "dashboard.export_field_igul_letova_card_exp_month",
              "Igul Letova Card Exp Month"
            ),
          ],
          [
            "igm_igul_letova_card_exp_year",
            false,
            this.$t(
              "dashboard.export_field_igul_letova_card_exp_year",
              "Igul Letova Card Exp Year"
            ),
          ],
          [
            "igm_igul_letova_card_issuer",
            false,
            this.$t("dashboard.export_field_igul_letova_card_issuer", "Igul Letova Card Issuer"),
          ],
        ],
      };
    },
  },
  watch: {
    procID: function () {
      if (this.procID > 0 && this.interval == null) {
        this.initProcTracking();
      }
    },
    sqsMessageID: function () {
      if (this.sqsMessageID && this.interval == null) {
        this.initTaskTracking();
      }
    },
    reportTypeSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        switch (newValue) {
          case "basic": {
            this.advancedReport = Object.fromEntries(this.advancedReportRaw.data);
            this.mediaPerformanceReport = Object.fromEntries(this.mediaPerformanceReportRaw.data);
            this.clearanceReport = Object.fromEntries(this.clearanceReportRaw.data);
            this.crmExportReport = Object.fromEntries(this.crmExportReportRaw.data);
            this.igulLetovaReport = Object.fromEntries(this.igulLetovaReportRaw.data);
            this.advancedReport.checked = true;
            this.admireExportReport = Object.fromEntries(this.admireExportReportRaw.data);
            break;
          }
          case "admireExport": {
            this.exportAsCSV = true;
            break;
          }
          case "tessituraExport": {
            this.exportAsCSV = true;
          }
        }
      }
    },
    email(newValue) {
      if (newValue === "") {
        this.$validator.reset();
      }
    },
  },

  methods: {
    clearData: function () {
      this.allGdSubCampaigns = false;
      this.allLinkedCampaigns = false;
      this.email = "";
      this.exportLink = "";
      this.exportFormat = "";
      this.exportTaskError = "";
      this.isLoading = false;
      this.needEmail = false;
      this.exportAsCSV = false;
      this.tab = "processed";
      this.success = false;
      this.progress = 0;
      this.procID = 0;
      this.sqsMessageID = "";
      this.maxChecks = 45;
      this.checks = 0;
      this.interval = null;
      this.procLoading = false; // the process of file uploading
      this.uploadResult = null; // json with result report
      this.successUpload = false; // if the import is comlpeted
      this.showFilters = true;
      this.reportTypeSelected = "basic";
      // Clear to defaults
      Object.keys(this.basicReport).forEach((key) => {
        const found = this.basicReportRaw.data.find((v) => v[0] === key);
        this.$set(this.basicReport, key, found[1]);
      });
      this.selectedTeam = null;
      this.selectedDate = "all";
      this.custom.from = new Date().toISOString();
      this.custom.to = new Date().toISOString();
    },

    showDate(value) {
      if (value === "today") {
        return moment().tz(this.accTimezone).format("MMM D");
      } else if (value === "month") {
        return `${moment().tz(this.accTimezone).startOf("month").format("MMM D")} - ${moment()
          .tz(this.accTimezone)
          .format("MMM D")}`;
      } else if (value === "-week") {
        return `${moment().tz(this.accTimezone).subtract(1, "week").format("MMM D")} - ${moment()
          .tz(this.accTimezone)
          .subtract(1, "day")
          .format("MMM D")}`;
      } else if (value === "-month") {
        return `${moment()
          .tz(this.accTimezone)
          .subtract(1, "month")
          .startOf("month")
          .format("MMM D")} - ${moment()
          .tz(this.accTimezone)
          .subtract(1, "month")
          .endOf("month")
          .format("MMM D")}`;
      }
    },

    selectDeselect(objRaw, obj, setFirst = true) {
      this.$set(objRaw, "checked", !objRaw.checked);
      if (setFirst) {
        this.$set(obj, "donation_id", true);
      }
      if (!objRaw.checked) {
        Object.keys(obj).forEach((key) => {
          this.$set(obj, key, true);
        });
      } else {
        Object.keys(obj).forEach((key) => {
          this.$set(obj, key, false);
        });
      }
    },

    selectDeselectReversed(objRaw, obj, setFirst = true) {
      this.$set(objRaw, "checked", !objRaw.checked);
      if (setFirst) {
        this.$set(obj, "donation_id", true);
      }
      if (objRaw.checked) {
        Object.keys(obj).forEach((key) => {
          this.$set(obj, key, false);
        });
      } else {
        Object.keys(obj).forEach((key) => {
          this.$set(obj, key, true);
        });
      }
    },

    initProcTracking: function () {
      this.interval = setInterval(this.checkProcProgress, 2000);
    },

    initTaskTracking: function () {
      this.checks = 0;
      this.interval = setInterval(this.checkTaskProgress, 2000);
    },

    checkProcProgress: function () {
      if (this.procID == 0) {
        return;
      }
      let orgId = localStorage.getItem("orgId");
      axios
        .get(urls.procProgressTracker.replace(":oid", orgId).replace(":id", this.procID))
        .then((response) => {
          this.progress = response.data.data.attributes.progress;
          if (response.data.data.attributes.finished) {
            clearInterval(this.interval);

            this.success = true;
            this.isLoading = false;
            if (response.data.data.attributes.meta != null) {
              this.exportLink = JSON.parse(response.data.data.attributes.meta);
            }
          } else {
            this.isLoading = true;
          }
        })
        .catch((e) => {
          this.isLoading = false;
          this.errors.push(e.response);
        });
    },

    checkTaskProgress: function () {
      if (!this.sqsMessageID) {
        return;
      }

      if (this.checks++ > this.maxChecks) {
        clearInterval(this.interval);
        this.isLoading = false;
        this.exportTaskError = "Donation export exceeded max allowed time.";
        return;
      }

      axios
        .get(urls.taskProgressTracker.replace(":id", this.sqsMessageID))
        .then((response) => {
          this.progress = (response.data.progress ? response.data.process : 0) * 100;
          if ("state" in response.data && response.data.state > 1) {
            clearInterval(this.interval);
            this.isLoading = false;
          }
          if (response.data.state === 2) {
            this.success = true;
            if (response.data.meta != null) {
              this.exportLink = JSON.parse(response.data.meta);
              this.exportFormat = JSON.parse(response.data.meta).format;
            }
          } else if (response.data.state > 2) {
            this.exportTaskError = response.data.notes;
          } else {
            this.isLoading = true;
          }
        })
        .catch((e) => {
          if ("response" in e && e.response.status === 400) {
            return;
          }
          this.isLoading = false;
          this.errors.push(e.response);
        });
    },

    openModal() {
      this.$root.$emit("bv::show::modal", "exportDonation");
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "exportDonation");
    },

    exportList() {
      this.$validator.validateAll().then((result) => {
        if (this.isEmailSet && !result) {
          return;
        }

        this.$refs.error.clearErrors();

        const columns = [];
        const dt = {
          unix_date_from: "",
          unix_date_to: "",
        };

        if (this.selectedDate === "today") {
          dt.unix_date_from = (moment().tz(this.accTimezone).startOf("day").format("x") / 1000) | 0;
          dt.unix_date_to = (moment().tz(this.accTimezone).endOf("day").format("x") / 1000) | 0;
        } else if (this.selectedDate === "month") {
          dt.unix_date_from =
            (moment().tz(this.accTimezone).startOf("month").format("x") / 1000) | 0;
          dt.unix_date_to = (moment().tz(this.accTimezone).format("x") / 1000) | 0;
        } else if (this.selectedDate === "-week") {
          dt.unix_date_from =
            (moment().tz(this.accTimezone).subtract(1, "week").format("x") / 1000) | 0;
          dt.unix_date_to =
            (moment().tz(this.accTimezone).subtract(1, "day").format("x") / 1000) | 0;
        } else if (this.selectedDate === "-month") {
          dt.unix_date_from =
            (moment().tz(this.accTimezone).subtract(1, "month").startOf("month").format("x") /
              1000) |
            0;
          dt.unix_date_to =
            (moment().tz(this.accTimezone).subtract(1, "month").endOf("month").format("x") / 1000) |
            0;
        } else if (this.selectedDate === "custom") {
          dt.unix_date_from =
            (moment(this.custom.from).tz(this.accTimezone).format("x") / 1000) | 0;
          dt.unix_date_to = (moment(this.custom.to).tz(this.accTimezone).format("x") / 1000) | 0;
        }

        const reports = {
          basic: "basicReport",
          advanced: "advancedReport",
          mediaPerformance: "mediaPerformanceReport",
          clearance: "clearanceReport",
          crmExport: "crmExportReport",
          igulLetova: "igulLetovaReport",
          admireExport: "admireExportReport",
          tessituraExport: "tessituraExportReport",
        };
        const reportToSend = this[reports[this.reportTypeSelected]];

        if (reportToSend) {
          for (let [key, value] of Object.entries(reportToSend)) {
            if (value) {
              columns.push(key);
            }
          }
        }

        // --> ? unused const 'params'
        // const params = {
        //   bank_name:
        //     this.filters.gatewayFilter.length > 0
        //       ? this.filters.gatewayFilter
        //       : "",
        //   recurring: this.filters.recurringFilter,
        //   status:
        //     this.filters.filterStatus.length > 0
        //       ? this.filters.filterStatus
        //       : "",
        //   q: this.filters.searchQ.length > 0 ? this.filters.searchQ : "",
        //   team_id: this.selectedTeam ? this.selectedTeam.value : "",
        //   include_all_gd_campaigns: this.allGdSubCampaigns ? 1 : ""
        // };
        // <--

        let settingArr = [];

        if (this.filters.gatewayFilter.length > 0) {
          settingArr.push(`bank_name=${this.filters.gatewayFilter}`);
        }

        if (this.filters.filterStatus.length > 0) {
          settingArr.push(`status=${this.filters.filterStatus}`);
        }

        if (this.filters.searchQ.length > 0) {
          settingArr.push(`q=${this.filters.searchQ}`);
        }

        if (this.selectedTeam) {
          settingArr.push(`team_id=${this.selectedTeam.value}`);
        }

        if (this.category === "givingday" && this.allGdSubCampaigns) {
          settingArr.push(`include_all_gd_campaigns=1`);
        }

        if (this.allLinkedCampaigns) {
          settingArr.push(`include_all_linked_campaigns=1`);
        }

        if (this.exportAsCSV) {
          settingArr.push(`format=csv`);
          settingArr.push(`tab=${this.tab}`);
        } else {
          settingArr.push(`format=xlsx`);
        }

        if (this.reportTypeSelected === "crmExportV2") {
          settingArr.push(`crm_2_format=1`);
        } else if (this.reportTypeSelected === "admireExport") {
          settingArr.push(`admire_format=1`);
          settingArr.push(`columns=${columns.join()}`);
        } else if (this.reportTypeSelected === "tessituraExport") {
          settingArr.push(`tessitura_format=1`);
        } else {
          settingArr.push(`columns=${columns.join()}`);
        }

        settingArr.push(`recurring=${this.filters.recurringFilter}`);
        settingArr.push(`unix_date_from=${dt.unix_date_from}`);
        settingArr.push(`unix_date_to=${dt.unix_date_to}`);

        settingArr.push(`locale=${this.lang}`);

        let setting = `&${settingArr.join("&")}`;

        this.isLoading = true;
        this.showFilters = false;

        axios
          .post(
            urls.exportCsvList
              .replace(":orgId", this.orgId)
              .replace(":campaignId", this.campaignid)
              .replace(":email", this.email) + setting
          )
          .then((response) => {
            this.exportFormat = response.data.format;
            this.procID = parseInt(response.data.proc_session_id); // set process id to track progress
            this.sqsMessageID = response.data.sqs_message_id;
          })
          .catch((e) => {
            console.log(e.response);
            this.isLoading = false;
            this.needEmail = true;
            this.errorResponse = e.response;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.rtl-justify-start {
  [dir="rtl"] & {
    display: grid;
    justify-items: start;
  }
}

#export-donation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  [dir="rtl"] & {
    p {
      text-align: right;
    }
  }
}

.loading-link-wrap {
  width: 100%;
  .form-group {
    @media only screen and (min-width: 768px) {
      width: 50%;
    }
  }
}

.select__select {
  position: relative;
  z-index: 1;
}

.loading-link,
.success-desc {
  text-align: center;
  width: 100%;
  p {
    width: 100%;
    text-align: center;
  }
}

.export-list {
  margin-top: 20px;
}

.filter-block {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(318.66px, 1fr));
  gap: 20px;
  &-wrapper {
    display: grid;
    [dir="rtl"] & {
      h4 {
        justify-self: start;
      }
    }
  }
  &-item {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    [dir="rtl"] & {
      h6 {
        justify-self: start;
      }
    }
    &--100 {
      grid-template-columns: 0.2fr 1fr;
      grid-column: 1/-1;
    }
  }
}

.modal-subtitle {
  font-size: 18px;
  font-family: "VitoExtended-Bold";
}

::v-deep .multiselect__tags {
  height: 48px;
  width: 100%;
  border: 1px solid #88bacc;
  border-radius: 24px;
  padding: 0 20px !important;
  color: black;
  cursor: pointer;
}
::v-deep .multiselect__single,
::v-deep .multiselect__placeholder {
  line-height: 48px;
  background: #0000;
  color: black;
}
::v-deep .multiselect__select {
  height: 48px;
  color: black;
}
::v-deep .multiselect__input,
::v-deep .multiselect__single,
::v-deep .multiselect__placeholder {
  height: 48px;
  margin: 0;
  line-height: 48px;
  background: transparent;
  color: initial;
  padding: 0;
  font-family: inherit;
  font-size: 16px;
}
::v-deep .multiselect__select:before {
  top: 58%;
  color: black;
}
::v-deep .multiselect__element {
  color: black;
}
.bottom-filters {
  padding-top: 20px;
  display: grid;
  grid-auto-flow: row;
  gap: 1.5rem;
  &__actions {
    display: grid;
    grid-auto-flow: row;
    gap: 1rem;
  }
  &__actions-title {
    display: grid;
    grid-auto-flow: row;
    [dir="rtl"] & {
      justify-items: start;
    }
  }
  .section-title {
    font-size: 18px;
    font-family: "VitoExtended-Bold";
    margin-bottom: 0;
    align-self: center;
    display: grid;
    justify-items: start;
    span {
      font-family: "VitoExtended-Light";
      font-size: 14px;
    }
  }
  .section-subtitle {
    font-family: "VitoExtended-Light";
    font-size: 14px;
    span {
      font-family: "VitoExtended-Bold";
      color: #017da3;
    }
  }
  .select-deselect {
    align-self: center;
    [dir="ltr"] & {
      margin-left: auto;
    }
    [dir="rtl"] & {
      margin-right: auto;
    }
    background: #ffffff;
    font-family: "VitoExtended-Light";
    font-size: 14px;
    color: #107598;
    text-decoration: underline;
    border: none;
    &:focus,
    &:active {
      outline: 0;
    }
  }
  .action-row {
    display: flex;
    align-items: center;
    width: 100%;
    .form-radio {
      width: 150px;
      padding: 4px 0;
      @media only screen and (min-width: 992px) {
        width: 200px;
      }
      &__label {
        font-family: "VitoExtended-Light";
        font-size: 14px;
        @media only screen and (min-width: 992px) {
          font-size: 16px;
        }
      }
    }
    .legend {
      [dir="ltr"] {
        flex-grow: 1;
      }
      font-family: "VitoExtended-Light";
      font-size: 14px;
      @media only screen and (min-width: 992px) {
        font-size: 16px;
      }
    }
  }
  .action-row-custom {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media only screen and (min-width: 992px) {
      flex-wrap: nowrap;
    }
    .form-radio {
      padding: 4px 0;
      width: 100%;
      @media only screen and (min-width: 992px) {
        max-width: 200px;
      }
      &__label {
        font-family: "VitoExtended-Light";
        font-size: 14px;
        @media only screen and (min-width: 992px) {
          font-size: 16px;
        }
      }
    }
    .from,
    .to {
      width: calc(50% - 5px);
      @media only screen and (min-width: 992px) {
        width: 100%;
        max-width: 200px;
      }
    }
    .from {
      [dir="ltr"] & {
        margin-right: 5px;
      }
      [dir="rtl"] & {
        margin-left: 5px;
      }
    }
    .to {
      [dir="ltr"] & {
        margin-left: 5px;
      }
      [dir="rtl"] & {
        margin-right: 5px;
      }
      &.danger {
        ::v-deep .form-control {
          border-color: #f26e66;
        }
      }
    }
  }
  .report-type {
    display: flex;
    align-items: center;
    &__legend {
      [dir="ltr"] & {
        margin-right: 15px;
      }
      [dir="rtl"] & {
        margin-left: 15px;
      }
      flex-shrink: 0;
      font-family: "VitoExtended-Light";
      font-size: 14px;
      @media only screen and (min-width: 992px) {
        font-size: 16px;
      }
    }
    .custom-select {
      width: 100%;
      max-width: 180px;
    }
  }
}
.error-message {
  margin-bottom: 15px;
  padding: 10px 15px;
  border: 1px solid #f9b7b3;
  font-family: "VitoExtended-Light";
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  background-color: rgba(249, 183, 179, 0.4);
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  cursor: pointer;
  background-color: $lr-100;
  border: 1px solid $lr-100;
}

::v-deep .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  opacity: 0.7;
  background-color: $lr-100;
  border: 1px solid $lr-100;
}

::v-deep .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem $lr-100-op25;
}

::v-deep .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border: 1px solid $lr-100;
}

::v-deep .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label {
  cursor: not-allowed;
}

::v-deep .custom-checkbox .custom-control-input:not(:disabled) ~ .custom-control-label {
  cursor: pointer;
}

::v-deep .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  color: gray;
  opacity: 0.7;
  background-color: $lr-100;
  border: 1px solid $lr-100;
}
</style>
