<template>
  <div class="">
    <b-row>
      <b-col cols="12">
        <h2 class="h1-like reports-info">{{ categoriesInfo[reportName].title }}</h2>
        <p class="reports-info">{{ categoriesInfo[reportName].description }}</p>

        <!-- Filters row -->
        <b-row class="mb-5">
          <b-col>
            <b-row class="mb-4">
              <b-col cols="12" md="4" v-if="!hideCampaignFilter">
                <b-form-group
                  id="reports-fieldset-select-campaign"
                  :label="cmpFiltersTitle"
                  label-for="reports-input-select-campaign"
                  class="form-unit--multiselect"
                >
                  <select-campaign
                    v-model="campaign"
                    id="select-campaign"
                    default-styles
                    get-full-campaign
                    general
                    :allow-empty="checkIfAllowEmpty"
                    :per-page="50"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4" v-if="reportName === 'past_campaign_donors_outreach'">
                <b-form-group
                  id="reports-fieldset-select-campaign"
                  :label="$t('reports.past_campaign_title', 'Select past campaign')"
                  class="form-unit--multiselect"
                >
                  <select-campaign
                    v-model="pastCampaignList"
                    id="select-campaign"
                    default-styles
                    general
                    :multiple="true"
                    :taggable="true"
                    :allow-empty="true"
                    track-by="id"
                    :per-page="50"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
                v-if="
                  reportName === 'campaign_by_hour_compare_report' ||
                  reportName === 'two_campaigns_donation_amount'
                "
              >
                <b-form-group
                  id="reports-fieldset-select-campaign"
                  :label="$t('reports.filters_compare_by_campaign2', 'Select campaign 2')"
                  class="form-unit--multiselect"
                >
                  <select-campaign
                    v-model="campaign2"
                    id="select-campaign"
                    default-styles
                    get-full-campaign
                    general
                    :allow-empty="checkIfAllowEmpty"
                    :per-page="50"
                  />
                </b-form-group>
              </b-col>
              <!-- Account filter for Stripe report -->
              <b-col
                cols="12"
                md="1"
                v-if="reportName === 'stripe_report'"
                class="align-self-center text-center mt-md-4"
                ><p><strong>OR</strong></p></b-col
              >
              <b-col cols="12" md="7" v-if="reportName === 'stripe_report'">
                <b-spinner v-if="loadingGateways" medium class="text-center mt-3"></b-spinner>
                <div v-if="!loadingGateways && hasAccess">
                  <b-form-group
                    id="reports-fieldset-select-stripe_account"
                    :label="$t('reports.filters_stripe_account', 'Stripe Account')"
                    label-for="reports-input-select-stripe_account"
                    class="form-unit--multiselect"
                  >
                    <multiselect
                      id="reports-input-select-stripe_account"
                      v-model="stripeAccount"
                      :options="stripeAccounts"
                      :custom-label="stripeAccountLabel"
                      :loading="loadingGateways"
                      :allow-empty="true"
                      :placeholder="
                        $t('reports.filters_select_stripe_account', 'Select Stripe Account')
                      "
                      :select-label="
                        $t('reports.select_stripe_account_select_label', 'Press enter to select')
                      "
                      :deselect-label="
                        $t('reports.select_stripe_account_deselect_label', 'Press enter to remove')
                      "
                      :selected-label="
                        $t('reports.select_stripe_account_selected_label', 'Selected')
                      "
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col v-if="!hideDateFillter" cols="12" md="4">
                <b-form-group
                  id="reports-fieldset-date-from"
                  :label="$t('reports.filters_date_from', 'Date from')"
                  label-for="reports-input-date-from"
                  :description="dateFromDescription"
                >
                  <input
                    v-if="reportName === 'stripe_report'"
                    type="date"
                    v-model="date.from"
                    id="reports-input-date-from"
                    class="mb-2 mb-sm-0 form-control"
                    :placeholder="$t('reports.filters_date_from', 'Date from')"
                  />
                  <datetime
                    v-else
                    type="date"
                    id="reports-input-date-from"
                    input-class="mb-2 mb-sm-0 form-control"
                    :placeholder="$t('reports.filters_date_from', 'Date from')"
                    v-model="date.from"
                    auto
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="!hideDateFillter" cols="12" md="4">
                <b-form-group
                  id="reports-fieldset-date-to"
                  :label="$t('reports.filters_date_to', 'Date to')"
                  label-for="reports-input-date-to"
                  :description="dateToDescription"
                >
                  <input
                    v-if="reportName === 'stripe_report'"
                    type="date"
                    id="reports-input-date-to"
                    class="mb-2 mb-sm-0 form-control"
                    :placeholder="$t('reports.filters_date_to', 'Date to')"
                    v-model="date.to"
                  />
                  <datetime
                    v-else
                    type="date"
                    id="reports-input-date-to"
                    input-class="mb-2 mb-sm-0 form-control"
                    :placeholder="$t('reports.filters_date_to', 'Date to')"
                    v-model="date.to"
                    auto
                  />
                </b-form-group>
              </b-col>
              <!-- Extra filters for Follow up report -->
              <b-col
                v-if="
                  ['pending_failed_donation_followups', 'past_campaign_donors_outreach'].includes(
                    reportName
                  )
                "
                cols="12"
              >
                <b-form-group #default="{ ariaDescribedby }">
                  <b-form-radio-group
                    v-model="checkDublucatesBy"
                    :options="checkDublucatesByOptions"
                    :aria-describedby="ariaDescribedby"
                    stacked
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>

              <!-- Extra filters for Foundation report -->
              <b-col cols="12" v-if="reportName === 'foundation_report'">
                <b-form-checkbox v-model="donationsOnly">
                  {{ $t("reports.fund_donation_only", "Donations only") }}
                </b-form-checkbox>
              </b-col>

              <!-- Extra filters for Foundation report -->
              <b-col cols="12" v-if="reportName === 'donation_failed'">
                <b-form-checkbox v-model="excludeZereoAmounts">
                  {{ $t("reports.exclude_zereo_amounts", "Exclude with zereo amounts") }}
                </b-form-checkbox>
              </b-col>

              <!-- Extra filters for Stripe report -->
              <b-col cols="12" v-if="reportName === 'stripe_report'">
                <b-form-group
                  size="lg"
                  :label="
                    $t(
                      'reports.filters_to_emails',
                      'Send report to one or more emails (the account owner will always receive the report email)'
                    )
                  "
                >
                  <b-form-tags
                    v-model="toEmails"
                    :placeholder="$t('reports.filters_to_emails_placeholder', 'Enter email(s)')"
                    add-on-change
                    tag-pills
                    tag-variant="primary"
                  ></b-form-tags>
                </b-form-group>
              </b-col>
              <b-col cols="4" v-if="reportName === 'team_management'">
                <b-form-group
                  id="reports-fieldset-select-campaign"
                  :label="$t('reports.filters_report_lang', 'Select report language')"
                  label-for="reports-input-select-campaign"
                  class="form-unit--multiselect"
                >
                  <multiselect
                    id="reports-input-select-campaign"
                    v-model="reportLang"
                    :options="reportLangOptions"
                    :allow-empty="true"
                    :placeholder="
                      $t('reports.filters_select_report_lang', 'Select report language')
                    "
                    :select-label="
                      $t('reports.select_report_lang_select_label', 'Press enter to select')
                    "
                    :deselect-label="
                      $t('reports.select_report_lang_deselect_label', 'Press enter to remove')
                    "
                    :selected-label="$t('reports.select_report_lang_selected_label', 'Selected')"
                    :custom-label="(option) => option.name"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <p v-if="reportName === 'stripe_report' && stripeAccountInfo">
              {{ $t("reports.selected_stripe_account", "Seleced stripe account") }}:
              {{ stripeAccountInfo }}
            </p>
            <div class="tw-flex tw-w-full" v-if="enableChart">
              <BarChart
                :reportName="reportName"
                :chartData="chartData"
                :chartOptions="chartOptions"
              />
            </div>
            <button
              v-if="!showOnlyOneBtn"
              @click="onReloadClick"
              class="bttn bttn--sm bttn--orange"
              :disabled="cantRefresh"
            >
              <b-spinner v-if="manualDisable" small class="mx-2" />
              {{ refreshButtonTKey }}
            </button>
            <template v-if="reportName === 'post_campaign_report'">
              <button
                @click="onPostCampaignReportGetToken"
                class="bttn bttn--sm bttn--orange mx-2"
                :disabled="cantRefresh"
              >
                <b-spinner v-if="manualDisable" small class="mx-2" />
                {{
                  $t(
                    "reports.post_campaign_report_token_btn",
                    "Generate Auth Code for Looker Studio"
                  )
                }}
              </button>
            </template>
            <button
              v-if="downloadBtnOnly"
              @click="download"
              class="bttn bttn--sm bttn--orange"
              :disabled="downloadLoading"
            >
              <b-spinner v-if="downloadLoading" small class="mr-2"></b-spinner>
              {{ $t("reports.download_btn", "Download") }}
            </button>

            <button
              v-if="sendToEmailBtnOnly"
              class="bttn bttn--sm bttn--orange"
              v-b-modal.reports-email-modal
            >
              {{ $t("reports.send_to_email_btn", "Send to email") }}
            </button>
          </b-col>
        </b-row>
      </b-col>

      <!-- Export Row -->
      <b-col v-if="!showOnlyOneBtn" cols="12">
        <div class="action flex-wrap">
          <div v-if="totalRecords" class="d-flex w-100 align-self-center align-items-center">
            <strong>
              {{ $t("reports.total_count_records", "Total records: {cnt}", { cnt: totalRecords }) }}
            </strong>
            <span class="mx-2"></span>
            <!-- hide it for now as its just irrelevant -->
            <!-- <strong v-if="stats">
              {{ $t("reports.total_amount", "Total amount: {amt}", { amt: stats.total_amount }) }}
            </strong>
            <span class="mx-2"></span> -->
            <b-button
              v-if="reportName === 'past_campaign_donors_outreach'"
              variant="link"
              @click="openIgnoreModal"
            >
              {{ $t("reports.ignore_list_btn", "Ignore list: {len}", { len: ignoreListLength }) }}
            </b-button>
          </div>
          <!-- remove export button for now -->
          <!-- <button
            v-show="true"
            class="bttn bttn--sm bttn--orange"
            :disabled="cantRefresh || reportsList.length === 0"
            @click="onExport()"
          >
            {{ $t("reports.export_btn") }}
          </button> -->
          <!-- <button
            class="bttn bttn--sm bttn--orange"
            :disabled="cantRefresh || reportsList.length === 0"
            @click="exportReports()"
          >
            {{ $t("reports.export_btn") }}
          </button> -->
          <button
            v-if="!hideSendToEmail"
            class="bttn bttn--sm bttn--orange"
            v-b-modal.reports-email-modal
            :disabled="cantRefresh || reportsList.length === 0"
          >
            {{ $t("reports.send_to_email_btn", "Send to email") }}
          </button>
          <button
            v-if="downloadBtnNextToSendToEmail"
            @click="download"
            class="bttn bttn--sm bttn--orange"
            :disabled="downloadLoading || reportsList.length === 0"
          >
            <b-spinner v-if="downloadLoading" small class="mr-2"></b-spinner>
            {{ $t("reports.download_btn", "Download") }}
          </button>
          <template v-if="reportName === 'past_campaign_donors_outreach'">
            <template v-if="rowSelection && selectedRows.length > 0">
              <button
                class="bttn bttn--sm bttn--orange min-w-260px"
                :disabled="reportsList.length === 0"
                @click="addToIgnoreList(selectedRows)"
              >
                {{
                  $t(
                    "reports.add__selected_to_ignore_list",
                    "Add {selected} email to ignore list ::: Add {selected} emails to ignore list",
                    {
                      selected: selectedRows.length,
                    },
                    selectedRows.length
                  )
                }}
              </button>
              <button
                class="bttn bttn--sm bttn--orange min-w-260px"
                :disabled="reportsList.length === 0"
                @click="openEmailMsgModal('email', 'donors_outreach', selectedRows)"
              >
                <b-icon-envelope-fill class="mx-2" />
                {{
                  $t(
                    "reports.send_email_to_selected",
                    "Send email to {selected} donor ::: Send email to {selected} donors",
                    {
                      selected: selectedRows.length,
                    },
                    selectedRows.length
                  )
                }}
              </button>
              <button
                v-if="teamSMSMessaging"
                class="bttn bttn--sm bttn--orange min-w-260px"
                :disabled="reportsList.length === 0"
                @click="openEmailMsgModal('sms', 'donors_outreach', selectedRows)"
              >
                <b-icon-chat-text-fill class="mx-2" />
                {{
                  $t(
                    "reports.send_sms_to_selected",
                    "Send sms to {selected} donor ::: Send sms to {selected} donors",
                    {
                      selected: selectedRows.length,
                    },
                    selectedRows.length
                  )
                }}
              </button>
            </template>
            <button
              class="bttn bttn--sm bttn--orange min-w-180px"
              :disabled="reportsList.length === 0"
              @click="openEmailMsgModal('email', 'donors_outreach', null)"
            >
              <b-icon-envelope-fill class="mx-2" />
              {{ $t("reports.send_email_to_all", "Send email to all") }}
            </button>
            <button
              v-if="teamSMSMessaging"
              class="bttn bttn--sm bttn--orange min-w-180px"
              :disabled="reportsList.length === 0"
              @click="openEmailMsgModal('sms', 'donors_outreach', null)"
            >
              <b-icon-chat-text-fill class="mx-2" />
              {{ $t("reports.send_sms_to_all", "Send sms to all") }}
            </button>
          </template>
        </div>
      </b-col>

      <b-col v-if="!showOnlyOneBtn" cols="12">
        <div v-if="reportName === 'post_campaign_report'">
          <LookerReportEmbed v-if="reloadData" :iframe-url="reloadData" />
        </div>
        <div v-else-if="reportName === 'stripe_report'">
          <StripeReport :loadingReports="loadingReports" :reportsList="reportsList" />
        </div>
        <b-table
          v-else
          bordered
          responsive
          :fields="headers"
          :items="reportsList"
          :busy="loadingReports"
          :selectable="rowSelection"
          no-local-sorting
          @row-clicked="onRowSelected"
          @sort-changed="onSortChanged"
        >
          <template #cell($selected)="{ index }">
            <template v-if="selectedRowsMap[`${page}_${index}`]">
              <b-form-checkbox
                :checked="true"
                :value="true"
                :unchecked-value="false"
                :disabled="true"
                class="!tw-flex tw-justify-center tw-items-center"
              />
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <b-form-checkbox
                :checked="false"
                :value="true"
                :unchecked-value="false"
                :disabled="true"
                class="!tw-flex tw-justify-center tw-items-center"
              />
              <span class="sr-only">Not selected</span>
            </template>
          </template>
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="mx-2">Loading...</strong>
            </div>
          </template>
          <template #cell(actions)="data">
            <div v-if="reportName === 'past_campaign_donors_outreach'" class="messsaging-btns">
              <button
                v-if="data.item.phone && teamSMSMessaging"
                class="bttn bttn-round edit"
                @click="openEmailMsgModal('sms', 'donors', data.item)"
              >
                <b-icon-chat-text-fill />
              </button>
              <button
                v-if="data.item.email"
                class="bttn bttn-round edit"
                @click="openEmailMsgModal('email', 'donors', data.item)"
              >
                <b-icon-envelope-fill />
              </button>
            </div>
          </template>
        </b-table>

        <b-pagination
          v-if="totalRecords && !hidePagination"
          v-model="page"
          :total-rows="totalRecords"
          :per-page="perPage"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Export Modal -->
    <reportsExport ref="exportReportModal" />
    <!-- Email modal -->

    <OrgDonorMessagingModal ref="donorMsgModal" />

    <IgnoreListModal ref="ignoreListModal" @close="onCloseIgnoreList" />

    <b-modal
      id="reports-email-modal"
      ref="emailModal"
      :title="$t('reports.email_modal_title')"
      @show="resetModal"
      @hidden="resetModal"
    >
      <form @submit.prevent="handleOk">
        <b-form-group
          :label="$t('reports.email_input_label')"
          label-for="email-input"
          label-class="form-group__label form-group__label--required"
        >
          <b-form-input
            id="email-input"
            v-model="email"
            name="email"
            :placeholder="$t('reports.email_input_placeholder')"
            v-validate="'required|email'"
          />
          <span v-show="vErrors.has('email')" class="v-help is-danger">{{
            $t("template.field_is_required")
          }}</span>
        </b-form-group>
      </form>
      <template v-slot:modal-footer="{ cancel }">
        <div class="modal-footer-inner">
          <button class="bttn bttn--lg bttn--blue" @click="cancel()">
            {{ $t("reports.send_to_email_btn_cancel", "Cancel") }}
          </button>
          <button class="bttn bttn--lg bttn--orange" @click="handleOk">
            {{ $t("reports.send_to_email_btn_ok", "Ok") }}
          </button>
        </div>
      </template>
    </b-modal>
    <!-- Link modal -->
    <b-modal ref="linkModal" :title="$t('reports.link_modal_title')" @hidden="linkModalHidden">
      <b-input-group class="align-items-center">
        <b-form-input v-model="link" ref="linkInput" readonly />
        <b-input-group-append>
          <button class="bttn bttn--lg bttn--orange" @click="copy">Copy</button>
        </b-input-group-append>
      </b-input-group>
      <span v-if="copied">Copied to Clipboard</span>
      <template v-slot:modal-footer="{ cancel }">
        <button class="bttn bttn--lg bttn--blue" @click="cancel">Close</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { reportNames, reportOptions } from "@/constants/reports";
import { notificationsMixin } from "@/mixins";
import reportsExport from "./reportsExport";
import { isFunction, isPlainObject } from "@/helpers/inspect";
import Multiselect from "vue-multiselect";
import OrgDonorMessagingModal from "@/views/dashboard/subcomponents/org-donor-messaging/MsgModal.vue";
import StripeReport from "./stripeReport.vue";
import LookerReportEmbed from "./LookerReportEmbed.vue";
import selectCampaign from "@/views/dashboard/subcomponents/selectCampaign.vue";
import IgnoreListModal from "./IgnoreListModal.vue";
import BarChart from "./BarChart.vue";
import { hourToPeriod } from "@/helpers/time";

export default {
  components: {
    Multiselect,
    reportsExport,
    OrgDonorMessagingModal,
    StripeReport,
    LookerReportEmbed,
    selectCampaign,
    IgnoreListModal,
    BarChart,
  },

  mixins: [notificationsMixin],

  data() {
    return {
      email: "",
      link: "",
      copied: false,
      sortBy: "",
      page: 1,
      perPage: 10,
      // Filters
      campaign: null,
      campaign2: null,
      stripeAccount: null,
      pastCampaignList: [],
      date: {},
      toEmails: [],
      checkDublucatesBy: "",
      checkDublucatesByOptions: [],
      donationsOnly: false,
      excludeZereoAmounts: false,
      manualDisable: false,
      selectedRowsMap: {},
      stripeAccountInfo: null,
      reloadData: null,

      ignoreListLength: 0,
      reportLangOptions: [],
      reportLang: null,
    };
  },

  created() {
    this.$store.dispatch("getGateways");
  },

  mounted() {
    if (this.$refs.ignoreListModal) {
      this.ignoreListLength = this.$refs.ignoreListModal.getListLength();
    }

    this.sortBy = reportOptions[this.reportName]?.defaultSortBy || "";
  },

  computed: {
    ...mapState({
      stripeAccounts(state) {
        let allGateways = state.gateways.gateways;
        let stripeAccountPrefix = "acct_";
        let stripeAccounts = [];

        for (const el of allGateways) {
          if (
            Object.keys(el.attributes.gateway_data).length &&
            el.attributes.gateway_data.account_number?.startsWith(stripeAccountPrefix) &&
            !stripeAccounts.some(
              (e) => e.account_number === el.attributes.gateway_data.account_number
            )
          ) {
            stripeAccounts.push(el.attributes.gateway_data);
          }
        }
        return stripeAccounts;
      },
      reportsList: (state) => state.reports.list || [],
      loadingReports: (state) => state.reports.loading,
      downloadLoading: (state) => state.reports.downloadLoading,
      totalRecords: (state) => state.reports.totalRecords,
      stats: (state) => state.reports.stats,
      exportSuccess: (state) => state.reports.exportSuccess,
      exportErrors: (state) => state.reports.errors,
      orgEmail: (state) => state.account.account.data.attributes.email,
      teamSMSMessaging: (state) => state.account.teamSMSMessaging,
      gateways: (state) => state.gateways.gateways,
      loadingGateways: (state) => state.gateways.loadingGateways,
      hasAccess: (state) => state.account.access.CanSeeOrganizationGatewayList,
      lang: (state) => state.account.account.data.attributes.lang,
    }),

    reportName() {
      return this.$route.params.name;
    },

    selectedRows() {
      return Object.values(this.selectedRowsMap);
    },

    dateFromDescription() {
      if (this.reportName === "stripe_report") {
        if (this.stripeAccount) {
          return this.$t(
            "report.desc_start_date_stripe",
            "Required for reports based on stripe account."
          );
        } else {
          return this.$t(
            "report.desc_start_date_campaign",
            "Optional, will default to date of first campaign donation."
          );
        }
      }

      if (this.reportName === "pending_failed_donation_followups") {
        return this.$t(
          "report.desc_start_date",
          "Optional, will default to date of first campaign donation."
        );
      }

      return "";
    },
    dateToDescription() {
      if (this.reportName === "stripe_report") {
        if (this.stripeAccount) {
          return this.$t("report.desc_end_date_stripe", "Optional, will default to today's date.");
        } else {
          return this.$t(
            "report.desc_end_date",
            "Optional, will default to date of last campaign donation."
          );
        }
      }

      if (this.reportName === "pending_failed_donation_followups") {
        return this.$t(
          "report.desc_end_date",
          "Optional, will default to date of last campaign donation."
        );
      }

      return "";
    },

    cantRefresh() {
      switch (this.reportName) {
        case "past_campaign_donors_outreach":
          return !(this.campaign && this.pastCampaignList.length > 0);
        case "stripe_report":
          return !(this.campaign || this.stripeAccount);
        case "pending_failed_donation_followups":
          return !this.campaign;
        case "utm":
          return this.manualDisable || !this.campaign;
        default:
          return this.manualDisable || !this.campaign || !this.date.from || !this.date.to;
      }
    },

    cmpFiltersTitle() {
      switch (this.reportName) {
        case "past_campaign_donors_outreach":
          return this.$t("reports.filters_outreach_title", "Select campaign");

        case "campaign_by_hour_compare_report":
        case "two_campaigns_donation_amount":
          return this.$t("reports.filters_compare_by_campaign1", "Select campaign 1");

        default:
          return this.$t("reports.filters_title", "Select campaign");
      }
    },

    categoriesInfo() {
      return Object.fromEntries(
        reportNames.map((name, index) => [
          name,
          {
            title: this.$t(`reports.category_title_${index + 1}`, reportOptions[name].label),
            description: this.$t(`reports.category_description_${index + 1}`, " "),
          },
        ])
      );
    },

    headers() {
      const opt = reportOptions[this.reportName];
      const getForrmater = (h) => {
        return opt?.formatters?.[h];
      };
      const getClass = (h) => {
        return opt?.classes?.[h];
      };

      return opt.headers.map((h, i) => ({
        key: opt?.headerKey?.(h) || `attributes.${h}`,
        label: this.$t(`reports.${h}`, opt?.headersTkFallbacks?.[i]),
        formatter: getForrmater(h) || this.formatter,
        class: getClass(h) || "",
        sortable: h?.sortable || false,
      }));
    },

    hidePagination() {
      const opt = reportOptions[this.reportName];

      return opt?.hidePagination;
    },

    hideDateFillter() {
      const opt = reportOptions[this.reportName];

      return opt?.hideDateFillter;
    },

    hideCampaignFilter() {
      const opt = reportOptions[this.reportName];

      return opt?.hideCampaignFilter;
    },

    showOnlyOneBtn() {
      return this.downloadBtnOnly || this.sendToEmailBtnOnly;
    },

    downloadBtnOnly() {
      const opt = reportOptions[this.reportName];

      return opt?.downloadBtnOnly;
    },

    downloadBtnNextToSendToEmail() {
      const opt = reportOptions[this.reportName];

      return opt?.downloadBtnNextToSendToEmail;
    },

    sendToEmailBtnOnly() {
      const opt = reportOptions[this.reportName];

      return opt?.sendToEmailBtnOnly;
    },

    hideSendToEmail() {
      const opt = reportOptions[this.reportName];

      return opt?.hideSendToEmail;
    },

    checkIfAllowEmpty() {
      return this.reportName === "stripe_report" ? true : false;
    },

    refreshButtonTKey() {
      const opt = reportOptions[this.reportName];

      return this.$t(...(opt?.refreshButtonTKey || ["reports.reload_btn", "Load"]));
    },

    rowSelection() {
      const opt = reportOptions[this.reportName];

      return opt?.rowSelection;
    },

    enableChart() {
      const opt = reportOptions[this.reportName];

      if (
        opt?.enableChart &&
        this.reportsList.length > 0 &&
        Object.keys(this.chartData.datasets).length > 0
      ) {
        return true;
      }

      return false;
    },

    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      };
    },

    chartData() {
      if (this.reportName === "campaign_by_hour_compare_report") {
        return {
          labels: this.reportsList.map((el) => hourToPeriod(el.attributes.time)),
          datasets: [
            {
              label: "Campaign 1",
              data: this.reportsList.map(
                (el) =>
                  el.attributes.online_donations_campaign1 +
                  el.attributes.offline_donations_campaign1
              ),
              backgroundColor: "#107598",
              borderColor: "#107598",
            },
            {
              label: "Campaign 2",
              data: this.reportsList.map(
                (el) =>
                  el.attributes.online_donations_campaign2 +
                  el.attributes.offline_donations_campaign2
              ),
              backgroundColor: "#0A8EFF",
              borderColor: "#0A8EFF",
            },
          ],
        };
      }

      return {
        labels: [],
        datasets: [],
      };
    },
  },

  watch: {
    $route() {
      this.resetReportsState();
    },

    reportName: {
      immediate: true,
      handler() {
        this.setFromToDateFromCampaign(this.campaign);

        switch (this.reportName) {
          case "past_campaign_donors_outreach":
            this.checkDublucatesBy = "email";
            this.checkDublucatesByOptions = [
              {
                text: this.$t("reports.filters_compare_by_email", "Compare by email"),
                value: "email",
              },
              {
                text: this.$t("reports.filters_compare_by_phone", "Compare by  phone"),
                value: "phone",
              },
              {
                text: this.$t(
                  "reports.filters_compare_by_email_or_phone",
                  "Compare by email OR phone"
                ),
                value: "email_or_phone",
              },
              {
                text: this.$t(
                  "reports.filters_compare_by_email_and_phone",
                  "Compare by email AND phone"
                ),
                value: "email_and_phone",
              },
            ];
            break;

          case "pending_failed_donation_followups":
            this.checkDublucatesBy = "email_phone";
            this.checkDublucatesByOptions = [
              {
                text: this.$t(
                  "reports.filters_check_dups_by_email_phone",
                  "Check duplicates by email and phone"
                ),
                value: "email_phone",
              },
              {
                text: this.$t(
                  "reports.filters_check_dups_by_email_or_phone",
                  "Check duplicates by email OR phone"
                ),
                value: "email_or_phone",
              },
              {
                text: this.$t("reports.filters_check_dups_by_email", "Check duplicates by email"),
                value: "email",
              },
              {
                text: this.$t("reports.filters_check_dups_by_phone", "Check duplicates by phone"),
                value: "phone",
              },
            ];
            this.date = {};
            break;

          case "team_management":
            this.reportLangOptions = [
              { id: "en", name: "English" },
              { id: "he", name: "Hebrew" },
            ];

            this.reportLang = this.reportLangOptions.find((e) => e.id === this.lang) || {
              id: "en",
              name: "English",
            };
            break;

          case "stripe_report":
            this.date = {};
            break;

          default:
            this.checkDublucatesBy = "";
            this.checkDublucatesByOptions = [];
        }
      },
    },

    page() {
      this.onReload();
    },

    stripeAccount(acct) {
      this.stripeAccountInfo = this.stripeAccountLabel(acct);
    },

    campaign(cmp) {
      this.setFromToDateFromCampaign(cmp);
    },

    exportErrors(errors) {
      errors = Array.isArray(errors) ? errors : [errors];
      this.$_notificationsMixin_fillWithErrors(errors);
    },

    exportSuccess(value) {
      if (value && value.data.attributes.finished) {
        this.$_notificationsMixin_makeToast(
          "Success!",
          this.$t("reports.export_success_msg", "Successful export"),
          "success"
        );

        this.link = value.data.attributes.link;

        if (this.link) {
          this.$refs.linkModal.show();
        }
      } else if (value && !value.data.attributes.finished) {
        this.$_notificationsMixin_makeToast(
          "Processing!",
          this.$t("reports.export_processing_msg", "Export request processing"),
          "warning"
        );
      }
    },
  },

  methods: {
    ...mapActions({
      getReports: "getReports",
      exportReport: "exportReport",
      generateFileReport: "generateFileReport",
      resetReportsState: "resetReportsState",
      getLookerStudioToken: "getLookerStudioToken",
    }),

    addToIgnoreList(items) {
      if (this.$refs.ignoreListModal) {
        const list = items.map((e) => e.email);
        this.$refs.ignoreListModal.addToList(list);
      }
    },

    openIgnoreModal() {
      if (this.$refs.ignoreListModal) {
        this.$refs.ignoreListModal.open();
      }
    },

    onCloseIgnoreList() {
      if (this.$refs.ignoreListModal) {
        this.ignoreListLength = this.$refs.ignoreListModal.getListLength();
      }
    },

    async onPostCampaignReportGetToken() {
      this.setManualDisable(true);

      try {
        const result = await navigator.permissions.query({ name: "clipboard-write" });
        if (result.state !== "granted") {
          this.setManualDisable(false);
          return;
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        this.setManualDisable(false);
        console.error(e);
      }

      try {
        const token = await this.getLookerStudioToken({
          reportName: this.reportName,
          params: { campaign_id: this.campaign.id },
        });
        await navigator.clipboard.writeText(token);
        this.$_notificationsMixin_makeToast(
          "Success!",
          this.$t(
            "reports.post_campaign_report_success_token",
            "Successfully generated token and copied to clipboard!"
          ),
          "success"
        );
      } catch (e) {
        return this.$_notificationsMixin_handleCatch(e);
      } finally {
        this.setManualDisable(false);
      }
    },

    onSortChanged(data) {
      const opt = reportOptions[this.reportName];

      if (opt?.getSortBy) {
        this.sortBy = opt?.getSortBy(data.sortBy, data.sortDesc);
      } else {
        this.sortBy = `${data.sortBy}_${data.sortDesc ? "desc" : "asc"}`;
      }

      if (this.reportsList.length > 0) {
        this.onReload();
      }
    },

    onRowSelected(items, index) {
      if (!this.rowSelection) return;

      const key = `${this.page}_${index}`;

      if (this.selectedRowsMap[key]) {
        this.$delete(this.selectedRowsMap, key);
        return;
      }

      this.$set(this.selectedRowsMap, key, items);
    },

    getParams() {
      let params = {
        campaign_id: this.campaign.id,
        page: this.page,
        per_page: this.perPage,
        donations_only: this.donationsOnly ? "1" : null,
        exclude_zereo_amounts: this.excludeZereoAmounts ? "1" : null,
        sort_by: this.sortBy || null,
      };

      switch (this.reportName) {
        case "past_campaign_donors_outreach":
          params = {
            ...params,
            compare_by: this.checkDublucatesBy,
            past_campaign_id: this.pastCampaignList.map((c) => Number(c.id)),
          };
          break;

        case "campaign_by_hour_compare_report":
        case "two_campaigns_donation_amount":
          params = {
            campaign_id_list: [this.campaign.id, this.campaign2.id].join(","),
          };
          break;

        case "pending_failed_donation_followups":
          params = {
            ...params,
            option: this.checkDublucatesBy,
            date_from: this.date.from,
            date_to: this.date.to,
          };
          break;

        case "team_management":
          params = {
            ...params,
            lang: this.reportLang?.id || "en",
          };
          break;

        default:
          params = {
            ...params,
            date_from: this.date.from,
            date_to: this.date.to,
          };
          break;
      }

      return params;
    },

    download() {
      const params = this.getParams();

      const a = document.createElement("a");
      a.target = "_blank";
      a.download = this.reportName;
      this.generateFileReport({ reportName: this.reportName, params })
        .then((data) => {
          const l = data.tmp_url || data.link || data.file || data.data.attributes.link;
          a.href = l;
          a.click();
          a.remove();
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },

    copy() {
      this.$refs.linkInput.select();
      document.execCommand("copy");
      this.copied = true;
    },

    linkModalHidden() {
      this.link = "";
      this.copied = false;
    },

    setFromToDateFromCampaign(cmp) {
      if (
        cmp &&
        !["stripe_report", "pending_failed_donation_followups", "utm"].includes(this.reportName) &&
        (!this.date.from || !this.date.to)
      ) {
        this.date.from = new Date(cmp.attributes.start_date * 1000).toISOString();
        this.date.to = new Date(cmp.attributes.end_date * 1000).toISOString();
      }
    },

    formatter(value, key, item) {
      if (!this.campaign) return value;

      const { currency_sign } = this.campaign.attributes;

      if (
        key === "attributes.sum_real" ||
        key === "attributes.average_real" ||
        key === "attributes.sum_total" ||
        key === "attributes.average_total"
      ) {
        return `${currency_sign} ${value.toFixed(2)}`;
      }

      if (item.attributes?.email_phone) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const [email, phone] = item.attributes?.email_phone?.split("/");

        if (key === "attributes.email") {
          return email;
        }

        if (key === "attributes.phone") {
          return phone;
        }
      }

      return value;
    },

    stripeAccountLabel(acct) {
      let accountLabel =
        acct?.account_number &&
        `[${acct.country} - ${acct.currency.toUpperCase()}] ${acct.account_number} - ${
          acct.legal_name
        } (${acct.bank_name}  ...${acct.bank_account_last_4})`;
      return accountLabel;
    },

    setManualDisable(value) {
      this.manualDisable = value;
    },

    setReloadData(value) {
      this.reloadData = value;
    },

    onReloadClick() {
      this.selectedRowsMap = {};
      this.onReload();
    },

    async onReload() {
      const opt = reportOptions[this.reportName];

      let fetchOptions = {
        date: this.date,
        page: this.page,
        perPage: this.perPage,
        sortBy: this.sortBy,
        store: this.$store,
        setManualDisable: this.setManualDisable,
        toEmails: this.toEmails,
        setReloadData: this.setReloadData,
        excludeZereoAmounts: this.excludeZereoAmounts,
      };

      if (this.reportName === "stripe_report") {
        fetchOptions.cId = this.campaign?.id ? this.campaign.id : null;
        fetchOptions.acctList = this.stripeAccount?.account_number;
      } else {
        fetchOptions.cId = this.campaign.id;
      }

      if (isFunction(opt?.fetchFunction)) {
        return await opt.fetchFunction.call(null, fetchOptions);
      }

      this.getClassicReports();
    },

    getClassicReports() {
      const params = this.getParams();

      this.getReports({ reportName: this.reportName, params });
    },

    exportReports() {
      this.$refs.exportReportModal.openModal(this.campaign.id);
    },

    onExport(email) {
      const params = {
        option: this.checkDublucatesBy,
        campaign_id: this.campaign?.id || null,
        date_from: this.date?.from || null,
        date_to: this.date?.to || null,
        email,
      };

      this.exportReport({ reportName: this.reportName, params });
      this.$refs.emailModal.hide();
    },

    resetModal() {
      this.email = this.orgEmail;
    },

    handleOk() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.onExport(this.email);
        }
      });
    },

    openEmailMsgModal(delivery, audience, donorOrDonors) {
      const p = this.getParams();
      let params = {};

      switch (audience) {
        case "donors": {
          params = {
            audience,
            delivery,
            audienceParams: {
              campaign_id: Number(p.campaign_id),
              past_campaign_id_list: p.past_campaign_id,
            },
          };

          if (donorOrDonors) {
            if (isPlainObject(donorOrDonors)) {
              params.donor = { id: donorOrDonors.id, attributes: donorOrDonors };
            } else if (Array.isArray(donorOrDonors)) {
              params.donors = donorOrDonors;
            }
          }

          break;
        }

        case "donors_outreach": {
          params = {
            delivery,
            audience,
            audienceParams: {
              campaign_id: Number(p.campaign_id),
              past_campaign_id_list: p.past_campaign_id,
              compare_by: p.compare_by,
            },
          };

          if (this.$refs.ignoreListModal) {
            params.audienceParams.exclude_emails = this.$refs.ignoreListModal.getList();
          }

          if (donorOrDonors) {
            if (isPlainObject(donorOrDonors)) {
              params.donor = { id: donorOrDonors.id, attributes: donorOrDonors };
            } else if (Array.isArray(donorOrDonors)) {
              params.donors = donorOrDonors;
            }
          }

          break;
        }
      }

      this.$refs.donorMsgModal.open(params);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
.action {
  border-top: 1px solid #ddd;
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.min-w-260px {
  min-width: 260px;
}
.min-w-180px {
  min-width: 180px;
}
::v-deep {
  .multiselect {
    cursor: pointer;
    @media only screen and (min-width: 768px) {
      // width: 15rem;
    }
    @media only screen and (max-width: 768px) {
      margin-bottom: 0.5rem;
    }
  }

  .multiselect__single,
  .multiselect__placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .vdatetime {
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    &-input {
      cursor: pointer;
    }
  }
}

.report-block {
  @media only screen and (max-width: 768px) {
    margin-top: 0.7rem;
  }
}

::v-deep .vdatetime-popup {
  backface-visibility: hidden;
  transform: translate3d(-50%, calc(-50% - 0.5px), 0px);
  &__header {
    background-color: $bb-100;
  }
}

::v-deep .vdatetime-calendar {
  &__month__day {
    &--selected {
      &:hover {
        > span > span {
          background-color: $bb-80;
        }
      }
      > span > span {
        background-color: $bb-100;
      }
    }
  }
}

::v-deep .vdatetime-year-picker__item {
  &--selected {
    color: $bb-100;
  }
}

::v-deep .vdatetime-time-picker__item {
  &--selected {
    color: $bb-100;
  }
}
::v-deep .modal-footer-inner {
  display: flex;
  justify-content: space-between;
}
.he,
.ar {
  .reports-info {
    text-align: right;
  }
}

:deep(.wp-pre-line) {
  white-space: pre-line;
}
</style>
