<template>
  <div class="donor-log-in-page">
    <div class="donor-wrapper">
      <main-header>
        <template #auth-actions>
          <div class="sign__mode w-100 h-100">
            <p class="sign__helper">{{ $t("template.dont_have_account") }}</p>
            <a href="/donor-sign-up" class="bttn bttn--sm bttn--orange">{{
              $t("template.get_started")
            }}</a>
          </div>
        </template>
      </main-header>
      <div class="container-fluid d-flex align-items-center justify-content-between main">
        <div class="col">
          <form>
            <unified-log-in-form
              ref="loginmode"
              v-on:keyup.enter="proceedLogin"
              @submit.prevent="proceedLogin"
              @update-step="updateMode"
            >
              <div slot="action_buttons">
                <button
                  v-if="mode === 0"
                  @click.prevent="proceedLogin"
                  class="bttn bttn--lg bttn--orange"
                  :disabled="!loading"
                >
                  {{ $t("template.sign_in_btn", "Sign In") }}
                </button>
                <!-- type="submit" -->
                <button
                  v-if="mode === 1 || mode === 4"
                  class="bttn bttn--lg bttn--blue"
                  @click.prevent="stepBack(0)"
                >
                  {{ $t("template.log_in_back", "Back") }}
                </button>

                <button
                  v-if="mode === 1 || mode === 4"
                  class="bttn bttn--lg bttn--orange"
                  @click.prevent="resetPassword"
                >
                  {{ $t("template.log_in_reset_password", "Reset password") }}
                </button>
                <button
                  v-if="mode === 2"
                  class="bttn bttn--lg bttn--blue"
                  @click.prevent="stepBack(0)"
                >
                  {{ $t("template.log_in_back_to_login", "Back To Login") }}
                </button>
              </div>
            </unified-log-in-form>
          </form>
        </div>
        <div class="col text-center">
          <img
            src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1699652522_6163303936383766656237626336653731373031363839333334366130343135_31363939363532353232.svg"
          />
        </div>
      </div>
    </div>
    <show-settings-confirm-msg />
  </div>
</template>

<script>
import mainHeader from "@/views/donor-dashboard/common-components/main-header.vue";
import unifiedLogInForm from "@/components/unified-log-in-form.vue";
import showSettingsConfirmMsg from "@/views/donor-dashboard/modals/show-settings-confirm-msg.vue";

export default {
  data() {
    return {
      mode: 0,
      loading: true,
    };
  },
  components: {
    "main-header": mainHeader,
    unifiedLogInForm,
    showSettingsConfirmMsg,
  },
  methods: {
    proceedLogin() {
      this.$refs.loginmode.loginUser();
    },
    updateMode(n) {
      this.mode = n;
      if (n > 1) {
        this.$refs.loginmode.stepTo(n);
      }
    },
    stepBack(n) {
      this.mode = n;
      this.$refs.loginmode.stepTo(n);
    },
    resetPassword() {
      this.$refs.loginmode.proceedResetPassword();
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/_vars.scss";
.donor-log-in-page {
  line-height: 1.2;
  min-height: 100vh;
  overflow: auto;
  background: #fff
    url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/main-bg.png")
    top center no-repeat;
  background-size: 100% auto;
  color: $fd;
  .donor-wrapper {
    padding-top: 0;
    min-width: 320px;
  }
  .bttn--orange {
    background-color: #ff5555;
    &:hover {
      opacity: 0.85;
    }
  }
  .col {
    width: 50%;
    img {
      max-width: 100%;
    }
  }
}
.container-fluid {
  max-width: 1150px;
  margin: 0 auto;
}
.main {
  margin-bottom: 70px;
}
@media all and (max-width: 767px) {
  .main {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .donor-log-in-page {
    .col {
      width: 100%;
      flex-basis: auto;
      padding: 0;
      &:first-child {
        margin-bottom: 50px;
      }
    }
    .donor-sign-in .text-center p {
      font-size: 15px;
    }
  }
}
</style>
