<template>
  <div>
    <b-skeleton-wrapper :loading="checkSmsSettingInOrgLoading">
      <template #loading>
        <b-skeleton type="button"></b-skeleton>
      </template>
      <button v-if="team_sms_messaging" class="bttn bttn--sm bttn--orange" @click="open">
        {{ $t("dashboard.open_sms_message", "Send SMS") }}
      </button>
    </b-skeleton-wrapper>
    <b-modal
      id="SMSMessageModal"
      ref="SMSMessageModal"
      size="lg"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <template #modal-header>
        <div>
          <h2>{{ $t(`dashboard.sms_message_modal_title`) }}</h2>
          <SMSCountLimitInfo />
        </div>
      </template>
      <error :response.sync="error" :alert="true"></error>
      <section v-if="step === 1">
        <form id="SMSMessageModalForm1" @submit.prevent="submit1">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label
                    class="form-group__label"
                    :class="{ 'form-group__label--required': selectedTeamsIsEmpty }"
                  >
                    {{ $t("dashboard.sms_message_which_team") }}
                  </label>
                </template>

                <div v-if="importGroup">
                  <b>
                    {{ $t("dashboard.message_to_imported_group", "The Imported group") }}
                  </b>
                </div>
                <div v-else>
                  <div class="mb-2">
                    <b-form-select
                      :required="selectedTeamsIsEmpty"
                      v-model="targetBucket"
                      :options="options"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null">
                          {{ $t("dashboard.sms_message_please_select_option") }}
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </div>

                  <b-row
                    v-if="targetBucket === 'has_goal_not_reached_between_custom_pct_range'"
                    class="pt-2"
                  >
                    <b-col>
                      <b-form-group label="From">
                        <b-form-input
                          v-model.number="formPayload.custom_pct_range.from"
                          type="text"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          min="0"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group label="To">
                        <b-form-input
                          v-model.number="formPayload.custom_pct_range.to"
                          type="text"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          min="0"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-form-group>
                    <template v-slot:label>
                      <label class="form-group__label">
                        {{ $t("edit_campaign.donation_level_team", "Teams") }}
                      </label>
                    </template>
                    <multiselect-search
                      v-model="selectedTeams"
                      :open-prefetch="true"
                      :search-url="getSearchUrl"
                      :search-options="searchOptions"
                      :multiselectClass="{ cms: true }"
                      :multiple="true"
                      :taggable="true"
                    >
                      <template #no-options>
                        {{
                          $t(
                            "dashboard.team_search_no_options",
                            "Please enter 1 or more characters"
                          )
                        }}
                      </template>
                      <template #no-more-infinite>
                        {{ $t("dashboard.team_search_no_more", "No more teams") }}
                      </template>
                    </multiselect-search>
                  </b-form-group>

                  <div v-if="selectedTeams.length" class="pl-4 mt-2">
                    <b-form-checkbox v-model="formPayload.include_children_teams">
                      {{ $t("dashboard.message_include_children_teams", "Include children teams") }}
                    </b-form-checkbox>

                    <b-form-checkbox v-model="formPayload.include_grandchildren_teams">
                      {{
                        $t(
                          "dashboard.message_include_grandchildren_teams",
                          "Include grandchildren teams"
                        )
                      }}
                    </b-form-checkbox>

                    <b-form-checkbox
                      v-if="
                        formPayload.include_grandchildren_teams ||
                        formPayload.include_children_teams
                      "
                      v-model="formPayload.exclude_parent_teams"
                    >
                      {{
                        $t(
                          "dashboard.message_exclude_parent_teams",
                          "do not send to the parent teams itself"
                        )
                      }}
                    </b-form-checkbox>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required">{{
                    $t("dashboard.sms_message_data")
                  }}</label>
                </template>
                <quill-editor-variables
                  simple
                  emitWithChangeSource
                  v-model="formPayload.message"
                  :tags="tags"
                >
                  <template #btn-content>
                    {{ $t("edit_campaign.sms_template_edit_title", "Description") }}
                  </template>
                  <template #description>
                    {{
                      $t(
                        "edit_campaign.sms_template_var_description",
                        "You can use these placeholders (variables) which will be replaced with actual donor-related values: [FIRST_NAME] = The donor’s First Name"
                      )
                    }}
                  </template>
                  <template #tags-full-text="{ tagsFull }">
                    {{
                      tagsFull
                        ? $t("edit_campaign.sms_template_show_description", "Show")
                        : $t("edit_campaign.sms_template_hide_description", "Hide")
                    }}
                  </template>
                  <template #between-tags-and-editor="{ value }">
                    <org-message-templates
                      type="sms"
                      :templateText="value"
                      @update:templateText="(v) => (formPayload.message = v)"
                    />
                  </template>
                </quill-editor-variables>
                <template #description>
                  <span :class="{ 'text-danger': charactersLeft < 0 }">
                    {{ `${charactersLeftText} characters left` }}
                  </span>
                </template>
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </section>
      <section v-else-if="step === 2">
        <form id="SMSMessageModalForm2" @submit.prevent="submit2">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label">
                    <b>{{ $t("dashboard.sms_message_step2_you_are_about") }}</b>
                    &nbsp;
                    <u>{{ targetTeamBucketText }}</u>
                  </label>
                </template>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label">
                    <b>{{ $t("dashboard.sms_message_step2_message") }}</b>
                  </label>
                </template>
                <quill-editor-variables simple v-model="formPayload.message" :disabled="true" />
                <template #description>
                  <span :class="{ 'text-danger': charactersLeft < 0 }">
                    {{ `${charactersLeftText} characters left` }}
                  </span>
                </template>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="smsAllowedCountry.length > 0" cols="12">
              <b-form-group>
                <label class="form-group__label">
                  <b-alert show variant="warning">{{
                    $t("dashboard.sms_message_step2_allowed_countries")
                  }}</b-alert>
                </label>
                <div class="d-flex flex-wrap">
                  <div
                    v-for="(country, index) in smsAllowedCountry"
                    :key="index"
                    class="d-flex flex-column align-items-center mr-3"
                  >
                    <h4 class="fs-1">
                      <b-badge class="mb-1" variant="success">
                        {{ country.country }} +{{ country.code }}
                      </b-badge>
                    </h4>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col v-else>
              <b-alert show variant="danger">{{
                $t("dashboard.sms_message_step2_no_availeble_countries")
              }}</b-alert>
            </b-col>
          </b-row>
        </form>
      </section>
      <div slot="modal-footer" class="d-flex flex-row">
        <button class="mx-2 bttn bttn--lg bttn--blue" @click="close" type="button">
          {{ $t(`dashboard.cancel_btn`) }}
        </button>

        <button class="mx-2 bttn bttn--lg bttn--blue" @click="back" type="button" v-if="step === 2">
          {{ $t(`dashboard.sms_message_modal_back`) }}
        </button>

        <div class="ml-auto" v-if="step === 1">
          <button id="submit-btn" class="bttn bttn--lg bttn--orange" form="SMSMessageModalForm1">
            {{ $t(`dashboard.sms_message_modal_save`) }}
          </button>

          <b-tooltip v-if="charactersLeft < 0" target="submit-btn" variant="danger">
            {{ $t(`dashboard.sms_message_too_long`, "Message too long") }}
          </b-tooltip>
        </div>

        <b-overlay
          :show="loading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="ml-auto d-inline-block"
          v-else-if="step === 2"
        >
          <button
            type="submit"
            class="bttn bttn--lg bttn--orange"
            form="SMSMessageModalForm2"
            :disabled="loading || smsAllowedCountry?.length == 0"
          >
            {{ $t(`dashboard.sms_message_modal_send`) }}
          </button>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
import error from "@/components/error-message.vue";
import { mapState, mapActions } from "vuex";
import { notificationsMixin } from "@/mixins";
import quillEditorVariables from "@/common-components/ui-elements/quill-editor-variables.vue";
import MultiselectSearch from "@/components/multiselect-search.vue";
import urls from "@/constants/urls";
import OrgMessageTemplates from "@/components/org-message-templates.vue";
import SMSCountLimitInfo from "@/views/dashboard/subcomponents/messaging/smsCountLimitInfo.vue";

export default {
  mixins: [notificationsMixin],
  components: {
    error,
    quillEditorVariables,
    MultiselectSearch,
    OrgMessageTemplates,
    SMSCountLimitInfo,
  },
  data() {
    return {
      step: 1,
      error: null,
      formPayload: {
        target_bucket: null,
        custom_pct_range: {
          from: 0,
          to: 100,
        },
        message: "",
        group: "",
        include_children_teams: false,
        include_grandchildren_teams: false,
        exclude_parent_teams: false,
      },
      importGroup: false,
      targetBucket: null,
      selectedTeams: [],
      options: [
        {
          text: this.$t("dashboard.sms_message_options_all_teams"),
          value: "all",
        },
        {
          text: this.$t("dashboard.sms_message_options_all_teams_no_goal_reach"),
          value: "has_goal_not_reached",
        },
        {
          text: this.$t("dashboard.sms_message_options_teams_no_goal"),
          value: "has_no_goal",
        },
        {
          text: this.$t(
            "dashboard.sms_message_options_teams_no_donations",
            "All teams without donations"
          ),
          value: "has_no_donations",
        },
        {
          text: this.$t(
            "dashboard.sms_message_options_teams_with_donations",
            "All teams with donations"
          ),
          value: "has_donations",
        },
        {
          label: this.$t("dashboard.sms_message_options_teams_goal_no_reach"),
          options: [
            {
              text: "<=25%",
              value: "has_goal_not_reached_below_25pct",
            },
            {
              text: "<=50%",
              value: "has_goal_not_reached_below_50pct",
            },
            {
              text: "<=75%",
              value: "has_goal_not_reached_below_75pct",
            },
            {
              text: "<=100%",
              value: "has_goal_not_reached_below_100pct",
            },
          ],
        },
        {
          label: this.$t(
            "dashboard.sms_message_options_teams_goal_between",
            "Teams with progress between below point:"
          ),
          options: [
            {
              text: "0% - 25%",
              value: "has_goal_not_reached_between_0_25pct",
            },
            {
              text: "25% - 50%",
              value: "has_goal_not_reached_between_25_50pct",
            },
            {
              text: "50% - 75%",
              value: "has_goal_not_reached_between_50_75pct",
            },
            {
              text: "75% - 100%",
              value: "has_goal_not_reached_between_75_100pct",
            },
          ],
        },
        {
          text: this.$t(
            "dashboard.sms_message_options_between_custom_pct_range",
            "Custom percentage range"
          ),
          value: "has_goal_not_reached_between_custom_pct_range",
        },
        {
          text: this.$t("dashboard.email_message_options_hidden_teams", "Only hidden teams"),
          value: "only_hidden_teams",
        },
        {
          text: this.$t("dashboard.email_message_options_shown_teams", "Only shown teams"),
          value: "only_shown_teams",
        },
      ],
    };
  },
  mounted() {
    this.checkSmsSettingInOrg({ oId: this.oId });
  },
  computed: {
    ...mapState({
      cId: (state) => state.dashboard.selectedCampaign,
      loading: (state) => state.messaging.loading.saveMessaging_sms,
      team_sms_messaging: (state) => state.messaging.team_sms_messaging,
      checkSmsSettingInOrgLoading: (state) => state.messaging.loading.checkSmsSettingInOrg,
      sms_allowed_countries: (state) => state.messaging.sms_allowed_countries,
      metas: (state) => state.campaign.metas,
    }),
    oId() {
      return localStorage.getItem("orgId");
    },
    maxLength() {
      const campaignSmsSettings = this.metas["campaign_sms_settings"] || null;

      if (campaignSmsSettings?.value && campaignSmsSettings?.sms_text_len_limit) {
        return campaignSmsSettings.sms_text_len_limit;
      }

      return 160;
    },
    msgLength() {
      return new Blob([this.formPayload.message]).size;
    },
    charactersLeft() {
      return this.maxLength - this.msgLength;
    },
    charactersLeftText() {
      const l = this.msgLength;
      return `(${l} / ${this.maxLength}) ${this.charactersLeft}`;
    },
    targetTeamBucketText() {
      if (this.importGroup) {
        return this.$t("dashboard.message_to_imported_group", "The Imported group");
      }

      let text = null;
      this.options.forEach((v) => {
        if (v.options) {
          return v.options.forEach((o) => {
            o.value === this.targetBucket && (text = `${v.label} ${o.text}`);
          });
        } else {
          if (v.value === this.targetBucket) {
            switch (this.targetBucket) {
              case "has_goal_not_reached_between_custom_pct_range": {
                const { from, to } = this.formPayload.custom_pct_range;
                text = `${this.$t(
                  "dashboard.sms_message_options_teams_goal_between",
                  "Teams with progress between below point:"
                )} ${from}% - ${to}%`;
                break;
              }

              default:
                text = v.text;
                break;
            }
          }
        }
      });

      if (this.selectedTeams.length > 0) {
        text = `${(text || "All").replace(
          "All",
          this.selectedTeams.map((t) => this.searchOptions.customLabel(t)).join(", ")
        )}`;
      }

      return text;
    },
    getSearchUrl() {
      const cID = this.cId;
      const orgID = localStorage.getItem("orgId");

      if (Number(cID) === 0) {
        throw new Error("getSearchUrl sms msg campaign id is 0");
      }

      return urls.apiTeams.replace(":orgId", orgID).replace(":campaignId", cID);
    },

    searchOptions() {
      return {
        placeholder: this.$t("dashboard.sms_team_search_label_placeholder", "Add individual teams"),
        changeParams: ({ query, page, per_page: pp }) => ({
          q: query,
          page: page,
          limit: pp,
        }),
        customLabel: (e) => `[${e.id}] ${e.name}`,
        processResults: (data) =>
          data.map((el) => ({
            id: el.id,
            ...el.attributes,
          })),
      };
    },

    tags() {
      let options = [
        {
          value: "[TEAM_ID]",
          text: this.$t("dashboard.email_message_tag_team_id"),
        },
        {
          value: "[SHORTLINK]",
          text: this.$t("dashboard.email_message_tag_team_shortlink", "Team shortlink"),
        },
        {
          value: "[TEAM_NAME]",
          text: this.$t("dashboard.email_message_tag_team_name"),
        },
        {
          value: "[GOAL]",
          text: this.$t("dashboard.email_message_tag_goal"),
        },
        {
          value: "[LEADER_NAME]",
          text: this.$t("dashboard.email_message_tag_leader_name"),
        },
        {
          value: "[TEAM_FULL_LINK]",
          text: this.$t("dashboard.email_message_tag_team_full_link"),
        },
        {
          value: "[RAISED_AMOUNT]",
          text: this.$t("dashboard.email_message_tag_raised_amount"),
        },
        {
          value: "[DONOR_COUNT]",
          text: this.$t("dashboard.email_message_tag_donor_count"),
        },
      ];

      const allowTeamUpdateByPublicToken =
        this.metas["allow_team_update_by_public_token"]?.value || false;

      if (allowTeamUpdateByPublicToken) {
        options = [
          ...options,
          {
            value: "[TOKEN]",
            text: this.$t("dashboard.email_message_tag_token"),
          },
        ];
      }

      return options;
    },
    selectedTeamsIsEmpty() {
      return this.selectedTeams.length === 0;
    },
    smsAllowedCountry() {
      try {
        return JSON.parse(this.sms_allowed_countries);
      } catch {
        return [];
      }
    },
  },
  watch: {
    selectedTeams(l) {
      if (l?.length === 0) {
        this.formPayload.include_children_teams = false;
        this.formPayload.include_grandchildren_teams = false;
      }
    },
  },
  methods: {
    ...mapActions({
      saveSmsMessage: "messaging/save",
      checkSmsSettingInOrg: "messaging/checkSmsSettingInOrg",
    }),
    close() {
      this.step = 1;
      this.targetBucket = null;
      this.selectedTeams = [];
      this.formPayload = {
        target_bucket: null,
        custom_pct_range: {
          from: 0,
          to: 100,
        },
        message: "",
        group: "",
        include_children_teams: false,
        include_grandchildren_teams: false,
        exclude_parent_teams: false,
      };
      this.importGroup = false;

      this.$refs.SMSMessageModal.hide();
    },
    open({ team, importGroup } = {}) {
      if (importGroup) {
        this.selected = "";
        this.importGroup = importGroup;
      }

      if (team) {
        this.selectedTeams = [
          {
            id: team.id,
            ...team.attributes,
          },
        ];
      }
      this.$refs.SMSMessageModal.show();
    },
    back() {
      if (this.step === 2) {
        this.step = 1;
      }
    },
    submit1() {
      if (this.charactersLeft >= 0) {
        this.step = 2;
      }
    },
    submit2() {
      const data = {
        campaign_id: Number(this.cId),
        ...this.formPayload,
        target_bucket: this.targetBucket,
      };

      if (this.importGroup) {
        data.group = this.importGroup;
      }

      if (this.selectedTeams.length) {
        if (this.targetBucket) {
          data.bucket_and_list = true;
        }

        this.selectedTeams = this.selectedTeams.map((team) => {
          const phone = team?.attributes?.phone || "";
          return {
            ...(team || {}),
            attributes: {
              ...(team?.attributes || {}),
              phone: phone.trim(),
            },
          };
        });
        data.team_ids = this.selectedTeams.map((t) => Number(t.id));
      }

      this.saveSmsMessage({
        oId: this.oId,
        cId: this.cId,
        delivery: "sms",
        audience: "teams",
        data,
      })
        .then((data) => {
          this.close();
          this.$_notificationsMixin_makeToast(
            `Success`,
            `Message id: ${data.sqs_message_id}`,
            "success"
          );
        })
        .catch((e) => (this.error = e.response));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .custom-control {
    z-index: unset;
  }
  .multiselect.cms {
    width: 100%;
    min-height: 50px;
    cursor: pointer;
    .multiselect__select {
      height: 50px;
      &:before {
        top: 60%;
      }
    }
    .multiselect__tags {
      &-wrap {
        min-height: 50px;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
      }
      min-height: 50px;
      padding: 0px 40px 0 8px;
    }
    .multiselect__single,
    .multiselect__placeholder {
      font-size: 16px;
      font-weight: 600;
      min-height: 50px;
      line-height: 50px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
    .multiselect__input {
      min-height: 50px;
      font-size: 16px;
      font-weight: 600;
      line-height: 50px;
      margin-bottom: 0;
    }
  }
  input[type="radio"][name="target-radios"] {
    &:disabled {
      ~ label {
        &::before {
          background-color: #007bff !important;
        }
      }
    }
    ~ label {
      width: 100%;
      &::after {
        background-image: unset !important;
        background: unset;
      }
    }
  }
}

.disabled-area {
  pointer-events: none;
}
</style>
