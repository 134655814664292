<template>
  <component :is="iconComponents[iconName]" />
</template>

<script>
import confetti from "@/views/event-page/icons/confetti.vue";
import earth from "@/views/event-page/icons/earth.vue";
import laptop from "@/views/event-page/icons/laptop.vue";
import list from "@/views/event-page/icons/list.vue";
import move from "@/views/event-page/icons/move.vue";
import lock from "@/views/event-page/icons/lock.vue";
import bd from "@/views/event-page/icons/bd.vue";

export default {
  name: "event-page-icon",

  components: {
    confetti,
    earth,
    laptop,
    list,
    move,
    lock,
    bd,
  },

  props: {
    iconName: {
      type: String,
      default: () => "confetti",
    },
  },

  computed: {
    iconComponents() {
      return {
        confetti: "confetti",
        earth: "earth",
        laptop: "laptop",
        list: "list",
        move: "move",
        lock: "lock",
        bd: "bd",
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
