export default () => ({
  title: "PhoneRequired",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
    },
  },
});
