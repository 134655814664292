<template>
  <div class="sample-card" :style="{ backgroundColor: color1 }">
    <p class="sample-card__header" contenteditable :style="headerGradient">SAMPLE DONOR CARD</p>
    <p class="sample-card__name" contenteditable>Name Here</p>
    <p class="sample-card__comment" contenteditable>Comment here</p>
    <p class="sample-card__total" contenteditable>
      Total: <span class="sample-card__amount">$0</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "event-page-sample-donor-card",

  props: {
    color1: {
      type: String,
      required: true,
    },
    color2: {
      type: String,
      required: true,
    },
    color3: {
      type: String,
      required: true,
    },
  },

  computed: {
    headerGradient() {
      return {
        background: `linear-gradient(90deg, ${this.color2}, ${this.color3})`,
        color: this.color1,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.sample-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 26px 20px;
  width: 222px;
  aspect-ratio: 1;
  border-radius: 7px;
  text-align: center;
  color: var(--event-white);

  p {
    margin-bottom: 0;
  }

  &__header {
    padding: 10px 18px 8px;
    border-radius: 6px;
    font-size: 12px;
    line-height: 1;
    font-weight: 800;
    text-transform: uppercase;
  }

  &__name {
    letter-spacing: -0.03em;
    font-size: 28px;
    font-weight: 700;
  }

  &__comment {
    letter-spacing: -0.03em;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
  }

  &__total {
    font-size: 15px;
    font-weight: 500;
  }

  &__amount {
    font-size: 28px;
    font-weight: 700;
  }
}
</style>
