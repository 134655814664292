<template>
  <div class="event-page event-page-dahsboard">
    <nav class="tw-flex tw-gap-4 tw-justify-center tw-mb-4">
      <router-link to="/event-donations" class="event-page__link">
        {{ $t("template.event-page", "Donations") }}
      </router-link>
      <router-link to="/event-controls" class="event-page__link">
        {{ $t("template.event-page", "Screen controls") }}
      </router-link>
    </nav>
    <event-donations v-show="isShow === 'donations'" />
    <event-settings v-show="isShow === 'screen-controls'" />
  </div>
</template>

<script>
import EventSettings from "@/views/event-page/event-page-settings/index.vue";
import EventDonations from "@/views/event-page/event-page-donations/index.vue";
export default {
  name: "event-page-selector",

  components: {
    EventSettings,
    EventDonations,
  },

  computed: {
    isShow() {
      return this.$route.meta.eventDonations ? "donations" : "screen-controls";
    },
  },
};
</script>

<style lang="scss" scoped>
.event-page {
  font-family: var(--font-secondary);
  font-weight: 400;
  font-style: normal;

  &__link {
    text-decoration: none;
    font-weight: 700;
    color: var(--event-primary);

    &:hover {
      text-decoration: none;
    }

    ::v-deep &.router-link-exact-active {
      color: var(--event-soft-dark);
    }
  }
}
</style>
