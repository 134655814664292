<template>
  <div class="py-2">
    <div class="d-flex justify-content-center">
      <div v-if="!success">
        <div v-if="!confirm" class="tw-flex justify-content-center">
          <button class="bttn bttn--lg bttn--orange" @click="confirm = true">
            {{ btnTitle }}
          </button>
        </div>

        <div v-else>
          <button class="bttn bttn--lg" :disabled="loading" @click="confirm = false">
            <b-spinner v-if="loading" variant="white" small></b-spinner>
            {{ $t("dashboard.sub_cancel_btn_no", "Back") }}
          </button>

          <button class="bttn bttn--lg bttn--orange" :disabled="loading" @click="send">
            <b-spinner v-if="loading" variant="white" small></b-spinner>
            {{ $t("dashboard.sub_cancel_btn_yes", "Yes") }}
          </button>
        </div>

        <b-alert class="mt-2" variant="warning" show>
          {{ note }}
        </b-alert>
      </div>

      <b-alert v-else variant="success" show>
        {{ $t("dashboard.sub_canceled_success", "Subscription canceled") }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls";
import { notificationsMixin } from "@/mixins";

export default {
  props: {
    donationID: {
      type: [Number, String],
      default: 0,
    },
    donationAttributes: {
      type: Object,
      default: () => {},
    },
  },

  mixins: [notificationsMixin],

  data() {
    return {
      loading: false,
      confirm: false,
      success: false,
    };
  },

  computed: {
    orgId() {
      return localStorage.getItem("orgId");
    },
    bankName() {
      return this.donationAttributes.bank_name;
    },
    markAsCanceled() {
      switch (this.bankName) {
        case "stripe":
        case "stripe-element":
          return false;

        default:
          return true;
      }
    },
    note() {
      if (this.markAsCanceled) {
        return this.$t(
          "dashboard.sub_mark_cancel_note",
          "Note: This will mark the subscription as canceled in the system. You will need to manually cancel the subscription in your payment gateway."
        );
      }

      return this.$t(
        "dashboard.sub_cancel_note",
        "Note: This will mark the subscription as canceled in the system."
      );
    },
    btnTitle() {
      if (this.markAsCanceled) {
        return this.$t("dashboard.sub_mark_cancel_btn", "Mark as Canceled");
      }

      return this.$t("dashboard.sub_cancel_btn", "Cancel subscription");
    },
  },

  methods: {
    send() {
      this.loading = true;
      axios
        .post(
          urls.orgCancelRecurringPlan(
            this.orgId,
            this.donationAttributes.campaign_id,
            this.donationID
          )
        )
        .then(() => {
          this.$_notificationsMixin_makeToast("Success", "", "success");
          this.success = true;
        })
        .catch((e) => {
          this.$_notificationsMixin_handleCatch(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
