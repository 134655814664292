<script>
import { mapState } from "vuex";
import FormGeneretor from "@/common-components/form-generetor/form-generetor";
import HeadlessMultipleSetting from "@/views/campaigns/campaign-settings/headless-multiple-setting.vue";
import SettingModal from "@/views/campaigns/campaign-settings/settingModal.vue";

const onlyPositive = {
  lazy: true,
  handler: (value) => Math.abs(value),
};

const only2Decimals = {
  lazy: true,
  onMount: true,
  handler: (value) => Number(parseFloat(onlyPositive.handler(value)).toFixed(2)),
};

export default {
  components: {
    FormGeneretor,
    HeadlessMultipleSetting,
    SettingModal,
  },
  props: {
    baseSettingsItem: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      gatewayFeeScheme: null,
    };
  },
  created() {
    this.loadGateways();
  },
  methods: {
    async loadGateways() {
      let gateways = await this.$store.dispatch("getGatewaysAndReturn");
      const uniqueGateways = new Set();
      gateways = gateways
        .filter((v) => v.attributes.active)
        .filter((obj) => {
          const gateway = obj.attributes?.gateway;
          if (!gateway || uniqueGateways.has(gateway)) {
            return false;
          }
          uniqueGateways.add(gateway);
          return true;
        });
      const properties = gateways.reduce((acc, gateway) => {
        acc[gateway.attributes.gateway] = {
          title: gateway.attributes.gateway,
          ...this.GATEWAY_FEE_DEFAULT_PROPERTIES,
        };
        return acc;
      }, {});
      this.gatewayFeeScheme = {
        type: "object",
        layout: "accordion",
        properties,
      };
    },
    async onSubmit(form, save, hide) {
      const elements = form.target.elements;
      let someElementIsInvalid = false;

      for (const element of elements) {
        if (!element.checkValidity()) {
          someElementIsInvalid = true;
          const key = element.name.split("|")[0];
          const button = document.getElementById(`${key}-object-layout-accordion-button`);
          const body = document.getElementById(`${key}-object-layout-accordion`);
          if (!body.classList.contains("show")) {
            button.click();
          }
          button.focus();
          this.$nextTick(() => {
            element.reportValidity();
          });
          break;
        }
      }

      if (!someElementIsInvalid) {
        await save(null, hide);
      }
    },
  },
  computed: {
    ...mapState({}),

    GATEWAY_FEE_DEFAULT_PROPERTIES() {
      return {
        type: "object",
        expanded: true,
        properties: {
          percentage: {
            title: this.$t("edit_campaign.schema.gateway_fee_scheme.percentage", "Percentage"),
            type: "number",
            default: 0,
            step: "any",
            min: 0,
            formatter: only2Decimals,
            description: this.$t("edit_campaign.schema.gateway_fee_scheme.percentage_description", {
              returnEmptyOnMissing: true,
            }),
          },
          fixed: {
            title: this.$t("edit_campaign.schema.gateway_fee_scheme.fixed", "Fixed"),
            type: "number",
            default: 0,
            step: "any",
            min: 0,
            formatter: only2Decimals,
            description: this.$t("edit_campaign.schema.gateway_fee_scheme.fixed_description", {
              returnEmptyOnMissing: true,
            }),
          },
        },
      };
    },

    includeFeeToMyDonatonScheme() {
      return {
        type: "object",
        properties: {
          value: {
            title: "Active",
            type: "boolean",
            default: false,
            expanded: true,
          },
          show_on_confirmation_step: {
            title: this.$t(
              "edit_campaign.schema.gateway_fee_scheme.show_on_confirmation_step",
              "Show On Confirmation Step"
            ),
            description: this.$t(
              "edit_campaign.schema.gateway_fee_scheme.show_on_confirmation_step_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
            type: "boolean",
            default: false,
            expanded: true,
          },
          limit: {
            title: this.$t("edit_campaign.schema.gateway_fee_scheme.limit", "Limit"),
            type: "number",
            default: 0,
            expanded: true,
            min: 0,
            formatter: onlyPositive,
            description: this.$t("edit_campaign.schema.gateway_fee_scheme.limit_description", {
              returnEmptyOnMissing: true,
            }),
          },
          checked: {
            title: this.$t("edit_campaign.schema.gateway_fee_scheme.checked", "Checked"),
            type: "boolean",
            default: false,
            expanded: true,
            description: this.$t("edit_campaign.schema.gateway_fee_scheme.checked_description", {
              returnEmptyOnMissing: true,
            }),
          },
        },
      };
    },
  },
};
</script>

<template>
  <HeadlessMultipleSetting
    :settingScheme="{
      gateway_fee: gatewayFeeScheme,
      include_fee_to_my_donaton: includeFeeToMyDonatonScheme,
    }"
    v-slot="{ formData, save, loading, isActive }"
  >
    <SettingModal
      :isActive="isActive"
      :save="save"
      :loading="loading"
      :baseSettingsItem="baseSettingsItem"
    >
      <template #content="{ hide }">
        <form id="CoverCCFeeForm" novalidate @submit.prevent="onSubmit($event, save, hide)">
          <FormGeneretor
            :scheme="includeFeeToMyDonatonScheme"
            :value="formData('include_fee_to_my_donaton')"
            key="include_fee_to_my_donaton"
            as="div"
            class="my-4"
            :components="$options.formGeneretorComponents"
          />

          <FormGeneretor
            v-if="!!gatewayFeeScheme"
            :scheme="gatewayFeeScheme"
            :value="formData('gateway_fee')"
            key="gateway_fee"
            as="div"
            class="my-4"
            :components="$options.formGeneretorComponents"
          />
          <div v-else class="accordion my-4" id="loading-accordion">
            <b-card
              no-body
              v-for="i in Array.from(Array(10).keys())"
              :key="`loading-accordion-card-${i}`"
            >
              <b-card-header role="tab">
                <h2 class="mb-0">
                  <b-skeleton type="button" class="w-100" />
                </h2>
              </b-card-header>
              <b-collapse accordion="loading-accordion" role="tabpanel">
                <b-card-body />
              </b-collapse>
            </b-card>
          </div>
        </form>
        <div class="d-flex justify-content-end tw-items-end">
          <button
            type="button"
            class="bttn bttn--sm bttn--blue tw-me-auto tw-mt-7"
            @click="hide"
            name="button"
          >
            {{ $t("edit_campaign.setting_close", "Close") }}
          </button>
          <b-btn
            class="bttn bttn--sm bttn--orange"
            form="CoverCCFeeForm"
            type="submit"
            :disabled="loading"
            :style="{ gap: '10px' }"
          >
            <b-spinner v-if="loading" small></b-spinner>
            {{ $t("edit_campaign.setting_save", "Save") }}
          </b-btn>
        </div>
      </template>
    </SettingModal>
  </HeadlessMultipleSetting>
</template>
