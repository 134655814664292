var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('SettingModal',{attrs:{"baseSettingsItem":_vm.baseSettingsItem},scopedSlots:_vm._u([{key:"content",fn:function({ hide }){return [(!_vm.firstLoading)?_c('div',{staticClass:"w-25"},[_c('div',{staticClass:"d-flex flex-column"},[(_vm.metaDataServer['attach_pdf_to_receipt_email'])?_c('AppSwitchToggle',{staticClass:"my-1",attrs:{"label":_vm.$t(
              'edit_campaign.email_template_toggle_include_receipt_in_the_email',
              'Include receipt in the email'
            ),"plate":"","disabled":_vm.loading,"loading":_vm.loading},on:{"change":function($event){return _vm.onChangeAppSwitchToggle('attach_pdf_to_receipt_email')}},model:{value:(_vm.metaData.attach_pdf_to_receipt_email.value),callback:function ($$v) {_vm.$set(_vm.metaData.attach_pdf_to_receipt_email, "value", $$v)},expression:"metaData.attach_pdf_to_receipt_email.value"}}):_vm._e(),(_vm.metaDataServer['do_not_send_email_receipt'])?_c('AppSwitchToggle',{staticClass:"my-1",attrs:{"label":_vm.$t(
              'edit_campaign.email_template_toggle_do_not_send_email_receipt_v2',
              'Send donation receipt email'
            ),"plate":"","disabled":_vm.loading,"loading":_vm.loading,"true-value":false,"false-value":true},on:{"change":function($event){return _vm.onChangeAppSwitchToggle('do_not_send_email_receipt')}},model:{value:(_vm.metaData.do_not_send_email_receipt.value),callback:function ($$v) {_vm.$set(_vm.metaData.do_not_send_email_receipt, "value", $$v)},expression:"metaData.do_not_send_email_receipt.value"}}):_vm._e()],1)]):_c('div',{staticClass:"d-flex flex-column"},[_c('b-skeleton',{staticClass:"my-1",attrs:{"type":"button"}}),_c('b-skeleton',{staticClass:"my-1",attrs:{"type":"button"}})],1),_c('button',{staticClass:"bttn bttn--sm bttn--blue tw-me-auto tw-mt-7",attrs:{"type":"button","name":"button"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t("edit_campaign.setting_close", "Close"))+" ")])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }