export default ($t) => ({
  type: "object",
  title: $t("edit_campaign.featured_teams_setting_name", "Featured Teams"),
  description: $t(
    "edit_campaign.featured_teams_setting_description",
    "Configure featured teams display settings"
  ),
  properties: {
    value: {
      type: "boolean",
      title: $t("edit_campaign.featured_teams_value_title", "Enable Featured Teams"),
      description: $t(
        "edit_campaign.featured_teams_value_description",
        "Enable or disable featured teams functionality"
      ),
    },
    place: {
      type: "enum",
      title: $t("edit_campaign.featured_teams_place_title", "Display Location"),
      description: $t(
        "edit_campaign.featured_teams_place_description",
        "Specify which team levels should be included in featured teams (behavior depends on UI Location setting)"
      ),
      expanded: true,
      default: "all",
      items: {
        enum: [
          {
            value: "teams",
            text: $t(
              "edit_campaign.featured_teams_place_teams",
              "Teams Only (In Teams Tab: Shows featured teams in the teams tab only. Under Progress Bar: Shows one list of featured teams from team level.)"
            ),
          },
          {
            value: "parent",
            text: $t(
              "edit_campaign.featured_teams_place_parent",
              "Parent Teams (In Teams Tab: Shows featured teams in the parent teams tab only. Under Progress Bar: Shows one list of featured teams from parent level.)"
            ),
          },
          {
            value: "grandparent",
            text: $t(
              "edit_campaign.featured_teams_place_grandparent",
              "Grandparent Teams (In Teams Tab: Shows featured teams in the grandparent teams tab only. Under Progress Bar: Shows one list of featured teams from grandparent level.)"
            ),
          },
          {
            value: "all",
            text: $t(
              "edit_campaign.featured_teams_place_all",
              "All Places (In Teams Tab: Shows featured teams across all tabs teams, parent, grandparent. Under Progress Bar: Shows all featured teams from all levels.)"
            ),
          },
        ],
      },
    },
    where_to_show: {
      type: "enum",
      title: $t("edit_campaign.featured_teams_where_to_show_title", "UI Location"),
      description: $t(
        "edit_campaign.featured_teams_where_to_show_description",
        "Choose where on the campaign page to display the featured teams component"
      ),
      expanded: true,
      default: "teams_tab",
      items: {
        enum: [
          {
            value: "teams_tab",
            text: $t(
              "edit_campaign.featured_teams_where_to_show_teams_tab",
              "Teams Tab (Display featured teams within the respective teams tabs based on Display Location setting)"
            ),
          },
          {
            value: "under_progress_bar",
            text: $t(
              "edit_campaign.featured_teams_where_to_show_under_progress",
              "Under Progress Bar (Display featured teams in a single component under the progress bar, fetched according to Display Location setting)"
            ),
          },
        ],
      },
    },
    donate_team_sort_by_featured: {
      type: "boolean",
      expanded: true,
      title: $t(
        "edit_campaign.featured_teams_donate_team_sort_by_featured_title",
        "Donate Team Sort By Featured"
      ),
      description: $t(
        "edit_campaign.featured_teams_donate_team_sort_by_featured_description",
        "This will show teams that are featured as the first teams in the donation form teams list."
      ),
      default: false,
    },
    // TODO: needs to be empty object when values in form are same as here defined for default to not break how current campaign request works.
    // extra_params: {
    //   type: "object",
    //   title: $t("edit_campaign.featured_teams_extra_params_title", "Query Parameters"),
    //   description: $t(
    //     "edit_campaign.featured_teams_extra_params_description",
    //     "Additional query parameters for teams API request"
    //   ),
    //   expanded: true,
    //   properties: {
    //     group: {
    //       type: "string",
    //       title: $t("edit_campaign.featured_teams_param_group", "Group Filter"),
    //       description: $t(
    //         "edit_campaign.featured_teams_param_group_desc",
    //         "Group filter for teams"
    //       ),
    //       default: "",
    //     },
    //     limit: {
    //       type: "number",
    //       title: $t("edit_campaign.featured_teams_param_limit", "Limit"),
    //       description: $t(
    //         "edit_campaign.featured_teams_param_limit_desc",
    //         "Number of teams to return (max 100)"
    //       ),
    //       min: 1,
    //       max: 100,
    //       default: 10,
    //     },
    //     sort: {
    //       type: "enum",
    //       title: $t("edit_campaign.featured_teams_param_sort", "Sort By"),
    //       description: $t(
    //         "edit_campaign.featured_teams_param_sort_desc",
    //         "Sort field (prefix with - for descending order)"
    //       ),
    //       items: {
    //         enum: [
    //           { value: "name", text: $t("edit_campaign.featured_teams_sort_name", "Name") },
    //           {
    //             value: "-name",
    //             text: $t("edit_campaign.featured_teams_sort_name_desc", "Name (Descending)"),
    //           },
    //           { value: "amount", text: $t("edit_campaign.featured_teams_sort_amount", "Amount") },
    //           {
    //             value: "-amount",
    //             text: $t("edit_campaign.featured_teams_sort_amount_desc", "Amount (Descending)"),
    //           },
    //           {
    //             value: "donorscount",
    //             text: $t("edit_campaign.featured_teams_sort_donors", "Donors Count"),
    //           },
    //           {
    //             value: "-donorscount",
    //             text: $t(
    //               "edit_campaign.featured_teams_sort_donors_desc",
    //               "Donors Count (Descending)"
    //             ),
    //           },
    //           {
    //             value: "sort",
    //             text: $t("edit_campaign.featured_teams_sort_auto", "Auto Sort"),
    //           },
    //           {
    //             value: "-sort",
    //             text: $t("edit_campaign.featured_teams_sort_auto_desc", "Auto Sort (Descending)"),
    //           },
    //           { value: "goal", text: $t("edit_campaign.featured_teams_sort_goal", "Goal") },
    //           {
    //             value: "-goal",
    //             text: $t("edit_campaign.featured_teams_sort_goal_desc", "Goal (Descending)"),
    //           },
    //           {
    //             value: "closest_to_goal",
    //             text: $t("edit_campaign.featured_teams_sort_closest", "Closest to Goal"),
    //           },
    //           {
    //             value: "-closest_to_goal",
    //             text: $t(
    //               "edit_campaign.featured_teams_sort_closest_desc",
    //               "Closest to Goal (Descending)"
    //             ),
    //           },
    //           {
    //             value: "latest_activity",
    //             text: $t("edit_campaign.featured_teams_sort_activity", "Latest Activity"),
    //           },
    //           {
    //             value: "-latest_activity",
    //             text: $t(
    //               "edit_campaign.featured_teams_sort_activity_desc",
    //               "Latest Activity (Descending)"
    //             ),
    //           },
    //         ],
    //       },
    //       default: "sort",
    //     },
    //     skip_without_goal: {
    //       type: "boolean",
    //       title: $t("edit_campaign.featured_teams_param_skip_no_goal", "Skip Teams Without Goal"),
    //       description: $t(
    //         "edit_campaign.featured_teams_param_skip_no_goal_desc",
    //         "Exclude teams without goals"
    //       ),
    //       default: false,
    //     },
    //     skip_without_donations: {
    //       type: "boolean",
    //       title: $t(
    //         "edit_campaign.featured_teams_param_skip_no_donations",
    //         "Skip Teams Without Donations"
    //       ),
    //       description: $t(
    //         "edit_campaign.featured_teams_param_skip_no_donations_desc",
    //         "Exclude teams without donations"
    //       ),
    //       default: false,
    //     },
    //     goal_above: {
    //       type: "number",
    //       title: $t("edit_campaign.featured_teams_param_goal_above", "Goal Above"),
    //       description: $t(
    //         "edit_campaign.featured_teams_param_goal_above_desc",
    //         "Filter teams with goal above this value"
    //       ),
    //       default: 0,
    //     },
    //     goal_below: {
    //       type: "number",
    //       title: $t("edit_campaign.featured_teams_param_goal_below", "Goal Below"),
    //       description: $t(
    //         "edit_campaign.featured_teams_param_goal_below_desc",
    //         "Filter teams with goal below this value"
    //       ),
    //       default: 0,
    //     },
    //     show_hidden: {
    //       type: "boolean",
    //       title: $t("edit_campaign.featured_teams_param_show_hidden", "Show Hidden Teams"),
    //       description: $t(
    //         "edit_campaign.featured_teams_param_show_hidden_desc",
    //         "Include hidden teams"
    //       ),
    //       default: false,
    //     },
    //   },
    // },
  },
});
