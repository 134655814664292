import axios from "axios";
import debounce from "lodash/debounce";
import { Validator } from "vee-validate";
import urls from "@/constants/urls";
import Vue from "vue";

/* eslint-disable no-misleading-character-class */
const alphanumeric = {
  en: /^[0-9A-Z_-]*$/i,
  cs: /^[0-9A-ZÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ_-]*$/i,
  da: /^[0-9A-ZÆØÅ_-]$/i,
  de: /^[0-9A-ZÄÖÜß_-]*$/i,
  es: /^[0-9A-ZÁÉÍÑÓÚÜ_-]*$/i,
  fa: /^[ءآأؤإئابةتثجحخدذرزسشصضطظعغفقكلمنهوىيًٌٍَُِّْٰپژگچکی_-]*$/,
  fr: /^[0-9A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ_-]*$/i,
  it: /^[0-9A-Z\xC0-\xFF_-]*$/i,
  lt: /^[0-9A-ZĄČĘĖĮŠŲŪŽ_-]*$/i,
  hu: /^[0-9A-ZÁÉÍÓÖŐÚÜŰ_-]*$/i,
  nl: /^[0-9A-ZÉËÏÓÖÜ_-]*$/i,
  pl: /^[0-9A-ZĄĆĘŚŁŃÓŻŹ_-]*$/i,
  pt: /^[0-9A-ZÃÁÀÂÇÉÊÍÕÓÔÚÜ_-]*$/i,
  ro: /^[0-9A-ZĂÂÎŞŢ_-]*$/i,
  ru: /^[0-9А-ЯЁ_-]*$/i,
  sk: /^[0-9A-ZÁÄČĎÉÍĹĽŇÓŔŠŤÚÝŽ_-]*$/i,
  sr: /^[0-9A-ZČĆŽŠĐ_-]*$/i,
  sv: /^[0-9A-ZÅÄÖ_-]*$/i,
  tr: /^[0-9A-ZÇĞİıÖŞÜ_-]*$/i,
  uk: /^[0-9А-ЩЬЮЯЄІЇҐ_-]*$/i,
  ar: /^[٠١٢٣٤٥٦٧٨٩0-9ءآأؤإئابةتثجحخدذرزسشصضطظعغفقكلمنهوىيًٌٍَُِّْٰ_-]*$/,
  az: /^[0-9A-ZÇƏĞİıÖŞÜ_-]*$/i,
  el: /^[0-9Α-ώ_-]*$/i,
  he: /^[0-9A-Z\u0590-\u05fe_-]*$/i,
};

function alphaNum(value, { locale }) {
  if (Array.isArray(value)) {
    return value.every((val) => alphaNum(val, { locale }));
  }

  // Match at least one locale.
  if (!locale) {
    return Object.keys(alphanumeric).some((loc) => alphanumeric[loc].test(value));
  }

  return (alphanumeric[locale] || alphanumeric.en).test(value);
}

Validator.extend("alpha_h", (value) => !/\d/.test(value));
Validator.extend("alpha_num", alphaNum);

Validator.extend("no_only_spaces", {
  validate: (value) => /\S/.test(value),
});

Validator.extend("no_whitespace", {
  validate(value) {
    return !/^\s*$/.test(value);
  },
  getMessage() {
    const string = Vue.prototype.$t(
      "add_campaign.title_not_valid",
      "Campaign title should not contain empty space"
    );
    return string;
  },
});

Validator.extend("short_link", {
  validate: (value) => {
    const re = /^[0-9A-Z_-]*$/i;
    const spaces = /^\S+$/g;
    return re.test(value) && spaces.test(value);
  },
});

const twilioLookupValidate = debounce((phone, resolve) => {
  axios
    .get(urls.twilioLookup.replace(":phone", phone))
    .then(() => {
      resolve({
        valid: true,
        data: undefined,
      });
    })
    .catch(() => {
      resolve({
        valid: false,
        data: undefined,
      });
    });
}, 350);

Validator.extend("twilio_lookup", {
  validate: (value, { valid }) =>
    new Promise((resolve) => {
      if (!valid) {
        resolve({
          valid,
          data: undefined,
        });
        return;
      }

      const phone = String(value).trim();
      twilioLookupValidate(phone, resolve);
    }),
});

Validator.extend("phone_number", {
  validate: (value, phoneObject) => {
    if (phoneObject?.valid) {
      return true;
    }

    return false;
  },
});
