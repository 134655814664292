<template>
  <div>
    <button class="bttn bttn--sm bttn--orange" :disabled="!hasAccess" @click="openModal">
      {{ $t("edit_campaign.add_new_donation_level") }}
    </button>
    <!-- Modal Component -->

    <b-modal id="level-modal-form" @hidden="clearData" size="lg">
      <div slot="modal-header">
        <h2>{{ $t("edit_campaign.donation_level_form") }}</h2>
        <div class="modal-header__hide" @click="modalClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button type="button" class="bttn bttn--lg bttn--blue" @click="modalClose" name="button">
          {{ $t("edit_campaign.matcher_form_close") }}
        </button>
        <button
          type="submit"
          form="matcher-form"
          :disabled="sending"
          :class="sending ? 'disabled bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--orange'"
        >
          {{ $t("edit_campaign.matcher_form_save") }}
        </button>
      </div>

      <form id="matcher-form" class="gateway-form" @submit.prevent="saveForm">
        <error v-if="errors" :errors="errors"></error>

        <div class="form-block">
          <div class="row">
            <div class="col-12 col-lg-3">
              <div class="matcher-card__img">
                <div class="matcher-img">
                  <label v-if="!levelData.data.attributes.image" @click="changeImage()">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                      />
                    </svg>
                    {{ $t("edit_campaign.upload_donation_level_image") }}
                  </label>

                  <div v-if="levelData.data.attributes.image" class="matcher-img__loaded">
                    <div class="image-wrapper">
                      <img :src="levelData.data.attributes.image" alt />
                    </div>
                    <div class="matcher-img__action matcher-img__delete" @click="deleteImage()">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                        />
                        <path d="M0 0h24v24H0z" fill="none" />
                      </svg>
                    </div>
                    <label class="matcher-img__action matcher-img__change" @click="changeImage()">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                        />
                      </svg>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-9">
              <div class="row">
                <div class="col-12">
                  <div class="form-group form-unit">
                    <label
                      class="form-unit__label form-unit__label--upper-sm"
                      :class="{
                        'form-group__label--required': titleInputRequired,
                      }"
                    >
                      {{ $t("edit_campaign.donation_level_title") }}
                    </label>
                    <input
                      type="text"
                      class="form-control form-unit__input"
                      v-model="levelData.data.attributes.title"
                      :required="titleInputRequired"
                    />
                  </div>
                  <div class="form-group form-unit">
                    <label class="form-unit__label form-unit__label--upper-sm">
                      {{ $t("edit_campaign.donation_level_subtitle") }}
                    </label>
                    <input
                      type="text"
                      class="form-control form-unit__input"
                      v-model="levelData.data.attributes.subtitle"
                    />
                  </div>

                  <div class="row">
                    <div class="cols-12 col-lg-6">
                      <div class="form-group form-unit">
                        <label
                          class="form-unit__label form-unit__label--upper-sm form-group__label--required"
                        >
                          {{ $t("edit_campaign.donation_level_amount") }}
                        </label>
                        <b-input-group :prepend="cmpCurrency.code.toUpperCase()">
                          <input
                            type="text"
                            inputmode="numeric"
                            pattern="[0-9]*"
                            required
                            class="form-control form-unit__input"
                            v-model.number="levelData.data.attributes.amount"
                          />
                        </b-input-group>
                      </div>
                      <div
                        v-for="(aac, i) in amount_another_currency"
                        :key="aac[0]"
                        class="form-group form-unit"
                      >
                        <b-input-group class="position-relative">
                          <template #prepend>
                            <b-dropdown
                              :text="aac[0].toUpperCase()"
                              variant="custom"
                              toggle-class="h-100 donation-amount-amount-another-currency-ddl"
                            >
                              <b-dropdown-item
                                v-for="curr in currencies"
                                :key="curr.code"
                                @click="updateAmountAnotherCurrency(i, curr.code)"
                              >
                                {{ curr.code.toUpperCase() }}
                              </b-dropdown-item>
                            </b-dropdown>
                          </template>
                          <input
                            v-model.number="aac[1]"
                            class="form-control form-unit__input"
                            type="text"
                            inputmode="numeric"
                            pattern="[0-9]*"
                            required
                          />
                          <b-icon
                            icon="x-circle"
                            variant="danger"
                            font-scale="1.2"
                            class="remove-btn"
                            role="button"
                            @click="removeAmountAnotherCurrency(i)"
                          ></b-icon>
                        </b-input-group>
                      </div>
                      <div v-if="currencies.length" class="mb-2">
                        <a href="javascript:void(0)" @click="addAmountAnotherCurrency">
                          {{
                            $t(
                              "edit_campaign.donation_level_amount_in_another_curr",
                              "Add amount in another currency"
                            )
                          }}
                        </a>
                      </div>
                    </div>
                    <div class="cols-12 col-lg-6">
                      <div class="form-group form-unit" :class="{ 'has-error': !isOrderUnique }">
                        <label class="form-unit__label form-unit__label--upper-sm">{{
                          $t("edit_campaign.matcher_order")
                        }}</label>
                        <input
                          type="text"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control form-unit__input"
                          v-model.number="levelData.data.attributes.order"
                        />
                        <b-form-text v-if="!isOrderUnique">
                          {{
                            $t(
                              "edit_campaign.donation_level_no_unique_order",
                              "The order must be unique."
                            )
                          }}
                        </b-form-text>
                      </div>
                    </div>
                    <div class="cols-12 col-lg-6">
                      <div class="form-group form-unit">
                        <label class="form-unit__label form-unit__label--upper-sm">{{
                          $t(
                            "edit_campaign.campaign_limited_availability",
                            "Limited availability (0 - unlimited)"
                          )
                        }}</label>
                        <input
                          type="text"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control form-unit__input"
                          v-model.number="levelData.data.attributes.limited"
                        />
                      </div>
                    </div>
                    <div v-if="showRecurringNField" class="col-12">
                      <div class="form-group form-unit">
                        <label class="form-unit__label form-unit__label--upper-sm">
                          {{ $t("edit_campaign.level_recurring_n", "Recurring / Installment") }}
                        </label>
                        <input
                          type="text"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control form-unit__input"
                          v-model.number="levelData.data.attributes.recurring_n"
                        />
                      </div>
                      <div class="form-group form-unit">
                        <b-form-checkbox v-model="installment">
                          {{ $t("edit_campaign.level_installment", "Installment") }}
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group form-unit form-unit--multiselect">
                        <label class="form-unit__label">
                          {{ $t("edit_campaign.donation_level_team", "Team") }}
                        </label>
                        <multiselect
                          v-model="selectedTeams"
                          :options="teamList"
                          label="text"
                          track-by="id"
                          :multiple="true"
                          :customLabel="teamCustomLabel"
                          :placeholder="
                            $t('edit_campaign.donation_level_team_placeholder', 'Select team')
                          "
                          :select-label="$t('dashboard.team_select_label', 'Press enter to select')"
                          :deselect-label="
                            $t('dashboard.team_deselect_label', 'Press enter to remove')
                          "
                          :selected-label="$t('dashboard.team_selected_label', 'Selected')"
                          :searchable="true"
                          :internal-search="false"
                          @search-change="teamFind"
                        ></multiselect>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group form-unit">
                        <label class="form-unit__label">
                          {{ $t("edit_campaign.donation_level_color", "Color") }}
                        </label>
                        <b-form-input
                          v-model="levelData.data.attributes.color"
                          :value="levelData.data.attributes.color"
                          type="color"
                        ></b-form-input>
                        <b-form-text>
                          {{
                            $t(
                              "edit_campaign.donation_level_color_primary_description",
                              "This color will be used for default image color and hover color of item on campaign page"
                            )
                          }}
                        </b-form-text>
                      </div>
                    </div>
                  </div>

                  <div class="form-group form-unit">
                    <b-form-checkbox v-model="hiddenOnCampaignPage">
                      {{
                        $t(
                          "edit_campaign.donation_level_hidden_on_campaign_page",
                          "hide on the campaign page"
                        )
                      }}
                    </b-form-checkbox>
                  </div>

                  <div class="form-group form-unit">
                    <label class="form-unit__label">
                      {{ $t("edit_campaign.donation_level_tooltip_description", "Description") }}
                    </label>
                    <QuillEditorVariables simple v-model="tooltipDescription" :tags="[]">
                    </QuillEditorVariables>
                  </div>

                  <level-jle-config v-if="journalAdActivated" v-model="jleConfig" />
                </div>
              </div>

              <div class="wrapper-options mt-3">
                <button
                  type="button"
                  @click="showLocalizationSettings = !showLocalizationSettings"
                  class="show-hide-bttn"
                  name="button"
                >
                  {{
                    !showLocalizationSettings
                      ? $t("edit_campaign.localization_show_settings", "Show localization settings")
                      : $t("edit_campaign.localization_hide_settings", "Hide localization settings")
                  }}
                  <i
                    :class="!showLocalizationSettings ? 'fa fa-angle-down' : 'fa fa-angle-up'"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>

              <div v-if="showLocalizationSettings" class="localization-section">
                <div class="mt-3" id="title-localization">
                  <b-table
                    :items="selectedTitleLocalizationItem"
                    :fields="titleLocFields"
                    :key-field="['id']"
                    :caption="$t('edit_campaign.title_localization', 'Title Localization')"
                    caption-top
                  >
                    <template #cell(language)="row">
                      <b-form-select
                        v-model="row.item.language"
                        type="text"
                        :placeholder="$t('edit_campaign.language_placeholder', 'language')"
                        :options="languageList"
                      />
                    </template>
                    <template #cell(title)="row">
                      <b-form-input
                        v-model="row.item.title"
                        type="text"
                        :placeholder="$t('edit_campaign.title_placeholder', 'title')"
                      />
                    </template>
                    <template #cell(actions)="row">
                      <b-button
                        type="button"
                        variant="c-secondary"
                        @click="deleteTitleLocalizationItem(row.index)"
                      >
                        <b-icon-trash-fill font-scale="1.2" />
                      </b-button>
                    </template>
                  </b-table>
                  <b-button
                    type="button"
                    class="bttn bttn--sm bttn--blue"
                    @click="addTitleLocalizationItem()"
                    >{{ $t("edit_campaign.add", "Add") }}</b-button
                  >
                </div>

                <div class="mt-3" id="subtitle-localization">
                  <b-table
                    :items="selectedSubtitleLocalizationItem"
                    :fields="subtitleLocFields"
                    :key-field="['id']"
                    :caption="$t('edit_campaign.subtitle_localization', 'Subtitle Localization')"
                    caption-top
                  >
                    <template #cell(language)="row">
                      <b-form-select
                        v-model="row.item.language"
                        type="text"
                        :placeholder="$t('edit_campaign.language_placeholder', 'language')"
                        :options="languageList"
                      />
                    </template>
                    <template #cell(subtitle)="row">
                      <b-form-input
                        v-model="row.item.subtitle"
                        type="text"
                        :placeholder="$t('edit_campaign.subtitle_placeholder', 'subtitle')"
                      />
                    </template>
                    <template #cell(actions)="row">
                      <b-button
                        type="button"
                        variant="c-secondary"
                        @click="deleteSubtitleLocalizationItem(row.index)"
                      >
                        <b-icon-trash-fill font-scale="1.2" />
                      </b-button>
                    </template>
                  </b-table>
                  <b-button
                    type="button"
                    class="bttn bttn--sm bttn--blue"
                    @click="addSubtitleLocalizationItem()"
                    >{{ $t("edit_campaign.add", "Add") }}</b-button
                  >
                </div>

                <div class="mt-3" id="image-localization">
                  <b-table
                    :caption="$t('edit_campaign.image_localization', 'Image Localization')"
                    caption-top
                    :items="selectedImageLocalizationItem"
                    :fields="imageLocFields"
                  >
                    <template v-slot:cell(language)="row">
                      <b-form-select
                        v-model="row.item.language"
                        type="text"
                        :placeholder="$t('edit_campaign.language_placeholder', 'language')"
                        class="image-localization-input"
                        :options="languageList"
                      />
                    </template>

                    <template v-slot:cell(image)="row">
                      <label @click="changeImage(true, row.index)" style="cursor: pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                          />
                        </svg>
                        {{ $t("edit_campaign.upload_donation_level_image") }}
                      </label>
                    </template>

                    <template v-slot:cell(imagePreview)="row">
                      <div
                        v-if="row.item.image && row.item.image.length > 0"
                        class="image-preview-section"
                      >
                        <a :href="row.item.image" target="_blank">
                          <b-img :src="row.item.image" class="image-loc" />
                        </a>
                      </div>
                    </template>

                    <template v-slot:cell()="row">
                      <b-button
                        type="button"
                        variant="c-secondary"
                        @click="deleteImage(true, row.index)"
                      >
                        <b-icon-trash-fill font-scale="1.2" />
                      </b-button>
                    </template>
                  </b-table>
                  <b-button
                    type="button"
                    class="bttn bttn--sm bttn--blue"
                    @click="addImageLocalizationItem"
                    >{{ $t("edit_campaign.add", "Add") }}</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <error class="mt-3" v-if="errors" :errors="errors"></error>

        <div class="gateway-form__action"></div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import debounce from "lodash/debounce";
import urls from "@/constants/urls.js";
import { currencies, currenciesMap } from "@/constants/currencies";
import LevelJleConfig, { defaultFormData } from "./level-jle-config.vue";
import { processImageFile } from "@/helpers/processImageFile";
import QuillEditorVariables from "@/common-components/ui-elements/quill-editor-variables.vue";
import Multiselect from "vue-multiselect";
import error from "@/components/errors.vue";

const extend = `?${[
  "extend=campaign_stats",
  "extend=content",
  "extend=media",
  "extend=matchers",
  "extend=donation_levels",
  "extend=donation_streams",
].join("&")}`;

export default {
  data() {
    return {
      edit: false,
      errors: [],
      sending: false,
      levelId: 0,
      levelData: {
        data: {
          attributes: {
            title: "",
            subtitle: "",
            amount: 0,
            order: 1,
            team_id: 0,
            recurring_n: 0,
            image: "",
            limited: 0,
            color: "",
          },
        },
      },
      installment: false,
      hiddenOnCampaignPage: false,
      jleConfig: {},
      amount_another_currency: [],
      teams: [],
      tooltipDescription: "",
      showLocalizationSettings: false,
      selectedTitleLocalizationItem: [],
      selectedSubtitleLocalizationItem: [],
      selectedImageLocalizationItem: [],
      imageLocFields: [
        { key: "language", label: this.$t("edit_campaign.language", "Language") },
        { key: "image", label: this.$t("edit_campaign.image", "Image") },
        { key: "imagePreview", label: this.$t("edit_campaign.image_preview", "Image Preview") },
        { key: "actions", label: this.$t("edit_campaign.actions", "Actions") },
      ],
      titleLocFields: [
        { key: "language", label: this.$t("edit_campaign.language", "Language") },
        { key: "title", label: this.$t("edit_campaign.title", "Title") },
        { key: "actions", label: this.$t("edit_campaign.actions", "Actions") },
      ],
      subtitleLocFields: [
        { key: "language", label: this.$t("edit_campaign.language", "Language") },
        { key: "subtitle", label: this.$t("edit_campaign.subtitle", "Subtitle") },
        { key: "actions", label: this.$t("edit_campaign.actions", "Actions") },
      ],
    };
  },

  components: {
    error,
    Multiselect,
    LevelJleConfig,
    QuillEditorVariables,
  },

  computed: {
    ...mapState({
      cmpAttrs: (state) => state.campaign.campaign.data.attributes,
      campaignId: (state) => state.campaign.campaign.data.id,
      hasAccess: (state) => state.account.access.CanEditCampaignDonationLevels,
      orgId: () => localStorage.getItem("orgId"),
      teamList: (state) => state.teams.allTeams,
      metas: (s) => s.campaign.metas,
      journalAdActivated: (state) => state.journalAd.activated,
      userCountry: (s) => s.account.userCountry,

      levels: (state) => {
        let included = state.campaign.campaign.included;

        let relationships = state.campaign.campaign.data.relationships.donation_level.data;

        let arrLevels = [];

        relationships.forEach((relationship) => {
          let level = included.find(
            (el) => el.id == relationship.id && el.type == relationship.type
          );

          arrLevels.push(level);
        });

        return arrLevels;
      },

      languageList: (state) => {
        let langArr = state.account.languages;

        let resultArr = [];

        for (var i = 0; i < langArr.length; i++) {
          let obj = {
            text: langArr[i].attributes.name,
            value: langArr[i].attributes.code,
          };

          resultArr.push(obj);
        }

        return resultArr;
      },
    }),

    isOrderUnique() {
      const originalLevel = this.levels.find((l) => l.id === this.levelId);
      if (
        originalLevel &&
        originalLevel.attributes.order === this.levelData.data.attributes.order
      ) {
        return true;
      }

      const existingOrders = this.levels.map((l) => l.attributes.order);
      return !existingOrders.includes(this.levelData.data.attributes.order);
    },

    showRecurringNField() {
      const { value: val1 } = this.metas.call_level_donation_as_recurring || {};

      if (val1) {
        return true;
      }

      return false;
    },

    campaignColorPrimary() {
      const { colors } = this.metas.campaign_colors || {};

      return colors?.secondary || "#213b7f";
    },

    cmpCurrency() {
      const levelCurrency = (this.levelData.data.attributes?.currency || "").toLowerCase();
      if (levelCurrency && currenciesMap[levelCurrency]) {
        return {
          code: levelCurrency,
          sign: currenciesMap[levelCurrency].sign,
        };
      }

      return {
        code: this.cmpAttrs.currency,
        sign: this.cmpAttrs.currency_sign,
      };
    },

    currencies() {
      const selectedCurrencies = Object.keys(Object.fromEntries(this.amount_another_currency));
      return currencies.filter(
        (c) => c.code !== this.cmpCurrency.code && !selectedCurrencies.includes(c.code)
      );
    },

    selectedTeams: {
      get() {
        return this.teams;
      },
      set(teams) {
        this.teams = teams;

        const teamIDs = teams.map((t) => Number(t.id));

        this.levelData.data.attributes.team_id = teamIDs[0];
      },
    },

    titleInputRequired() {
      return this.userCountry !== "IL";
    },
  },

  methods: {
    ...mapActions({
      fetchCreateFileUrl: "mediaStorage/fetchCreateFileUrl",
    }),

    openModal() {
      this.$store.dispatch("getTeamsByCampaignId", [this.campaignId]);
      this.$store.dispatch("initData");

      if (this.journalAdActivated && !Object.values(this.jleConfig).length) {
        this.jleConfig = defaultFormData;
      }

      this.levelData.data.attributes.color = this.campaignColorPrimary;

      this.$root.$emit("bv::show::modal", "level-modal-form");
    },

    modalClose() {
      this.$root.$emit("bv::hide::modal", "level-modal-form");
    },

    teamCustomLabel(el) {
      return `[${el.id}] ${el.attributes.name}`;
    },

    fetchTeam(params) {
      if (Number(this.campaignId) === 0) {
        throw new Error("fetchTeam level campaign id is 0");
      }
      return axios
        .get(urls.apiTeams.replace(":orgId", this.orgId).replace(":campaignId", this.campaignId), {
          params,
        })
        .then(({ data }) => data.data);
    },

    teamFind: debounce(function (q) {
      this.getTeams(q);
    }, 500),

    getTeams(query) {
      const params = {
        q: query ? query : undefined,
      };
      this.$store.dispatch("getTeamsByCampaignId", [this.campaignId, params, true]);
    },

    deleteImage(localization = false, index) {
      if (localization && index > -1) {
        this.selectedImageLocalizationItem.splice(index, 1);
      } else {
        this.levelData.data.attributes.image = "";
      }
    },

    async saveForm() {
      if (!this.isOrderUnique) {
        return;
      }

      if (this.edit) {
        await this.editLevel();
      } else {
        await this.addLevel();
      }
      this.$emit("save-level");
    },

    async buildMeta() {
      const metaData = {
        amount: Object.fromEntries(this.amount_another_currency),
        team_id_list: this.teams.map((t) => Number(t.id)),
        installment: this.installment,
        hidden_on_campaign_page: this.hiddenOnCampaignPage,
        tooltip_description: this.tooltipDescription,
        title_loc: {},
        subtitle_loc: {},
        image_loc: {},
      };

      this.selectedTitleLocalizationItem.forEach((item) => {
        metaData.title_loc[item.language] = item.title;
      });

      this.selectedSubtitleLocalizationItem.forEach((item) => {
        metaData.subtitle_loc[item.language] = item.subtitle;
      });

      this.selectedImageLocalizationItem.forEach((item) => {
        metaData.image_loc[item.language] = item.image;
      });

      if (this.journalAdActivated) {
        this.jleConfig.icon = await this.fetchCreateFileUrl({
          file: this.jleConfig.icon,
        });
        this.jleConfig.bg_url = await this.fetchCreateFileUrl({
          file: this.jleConfig.bg_url,
        });

        metaData.jle_config = this.jleConfig;
      }

      this.levelData.data.attributes.meta = JSON.stringify(metaData);
    },

    async addLevel() {
      this.sending = true;
      this.errors = [];

      this.levelData.data.attributes.campaign_id = parseInt(this.campaignId);

      await this.buildMeta();

      axios
        .post(
          urls.apiDonationLevel
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignId),
          this.levelData
        )
        .then(() => {
          this.sending = false;

          this.$store.dispatch("updateCampaign", extend);

          this.modalClose();

          this.$_notificationsMixin_makeToastCompat({
            group: "Notification",
            title: this.$t("edit_campaign.success_save_donation_levels"),
          });
        })
        .catch((e) => {
          this.sending = false;
          this.renderError(e);
        });
    },

    async editLevel() {
      this.sending = true;
      this.errors = [];

      await this.buildMeta();

      axios
        .put(
          urls.apiPutDonationLevel
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignId)
            .replace(":donationLevelid", this.levelId),
          this.levelData
        )
        .then(() => {
          this.sending = false;

          this.$store.dispatch("updateCampaign", extend);

          this.modalClose();

          this.$_notificationsMixin_makeToastCompat({
            group: "Notification",
            title: this.$t("edit_campaign.success_save_donation_levels"),
          });
        })
        .catch((e) => {
          this.sending = false;
          this.renderError(e);
        });
    },

    changeImage(localization, index) {
      this.errors = [];

      let fileInput = document.createElement("input");
      fileInput.setAttribute("type", "file");
      fileInput.setAttribute("accept", "image/png, image/gif, image/jpeg, image/bmp, image/x-icon");
      fileInput.classList.add("sending-image-input");

      fileInput.addEventListener("change", async () => {
        this.sending = true;

        if (fileInput.files != null && fileInput.files[0] != null) {
          if (fileInput.files[0].size > 1048576) {
            this.renderError({
              response: {
                data: {
                  error: this.$t("edit_campaign.upload_file_too_big"),
                },
              },
            });
            return;
          } else {
            this.errors = [];
          }

          let form_data = new FormData();

          const webpImage = await processImageFile(fileInput.files[0]);

          form_data.append("file", webpImage);
          axios
            .post(urls.loadImageQuill.replace(":OrgId", this.orgId), form_data)
            .then((response) => {
              this.sending = false;

              if (localization) {
                this.selectedImageLocalizationItem[index].image = response.data.src;
              } else {
                this.levelData.data.attributes.image = response.data.src;
              }
            })
            .catch((e) => {
              this.sending = false;

              this.renderError(e);
            });
        }
      });

      document.querySelector("body").appendChild(fileInput);

      fileInput.click();
      fileInput.remove();
    },

    renderError(e) {
      if (e.response) {
        if (e.response.data.error) {
          this.errors.push(e.response.data.error);
        } else {
          this.errors.push(this.$t("template.error_default"));
        }
      } else {
        this.errors.push(this.$t("template.error_default"));
      }
    },

    openModalEdit(level) {
      this.edit = true;

      const meta = JSON.parse(level.attributes?.meta || "{}");
      this.installment = meta?.installment || false;
      this.hiddenOnCampaignPage = meta?.hidden_on_campaign_page || false;
      this.amount_another_currency = Object.entries(meta?.amount || {});
      this.selectedTitleLocalizationItem = Object.entries(meta?.title_loc || {}).map(
        ([language, title]) => ({
          language,
          title,
        })
      );
      this.selectedSubtitleLocalizationItem = Object.entries(meta?.subtitle_loc || {}).map(
        ([language, subtitle]) => ({
          language,
          subtitle,
        })
      );
      this.selectedImageLocalizationItem = Object.entries(meta?.image_loc || {}).map(
        ([language, image]) => ({
          language,
          image,
        })
      );
      this.tooltipDescription = meta?.tooltip_description || "";

      if (this.journalAdActivated) {
        this.jleConfig = meta?.jle_config || defaultFormData;
      }

      this.levelData.data.attributes.color = level.attributes.color || this.campaignColorPrimary;

      this.levelData.data.attributes = Object.assign({}, level.attributes);
      this.levelId = level.id;

      if (level.attributes.team_id || meta?.team_id_list?.length) {
        const ids = meta?.team_id_list?.length ? meta.team_id_list : [level.attributes.team_id];

        this.fetchTeam({
          team_id: ids,
        }).then((list) => {
          this.selectedTeams = list;
        });
      }

      this.openModal();
    },

    clearData() {
      this.edit = false;

      this.installment = false;
      this.hiddenOnCampaignPage = false;

      this.levelId = 0;
      this.errors = [];
      this.amount_another_currency = [];
      this.selectedTitleLocalizationItem = [];
      this.selectedSubtitleLocalizationItem = [];
      this.selectedImageLocalizationItem = [];

      this.teams = [];
      this.jleConfig = {};

      this.tooltipDescription = "";

      this.levelData.data.attributes = {
        title: "",
        subtitle: "",
        color: this.campaignColorPrimary,
        price: 0,
        order: 1,
        team_id: 0,
        recurring_n: 0,
        image: "",
      };
    },

    xchange({ amt, from, to }) {
      return axios
        .get(
          urls.getConversionAmount.replace(":amount", amt).replace(":from", from).replace(":to", to)
        )
        .then((response) => {
          return Math.ceil(response.data.data.attributes.amount_out);
        });
    },

    addAmountAnotherCurrency() {
      const lvlAmt = this.levelData.data.attributes.amount || 0;
      const firstFreeCurr = this.currencies[0]?.code || "usd";

      const payload = {
        amt: lvlAmt,
        from: this.cmpCurrency.code,
        to: firstFreeCurr,
      };

      this.xchange(payload).then((out) => {
        this.amount_another_currency.push([firstFreeCurr, out]);
      });
    },

    updateAmountAnotherCurrency(i, curr) {
      const lvlAmt = this.levelData.data.attributes.amount;

      const payload = {
        amt: lvlAmt,
        from: this.cmpCurrency.code,
        to: curr,
      };

      this.xchange(payload).then((out) => {
        this.$set(this.amount_another_currency, i, [curr, out]);
      });
    },

    removeAmountAnotherCurrency(i) {
      this.amount_another_currency.splice(i, 1);
    },

    addTitleLocalizationItem() {
      this.selectedTitleLocalizationItem.push({
        language: "",
        title: "",
      });
    },
    deleteTitleLocalizationItem(index) {
      if (index > -1) {
        this.selectedTitleLocalizationItem.splice(index, 1);
      }
    },
    addSubtitleLocalizationItem() {
      this.selectedSubtitleLocalizationItem.push({
        language: "",
        subtitle: "",
      });
    },
    deleteSubtitleLocalizationItem(index) {
      if (index > -1) {
        this.selectedSubtitleLocalizationItem.splice(index, 1);
      }
    },
    addImageLocalizationItem() {
      this.selectedImageLocalizationItem.push({
        language: "",
        image: "",
      });
    },
    deleteImageLocalizationItem(index) {
      if (index > -1) {
        this.selectedImageLocalizationItem.splice(index, 1);
      }
    },
    removeImageLocalization(index) {
      this.selectedImageLocalizationItem[index].image = "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .donation-amount-amount-another-currency-ddl {
    background-color: #e9ecef;
    &:hover {
      background-color: darken(#e9ecef, 5);
    }
    @media only screen and (max-width: 1440px) {
      white-space: break-spaces;
      max-width: 150px;
    }
  }
  .remove-btn {
    position: absolute;
    z-index: 2;
    background: #ffff;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
  }
}
.image-loc {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-preview-section {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  overflow: hidden;
}
.mt-3 {
  margin-top: 3rem;
}
.has-error {
  .form-control {
    border-color: #f26e66;
  }
  small {
    color: #f26e66 !important;
  }
}
</style>
