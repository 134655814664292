<template>
  <div class="event-header-hero tw-px-6 lg:tw-px-[78px] tw-px-6">
    <h1 class="event-header-hero__title">{{ title }}</h1>
    <div class="event-header-hero__slot">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.event-header-hero {
  padding-block: 80px 66px;
  background: linear-gradient(246deg, var(--event-primary) 3%, #b2e6fd 99%);

  &__title {
    margin-bottom: 0;
    text-align: center;
    color: var(--event-white);
    font-size: 60px;
    font-weight: 800;
    line-height: 1.2;
  }

  &__slot:not(:empty) {
    margin-top: 46px;
  }
}
</style>
