export const dashboardHost = process.env.VUE_APP_DASHBOARD_HOST || "https://dashboard.charidy.com";

export const dashboardApiHost =
  process.env.VUE_APP_DASHBOARD_API_HOST || "https://dashboardapi.charidy.com";

export const appHost = process.env.VUE_APP_APP_HOST || "https://www.charidy.com";

export const publicApiHost = process.env.VUE_APP_API_HOST || "https://api.charidy.com";

export const donateHost = process.env.VUE_APP_DONATE_HOST || "https://donate.develop.charidy.com";

const urls = {
  appHost: `${appHost}`,
  initPage: `${publicApiHost}/api/v1/initial`,
  createCampaign: `/new/org/campaign/create`,
  valideShortLink: `/new/org/campaign/validate/shortlink`,
  selectCountry: `/new/api/v1/internal/countries/`,
  googleLogin: `${dashboardApiHost}/orgarea/api/v1/authentication/google`,
  stripe: `/new/org/organization/:OrgId/gateways/stripe`,
  loadImageQuill: `${dashboardApiHost}/orgarea/api/v1/account/media`,
  reloadImage: `/new/api/v1/campaign/:Id/media/:IdImage`,
  loadImageOldApi: `/new/api/v1/campaign/:Id/media`,
  loadImage: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/media`,
  stripeCountry: `/new/org/organization/gateways/stripe/fields?country=:IdCountry`,
  deleteImage: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/media/:mid`,

  eventDonationList: (orgId, cid) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${orgId}/campaign/${cid}/event_page/list`,

  switcherGateways: `/new/org/organization/:OrgId/gateway/status`,
  acceptAndESign: `/new/api/v1/organization/:orgID/campaign/:campaignID/document/:documentID/esign`,
  showDocument: `/new/api/v1/organization/:orgID/campaign/:campaignID/document/:documentID?extend[]=preview_url`,
  loadDocument: `/new/api/v1/organization/:orgID/documents`,
  stripeUser: `/new/api/v1/organization/:orgID/gateway/:gatewayId/stripe-user`,
  massUpload: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/donations/import`,
  oldProcProgressTracker: `${dashboardApiHost}/orgarea/api/v1/proc/:id`,
  procDonorAccountProgressTracker: `${dashboardApiHost}/orgarea/api/v1/donor_account/proc/:id`,
  procProgressTracker: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/proc/:id`, // updated link
  taskProgressTracker: `${dashboardApiHost}/orgarea/api/v1/task/reference/:id`,
  loadDonationsList: `/new/api/v1/organization/:orgId/donations?page=:page&perPage=:perPage`,
  loadTeamsList: `/new/api/v1/campaign/:campaignId/teams`,
  // putDonation: `/new/api/v1/donation/:donationId`, //v1 remove?
  postDonation: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/donations`,
  loadDonation: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/donation/:id`,
  donationNotification: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/donation/:did/notification/:type`,
  exportCsv: `new/org/donations/export-csv`,
  donationsExport: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/donations/export`,
  exportCsvList: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/donations/export?email=:email`,
  exportDonorsOrg: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/donors/export`,
  getCampaignList: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaigns`,
  editCampaign: `/org/campaign/edit`,
  getTransition: `${publicApiHost}/api/v1/locale?:group&:location`,
  gateway: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/:id`,
  gateways: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateways`,
  gatewaysForFilterByOrg: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateways`,
  gatewaysForFilterByCampaign: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/gateways`,
  gatewayPayPal: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/paypal/add`,
  gatewayPayPalV2: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/paypalv2/add`,
  gatewayPayMe: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/payme/add`,
  gatewayBroom: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/broomfoundation/add`,
  gatewayAsser: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/asserbishvil/add`,
  gatewayAchisomoch: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/achisomoch/add`,
  getawayBraintree: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/braintree/add`,
  getawayMarcadoPago: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/mercadopago/add`,
  getawayHkBankTransfer: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/hkbanktransfer/add`,
  getawayPledge: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/pledge/add`,
  getawayOjc: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/ojc/add`,
  getawayPayGate: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/paygate/add`,
  getawayDLocal: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/dlocal/add`,
  getawayCheckoutFI: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/checkoutfi/add`,
  getawayMeshulam: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/meshulam/add`,
  getawayMeshulamBit: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/meshulam-bit/add`,
  getawayMhTrust: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/mhtrust/add`,
  getawayZoreyaTzedokos: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/zoreyatzedokos/add`,
  getawayThreepillars: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/threepillars/add`,
  getawayKolYom: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/kolyom/add`,
  getawayNedarimPlus: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/nedarimplus/add`,
  getawayNedarimPlusBit: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/nedarimplus-bit/add`,
  getewayStripeBecs: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/:merchantInfoId/gateway_name/stripe-becs`,
  getewayStripeAppleGooglePay: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/:merchantInfoId/gateway_name/:gtw`,
  getewayFromStripe: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/:merchantInfoId/gateway_name/:gtw`,
  getawayTranzila: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/tranzila/add`,
  getawayTranzilaBit: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/tranzila-bit/add`,
  getawayFunds: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/fund/add`,
  getawayPelecard: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/pelecard/add`,
  getawayWalletdoc: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/walletdoc/add`,
  getawayCoinbase: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/coinbase/add`,
  getawayCardCom: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/cardcom/add`,
  getawayCardComBit: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/cardcom-bit/add`,
  getawayICreditRivhit: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/icredit-rivhit/add`,
  getawayJaffa: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/jaffa/add`,
  getawayJaffaBit: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/jaffa-bit/add`,
  getawayBlinkFidelipay: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/blink-fidelipay/add`,
  getawayAminut: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/aminut/add`,
  getawayCardknox: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/cardknox/add`,
  getawayDonorsFund: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/donors-fund/add`,
  getawayDonorsFundAccountNumbers: (oid) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/gateway/donors-fund/account-numbers`,
  getawayUsaepay: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/usaepay/add`,
  getawayMancal: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/mancal/add`,
  getawayAdd: (gn) => `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/${gn}/add`,
  // Payouts
  payouts: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/payouts`,
  // Reports
  reportGet: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/report/:reportName/get`,
  reportGenerate: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/report/:reportName/generate`,
  reportGenerateFile: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/report/:name/generate/file`,
  reportExport: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:cid/donations/export`,
  reportPickup: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:cid/pickup/list`,
  reportPreuploadedDonors: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/org_donors/report/not_donated`,

  postYadPay: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/yaadpay/add`,
  postIsraelTorament: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/israeltoremet/add`,
  account: `${dashboardApiHost}/orgarea/api/v1/organization`,
  organization: `${dashboardApiHost}/orgarea/api/v1/account/organization`,
  entities: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/account/entities`,
  accountContacts: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/contact`,
  getLanguages: `${dashboardApiHost}/orgarea/api/v1/account/languages`,
  signin: `${dashboardApiHost}/orgarea/api/v1/signin`,
  gettimeZone: `${dashboardApiHost}/orgarea/api/v1/account/timezones`,
  editOrgInfo: `${dashboardApiHost}/orgarea/api/v1/organization`,
  postEntity: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/account/entities`,
  uploadOrgLogo: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/logo`,
  changePassword: `${dashboardApiHost}/orgarea/api/v1/account/password`,
  editEntitie: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/account/entity`,
  stripeApi: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/stripe/custom/add/entity/:id`,
  stripeApiV2: `${dashboardApiHost}/orgarea/api/v2/organization/:orgId/gateway/stripe/custom/add/entity/:id`,
  stripeOnBoarding: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/gateway/stripe/onboarding/:idGateway`,
  getStripeCountry: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/stripe/custom/add/countries`,
  getEntitiesCountry: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/account/entities/countries`,
  getGatewayData: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/:gatewayId`,
  payFastApi: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/payfast/add`,
  getStripeEntiti: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/gateway/stripe/custom/add/entities`,
  getCampaignData: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId`,
  addDescription: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/content`,
  apiMathcers: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/matchers`,
  apiDonationLevel: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/donation_levels`,
  apiDonationStreams: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/donation_streams`,
  apiPutMacther: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/matcher`,
  apiPutDonationLevel: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/donation_level/:donationLevelid`,
  apiPutDonationStream: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/donation_stream/:donationstreamId`,
  validationShortLink: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/shortlink?q=:shortlink&exclude_campaign_id=:exclude_campaign_id`,
  getVideo: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/account/training/videos`,
  apiTeams: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/teams`,
  publicTeams: `${publicApiHost}/api/v1/campaign/:cid/teams`,
  editTeams: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/team`,
  reminder: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/remindme`,
  contactsGetTypeList: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/contacts/types`,
  postGetContacts: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/contacts`,
  putContact: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/contact`,
  donationNewApi: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/donations`,
  putDonation: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/donation`, //v2
  getDashboardInfo: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/dashboard`,
  getCountries: `${dashboardApiHost}/orgarea/api/v1/account/countries`,
  orgDonationsApi: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/donations`,
  editTimeZone: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/timezone`,
  login: `${dashboardApiHost}/orgarea/api/v1/login`,
  resetPassword: `${dashboardApiHost}/orgarea/api/v1/login/reset`,
  resetPasswordToken: `${dashboardApiHost}/orgarea/api/v1/login/reset/token/`,
  getCurrency: `${dashboardApiHost}/orgarea/api/v1/account/currencies`,
  // Integrations
  getSalesforceIntegrationKey: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/integrations/salesforce/init`,
  getCommunityIntegrationKey: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/integrations/comeunity/init`,
  getComeunityIntegrationDataMap: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/integration/comeunity/:id/datamap`,
  getComeunityIntegrationDefaultDataMap: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/integration/comeunity/default_datamap`,
  getMailChimpIntegrationKey: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/integrations/mailchimp/init`,
  getMailChimpIntegrationList: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/integration/mailchimp/:id/lists`,
  exportToMailChimpDonationList: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/integration/mailchimp/:id/list/:listId/export_donations`,
  exportToMailChimpRemindnList: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/integration/mailchimp/:id/:listId/export_remindlist`,
  getOverpassIntegrationKey: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/integrations/overpass/init`,
  getIntegrationsList: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/integrations`,
  getSalesforceIntegrationDataMapV2: (oid, id) =>
    `${dashboardApiHost}/orgarea/api/v2/organization/${oid}/integration/salesforce/${id}/datamap`,
  getSalesforceIntegrationDefaultDataMapV2: (oid) =>
    `${dashboardApiHost}/orgarea/api/v2/organization/${oid}/integration/salesforce/default_datamap`,
  // TaxGift integration
  addIntegration: (oid, name) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/integration/${name}`,
  updateIntegrationByID: (oid, name, id) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/integration/${name}/${id}`,
  getPreviewToken: (oid, cid) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/campaign/${cid}/preview_token`,

  uploadDocuments: `${dashboardApiHost}/orgarea/api/v1/account/documents`,
  getExportReminderList: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/remindme/export`,
  cloneCampaign: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/clone`,
  getReceipt: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/donation/:donationId/receipt`,
  postReceipt: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/donation/:donationId/receipt/email`,
  postConfirmationEmail: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/donation/:donationId/confirmation/email`,
  getOrganizations: `${dashboardApiHost}/orgarea/api/v1/organizations`,
  getAccountOrgById: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId?extend=settings`,
  checkToken: `${dashboardApiHost}/orgarea/api/v1/login/verify/:token`,
  registerUser: `${dashboardApiHost}/orgarea/api/v1/signup?org_account=1`,
  subAccount: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/accounts`,
  patchSubAccount: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/account/:sucAccId`,
  getGd: `${dashboardApiHost}/orgarea/api/v1/query/campaigns`,
  joinGd: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/join_giving_day`,
  deleteAllTeams: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/teams`,
  importTeams: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/teams/import`,
  getAccountAccess: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/connected_account/account/:accountId`,
  exportTeams: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/teams/export`,
  getTrainingVideo: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/account/training/videos`,
  getEmailTemplates: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/email_tpls`,
  getEmailTemplateById: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/email_tpls/:templateId`,
  emailTemplateById: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/email_tpl/:templateType`,
  resetEmailTemplateById: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/email_tpl/:templateType/:section`,
  resetAllEmailTemplateById: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/email_tpl/:templateType`,
  getConversionAmount: `${publicApiHost}/api/v1/xchange?amount=:amount&from=:from&to=:to`,
  getExchange: `${publicApiHost}/api/v1/xchange`,
  cancelDonateSoft: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/donation/:did/softcancel`,
  cancelDonate: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/donation/:donationId/changerequests`,
  cancelDonateDelete: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/donation/:donationId/changerequest/:id`,
  exportReceipts: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/receipts/export?email=:email&legal_entity=:legal_entity`,
  getTemplates: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/emailtemplates`,
  previewTemplate: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/emailtemplate/:templateId/preview`,
  campaignSetupTemplate: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/template`,
  campaignSetupTemplates: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/templates`,
  updates: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/updates`,
  update: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/update/:uid`,
  validationShortLinkOrg: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/shortlink`,

  generateZapierKey: `${dashboardApiHost}/orgarea/api/v1/organization/zapier/:cid/generate_token`,
  integrationGenerateToken: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/integration/:name/generate/token`,

  organizationSettings: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/org_setting`,
  organizationAccountSettings: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/account/settings`,
  campaignSettings: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/setting`,

  donorAccount: `${dashboardApiHost}/orgarea/api/v1/account/donor_account`,
  donorAccountSettings: `${dashboardApiHost}/orgarea/api/v1/account/donor_account/settings`,

  donorAccountStats: `${dashboardApiHost}/orgarea/api/v1/account/donor_account/stats?convert_to=:currency&extend=:organizations&unix_date_from=:date_from&unix_date_to=:date_to`,

  getDonorOrganizations: `${dashboardApiHost}/orgarea/api/v1/account/donor_account/organizations`,
  donorFavoriteOrganizations: `${dashboardApiHost}/orgarea/api/v1/account/donor_account/organizations/favorite`,

  donorDonationsList: `${dashboardApiHost}/orgarea/api/v1/account/donor_account/donations`,
  donorReceiptsList: `${dashboardApiHost}/orgarea/api/v1/account/donor_account/receipts`,
  donorDonationReceipt: `${dashboardApiHost}/orgarea/api/v1/account/donor_account/donation/:donationId/receipt`,
  donorDonationReceiptDownload: `${dashboardApiHost}/orgarea/api/v1/account/donor_account/receipts/archive?email=:email`,
  donorReceiptRegenerate: `${dashboardApiHost}/orgarea/api/v1/account/donor_account/donation/:donationId/receipt`,
  donorCardsStripe: `${dashboardApiHost}/orgarea/api/v1/account/donor_account/payment_methods/stripe/:cardId`,

  donorUnsubscribe: `${dashboardApiHost}/orgarea/api/v1/account/donor_account/unsubscribe`,
  donorDeleteAccount: `${dashboardApiHost}/orgarea/api/v1/account/donor_account/delete_account`,
  donorDeleteAllData: `${dashboardApiHost}/orgarea/api/v1/account/donor_account/delete_all_data`,
  donorDownloadData: `${dashboardApiHost}/orgarea/api/v1/account/donor_account/download_data`,
  organizationRoles: `${dashboardApiHost}/orgarea/api/v1/access/organizations`,

  stripeDescriptorUpdate: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/gateway/stripe/descriptor/update/:idGateway`,
  moduleData: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cmpid/module_data`,
  unpublishableDonations: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cmpid/module/unpublishable_donations`,
  donationLevel: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cmpid/donation_level`,
  getModule: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/module`,
  utm: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/utm`,
  vetting: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/vetting_list`,
  liveRequest: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/live_request`,
  campaignExpress: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/express`,
  raffle: `${publicApiHost}/api/v1/module/raffle`,
  module: `${publicApiHost}/api/v1/module/:name`,
  share: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/share`,
  shareRefreshImages: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/share/refresh-images`,
  campaignTeamDonation: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/donation/:donation_id/campaign_team_donation`,
  messaging: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/messaging/:delivery/:audience`,

  getDonorTeams: `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/teams`,
  getDonorTeamsExport: `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/teams/export`,
  getDonorTeamsStats: `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/teams_stats`,
  getDonorTeamDonors: `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/team/:id/donors`,
  editDonorTeams: `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/team/:id`,
  exportDonors: `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/team/:id/donors/export`,
  getProtectedListUserTeamCampaign: (tid, cid) =>
    `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/team/${tid}/campaign/${cid}`,
  getProtectedListUserTeamCampaignList: (tid) =>
    `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/team/${tid}/campaigns`,
  generateProtectedDonorReport: (tid, reportName) =>
    `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/team/${tid}/report/${reportName}/generate`,
  generateFileProtectedDonorReport: (tid, reportName) =>
    `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/team/${tid}/report/${reportName}/generate/file`,
  getProtectedDonorReport: (tid, reportName) =>
    `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/team/${tid}/report/${reportName}/get`,

  donorAccountMessaging: `${dashboardApiHost}/orgarea/api/v1/donor_account/messaging`,

  getSeferTorahTeamPagesPublic: `${publicApiHost}/api/v1/module/sefer-torah/:cid/list`,
  getSeferTorahTeamPages: `${dashboardApiHost}/api/v1/module/sefer-torah/:cid/list/:tid`,
  editSeferTorahTeamPages: `${dashboardApiHost}/api/v1/module/sefer-torah/item/:id/team/:tid`,

  orgEditSeferTorahTeamPages: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/module/sefer-torah/item/:id/team/:tid`,
  orgSaveSeferTorah: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/module/sefer-torah/item`,
  orgEditSeferTorah: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/module/sefer-torah/item/:id`,
  orgDeleteSeferTorah: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/module/sefer-torah/item/:id`,
  seferTorahImport: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/module/sefer-torah/import`,
  seferTorahExport: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/module/sefer-torah/export`,
  seferTorahImportTemplate: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/module/sefer-torah/template`,

  getTeamSubAccounts: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/logins_to_teams/team/:id/users`,
  getLoginsToTeamsUsers: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/logins_to_teams/users`,
  createTeamSubAccount: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/logins_to_teams/team/:id/user`,
  editTeamSubAccount: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/logins_to_teams/:id`,

  fundTransferStatus: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/stripe/payouts`,
  donorDataRequestCCPA: `${publicApiHost}/api/v1/donor_data_request/ccpa`,

  recurringDonationsSummaryMonth: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/recurring/year/:year/month/:month`,
  recurringDonationsSummaryStats: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/recurring/stats`,
  recurringDonationsSummaryByDonation: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/recurring/donation/:did`,
  recurringDonationResubscribe: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/donation/:did/resubscribe`,
  recurringPayOff: (oid, cid, did) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/campaign/${cid}/donation/${did}/subscriptions/pay_off`,

  // share video
  shareVideoPublic: `${publicApiHost}/api/campaign/:cid/module_video`,
  shareVideoProtectedCreate: `${dashboardApiHost}/api/organization/:oid/campaign/:cid/module_video`,
  shareVideoProtectedRemove: `${dashboardApiHost}/api/organization/:oid/campaign/:cid/module/module_video/:id`,

  reportSms: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/report_sms`,
  reportEmail: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/report_email`,

  foundationReport: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/foundation_report`,

  reportDonationsFailed: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/donations_failed/report`,
  listDonationsFailed: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/donations_failed/list`,
  listTeamManagement: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/campaign/:cid/team_management/list`,

  // Org tickets
  getOrgTicket: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/ticket/:id`,
  getOrgTicketList: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/ticket/list`,

  // Remind me
  remindMeImportXls: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/remind_me/import/xls`,

  // Org extra emails
  orgExtraEmails: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/extra_emails`,
  orgNotificationsFromEmail: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/notifications_from_email`,
  orgNameLanguages: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/org_name_languages`,
  orgMessageTemplates: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/message_templates/:type`,
  orgMessageTemplatesId: `${dashboardApiHost}/orgarea/api/v1/organization/:oid/message_templates/:type/:id`,

  twilioLookup: `${publicApiHost}/api/v1/module/twilio/lookup/:phone`,
  // 2FA
  account2FA: `${dashboardApiHost}/orgarea/api/v1/account/2fa`,

  // Color Tool
  checkColorToolToken: `${dashboardApiHost}/orgarea/:orgId/api/v1/customdesign/:campaignId/edit`,
  getColor: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/setting?meta_name=campaign_colors`,
  saveColor: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/setting`,

  subscriptionsPaymentDetails: `${dashboardApiHost}/orgarea/api/v1/donor_account/donations/:did/subscriptions/payment_details`,

  donorDonationsEdit: `${dashboardApiHost}/orgarea/api/v1/donor_account/donations/:did/edit`,
  donorDonationsTeamList: `${dashboardApiHost}/orgarea/api/v1/donor_account/donations/:did/team/list`,

  stripeReportRunner: `https://old.charidy.com/tools/report_runner.php`,

  cancelRecurringPlan: `${publicApiHost}/api/v1/subscription/cancel`,

  donorTeamsPublicReply: `${publicApiHost}/api/v1/teams/public-donor-reply`,

  subscriptionsCancel: `${dashboardApiHost}/orgarea/api/v1/donor_account/donations/:did/subscriptions/cancel`,

  getLookerStudioToken: `${dashboardApiHost}/orgarea/api/v1/organization/:orgId/campaign/:campaignId/looker-studio/:reportName/token`,

  customersSearchByQuery: (oid) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/customers/search/query`,

  paymentDetails: (oid, cid, id) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/campaign/${cid}/donation/${id}/subscriptions/payment_details`,

  foundationRequest: (oid) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/charidy_foundation_request`,
  foundationRequestStatus: (oid) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/charidy_foundation_request/status`,

  stripeCharge: (oid, cid, tid) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/campaign/${cid}/team/${tid}/stripe/charge`,

  createPandadocDocument: (oid) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/pandadoc/document?usecase=create_document_charidy_foundation_fiscal_sponsorship_agreement`,
  getStatusPandadocDocument: (oid, documentId) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/pandadoc/document/${documentId}/status`,
  sendPandadocDocument: (oid, documentId) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/pandadoc/document/${documentId}/send`,
  createPandadocSigningSession: (oid, documentId) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/pandadoc/document/${documentId}/signingSession`,
  downloadPandadocDocument: (oid, documentId) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/pandadoc/document/${documentId}/download`,

  orgSMSCount: (oid) => `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/count_sms`,
  orgSMSLimit: (oid) => `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/limit_sms`,

  receiptBulk: (oid, cid) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/campaign/${cid}/receipt_bulk`,

  // Promo codes
  promoCode: (oid, cid) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/campaign/${cid}/promo_code`,
  promoCodeById: (oid, cid, id) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/campaign/${cid}/promo_code/${id}`,
  promoCodeList: (oid, cid) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/campaign/${cid}/promo_code/list`,

  orgCancelRecurringPlan: (oid, cid, id) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/campaign/${cid}/donations/${id}/subscription/cancel`,

  campaignSetCampaignMode: (oid, cid) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/campaign/${cid}/set_campaign_mode`,

  // Integration toggle
  toggleIntegration: (oid, id) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/integration/${id}/toggle-status`,

  paymentSystemList: (oid) =>
    `${dashboardApiHost}/orgarea/api/v1/organization/${oid}/payment_system_list`,
};

export default urls;
