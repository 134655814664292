export default ($t) => ({
  title: "DonorsWidgetVue",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    donors_item_hide_level_badge: {
      title: $t(
        "edit_campaign.schema.donors_widget_vue.donors_item_hide_level_badge",
        "Hide level badge"
      ),
      type: "boolean",
      default: true,
      description: $t(
        "edit_campaign.schema.donors_widget_vue.donors_item_hide_level_badge_description",
        {
          returnEmptyOnMissing: true,
        }
      ),
    },
    donors_item_hide_level_image: {
      title: $t(
        "edit_campaign.schema.donors_widget_vue.donors_item_hide_level_image",
        "Hide level image"
      ),
      type: "boolean",
      default: true,
      description: $t(
        "edit_campaign.schema.donors_widget_vue.donors_item_hide_level_image_description",
        {
          returnEmptyOnMissing: true,
        }
      ),
    },
    auto_scroll: {
      title: $t("edit_campaign.schema.donors_widget_vue.auto_scroll", "Auto scroll"),
      type: "boolean",
      default: true,
      description: $t("edit_campaign.schema.donors_widget_vue.auto_scroll_description", {
        returnEmptyOnMissing: true,
      }),
    },
    disable_auto_update: {
      title: $t(
        "edit_campaign.schema.donors_widget_vue.disable_auto_update",
        "Disable auto update"
      ),
      type: "boolean",
      default: true,
      description: $t("edit_campaign.schema.donors_widget_vue.disable_auto_update_description", {
        returnEmptyOnMissing: true,
      }),
    },
    sort_by: {
      title: $t("edit_campaign.schema.donors_widget_vue.sort_by", "Sort by"),
      type: "string",
      default: "-amount",
      description: $t("edit_campaign.schema.donors_widget_vue.sort_by_description", {
        returnEmptyOnMissing: true,
      }),
    },
    exclude_extend: {
      title: $t("edit_campaign.schema.donors_widget_vue.exclude_extend", "Exclude extend"),
      type: "array",
      default: ["team"],
      description: $t("edit_campaign.schema.donors_widget_vue.exclude_extend_description", {
        returnEmptyOnMissing: true,
      }),
      items: {
        title: "Items",
        type: "string",
      },
    },
    set_interval_update: {
      title: $t("edit_campaign.schema.donors_widget_vue.set_interval_update", "Interval update"),
      type: "number",
      default: 10000,
      description: $t("edit_campaign.schema.donors_widget_vue.set_interval_update_description", {
        returnEmptyOnMissing: true,
      }),
    },
    auto_scroll_delay: {
      title: $t("edit_campaign.schema.donors_widget_vue.auto_scroll_delay", "Auto scroll delay"),
      type: "number",
      default: 500,
      description: $t("edit_campaign.schema.donors_widget_vue.auto_scroll_delay_description", {
        returnEmptyOnMissing: true,
      }),
    },
  },
});
