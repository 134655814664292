<template>
  <div class="e-badge">
    <iconUsers />
    <p class="e-badge__text">{{ qty }}</p>
  </div>
</template>

<script>
import iconUsers from "@/views/event-page/icons/users.vue";

export default {
  name: "badge",

  components: {
    iconUsers,
  },

  props: {
    qty: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.e-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: var(--event-active);
  border-radius: 9999px;
  padding: 8px 15px 8px 15px;

  &__text {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    color: var(--event-soft-dark);
  }

  svg {
    width: 19px;
    height: 19px;
  }
}
</style>
